import React from "react";
import ContentLoader from "react-content-loader";

const TodayMatch = (props) => {
  return (
    <ContentLoader viewBox="-30 -50 500 150" speed={1} foregroundColor="#ffd3a8" {...props}>
      <rect x="0" y="-0" rx="0" ry="0" width="140" height="15" />
      <circle cx="70" cy="50" r="30" />
      <rect x="0" y="90" rx="0" ry="0" width="140" height="15" />
      <rect x="160" y="-0" rx="0" ry="0" width="140" height="15" />
      <circle cx="230" cy="50" r="30" />
      <rect x="160" y="90" rx="0" ry="0" width="140" height="15" />
      <rect x="320" y="-0" rx="0" ry="0" width="140" height="15" />
      <circle cx="390" cy="50" r="30" />
      <rect x="320" y="90" rx="0" ry="0" width="140" height="15" />
    </ContentLoader>
  );
};

TodayMatch.metadata = {
  name: "baptiste fkt",
  github: "baptistefkt",
  description: "Three column grid layout",
  filename: "TodayMatch",
};

export default TodayMatch;
