import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { changeAuth } from "../actions/loginAction";
import { useDispatch } from "react-redux";
import HeaderPage from "../_layout/elements/header";
import { Rating, Tooltip, Menu, MenuItem, Button, Tab, Tabs, Box } from "@mui/material";
import Aries from "../assets/images/aries.png";
import taurus from "../assets/images/taurus.png";
import gemini from "../assets/images/gemini.png";
import cancer from "../assets/images/cancer.png";
import leo from "../assets/images/leo.png";
import virgo from "../assets/images/virgo.png";
import libra from "../assets/images/libra.png";
import Scorpio from "../assets/images/Scorpio.png";
import Sagittarius from "../assets/images/sagittarius.png";
import capricorn from "../assets/images/capricorn.png";
import Aquarius from "../assets/images/aquarius.png";
import Pisces from "../assets/images/pisces.png";
import signs from "../assets/images/moonsign.png";
import MessageSnackbar from "../_layout/elements/snackbar";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import host from "../host";
import "swiper/css";
import "swiper/css/pagination";
import PlanDuration from "../components/plan_duration";
import ZodicSignLoader from "../components/loader/zodicsign_loader";
import TodayMatch from "../components/loader/today-match";
import CardContentLoader from "../components/loader/card_content_loader";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import RatingLoader from "../components/loader/rating";
export const PersonalizedHoroscope = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = localStorage.getItem("login") === "true";

  useEffect(() => {
    if (!auth) {
      navigate("/logout");
    }
  }, [auth]);

  const [severity, setSeverity] = useState("success");
  const [snackbar, setSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const onClose = () => {
    setSnackbar(false);
  };

  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoaded2, setIsLoaded2] = useState(false);
  const [isLoaded3, setIsLoaded3] = useState(false);
  const [isLoaded4, setIsLoaded4] = useState(false);
  const [reportAstroData, setReportAstroData] = useState(null);
  const [signDetails, setSignDetails] = useState(null);
  const [matches, setMatches] = useState(null);
  const [ratings, setRatings] = useState(null);
  const [predictions, setPredictions] = useState(null);

  const urlParams = new URLSearchParams(window.location.search);

  const [periodFilter, setPeriodFilter] = useState(urlParams.has("sortBy") ? urlParams.get("sortBy") : 0);

  const periodFilterMapping = {
    0: "Today's",
    1: "Tomorrow's",
    2: "Yesterday's",
    3: "This Week's",
    4: "This Month's",
    5: "This Year's",
  };

  const signImages = {
    Aries: Aries,
    Taurus: taurus,
    Gemini: gemini,
    Cancer: cancer,
    Leo: leo,
    Virgo: virgo,
    Libra: libra,
    Scorpio: Scorpio,
    Sagittarius: Sagittarius,
    Capricorn: capricorn,
    Aquarius: Aquarius,
    Pisces: Pisces,
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getReport = () => {
    setIsLoaded(false);
    fetch(host.astro_backend + `/report`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 403) {
          dispatch(changeAuth(false, null));
          navigate("/logout");
        } else if (res.status === 400) {
          setIsLoaded(true);
        } else {
          setIsLoaded(true);
          setSeverity("error");
          setMessage("Something went wrong");
          setSnackbar(true);
        }
      })
      .then((data) => {
        if (data) {
          setReportAstroData(data.vedic_horoscope?.astro_details);
          setSignDetails(data.vedic_horoscope?.planets_position);
          setIsLoaded(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoaded(true);
      });
  };

  const getMatches = () => {
    setIsLoaded2(false);
    fetch(host.astro_backend + `/personal_horoscope/matches?period_filter=${periodFilter}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 403) {
          dispatch(changeAuth(false, null));
          navigate("/logout");
        } else {
          setIsLoaded2(true);
          setSeverity("error");
          setMessage("Something went wrong");
          setSnackbar(true);
        }
      })
      .then((data) => {
        if (data) {
          setMatches(data.matches);
          setIsLoaded2(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoaded2(true);
      });
  };

  const getRatings = () => {
    setIsLoaded3(false);
    fetch(host.astro_backend + `/personal_horoscope/ratings?period_filter=${periodFilter}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 403) {
          dispatch(changeAuth(false, null));
          navigate("/logout");
        } else {
          setIsLoaded3(true);
          setSeverity("error");
          setMessage("Something went wrong");
          setSnackbar(true);
        }
      })
      .then((data) => {
        if (data) {
          setRatings(data.ratings);
          setIsLoaded3(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoaded3(true);
      });
  };

  const getPredictions = () => {
    setIsLoaded4(false);
    fetch(host.astro_backend + `/personal_horoscope/predictions?period_filter=${periodFilter}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 403) {
          dispatch(changeAuth(false, null));
          navigate("/logout");
        } else {
          setIsLoaded4(true);
          setSeverity("error");
          setMessage("Something went wrong");
          setSnackbar(true);
        }
      })
      .then((data) => {
        if (data) {
          setPredictions(data.predictions);
          setIsLoaded4(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoaded4(true);
      });
  };

  const [isFreeTrial, setIsFreeTrial] = useState(null);
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [hasExpired, setHasExpired] = useState(false);

  const getUserPlan = () => {
    setIsLoaded(false);
    fetch(host.astro_backend + `/plans/user/plan`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 403) {
          dispatch(changeAuth(false, null));
          navigate("/logout");
        } else {
          setIsLoaded(true);
          setSeverity("error");
          setMessage("Something went wrong");
          setSnackbar(true);
        }
      })
      .then((data) => {
        if (data) {
          if (data.is_free_trial) {
            setIsFreeTrial(true);
            setIsLoaded4(true); // To show the upgrade message in free tier
          } else {
            setIsFreeTrial(false);
          }
          // Calculate time remaining until expiration
          const currentDate = new Date();
          const expirationDate = new Date(data.expires_at);
          const temp = expirationDate - currentDate;
          setTimeRemaining(temp);
          if (currentDate > expirationDate) {
            setHasExpired(true);
          } else {
            setHasExpired(false);
          }
          setIsLoaded(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoaded(true);
      });
  };

  useEffect(() => {
    getReport();
    getUserPlan();
  }, []);

  useEffect(() => {
    if (
      (periodFilter === 1 || periodFilter === 2 || periodFilter === 3 || periodFilter === 4 || periodFilter === 5 || periodFilter === 0) &&
      (isFreeTrial === true || isFreeTrial === false) &&
      isFreeTrial !== undefined
    ) {
      getMatches();
      getRatings();
      if (isFreeTrial === false) {
        getPredictions();
      }
      const urlParameters = new URLSearchParams(window.location.search);
      urlParameters.set("sortBy", periodFilter);
      navigate({
        pathname: "/personalized-horoscope",
        search: urlParameters.toString(),
      });
    }
  }, [periodFilter, isFreeTrial]);
  const handleChange = (event, newValue) => {
    setPeriodFilter(newValue);
  };
  return (
    <div>
      <MessageSnackbar open={snackbar} autoHideDuration={3000} onClose={onClose} severity={severity} message={message} />

      <div className="inner-header">
        <HeaderPage />
      </div>
      <section className="kundli_reports  ">
        <div className="horoscope_layout_inner">
          <div className=" horoscope-details-wrapper">
            {isFreeTrial !== null && timeRemaining !== null && (isFreeTrial || timeRemaining < ((2.592 * 10) ^ 8)) && (
              <div className="fix_plan_wrapper">
                <PlanDuration timeRemaining={timeRemaining} hasExpired={hasExpired} isFreeTrial={isFreeTrial} questionsAllowed={null} isChat={false} showLifeBanner={false}/>
              </div>
            )}
            <div className="personalize_top_head">
              <div className="reports_tabs  "  >
                <Box sx={{ maxWidth: { xs: 360, sm: 640, margin: "0 auto" } }}>
                  <Tabs value={Number(periodFilter)} variant="scrollable" scrollButtons="auto" aria-label="scrollable auto tabs example">
                    <Tab
                      label="Today"
                      value={0}
                      selected={periodFilter === 0}
                      onClick={() => {
                        // handleClose();
                        setPeriodFilter(0);
                      }}
                    />
                    <Tab
                      label="Tomorrow"
                      value={1}
                      selected={periodFilter === 1}
                      onClick={() => {
                        // handleClose();
                        setPeriodFilter(1);
                      }}
                    />
                    <Tab
                      label="Yesterday"
                      value={2}
                      selected={periodFilter === 2}
                      onClick={() => {
                        // handleClose();
                        setPeriodFilter(2);
                      }}
                    />
                    <Tab
                      label="This Week"
                      value={3}
                      selected={periodFilter === 3}
                      onClick={() => {
                        // handleClose();
                        setPeriodFilter(3);
                      }}
                    />
                    <Tab
                      label="This Month"
                      value={4}
                      selected={periodFilter === 4}
                      onClick={() => {
                        // handleClose();
                        setPeriodFilter(4);
                      }}
                    />
                    <Tab
                      label=" This Year"
                      value={5}
                      selected={periodFilter === 5}
                      onClick={() => {
                        // handleClose();
                        setPeriodFilter(5);
                      }}
                    />
                  </Tabs>
                </Box>
              </div>
              <div className="personalize_heading">
                <h3>{periodFilterMapping[periodFilter]} Personalized Horoscope</h3>

                <div className="search_filter_cta d-none">
                  <Tooltip title="Sort by">
                    <Button onClick={handleClick} variant="outlined" startIcon={<FilterListOutlinedIcon />}>
                      Sort By
                    </Button>
                  </Tooltip>
                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&::before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <MenuItem
                      value={0}
                      selected={periodFilter === 0}
                      onClick={() => {
                        handleClose();
                        setPeriodFilter(0);
                      }}
                    >
                      Today
                    </MenuItem>
                    <MenuItem
                      value={1}
                      selected={periodFilter === 1}
                      onClick={() => {
                        handleClose();
                        setPeriodFilter(1);
                      }}
                    >
                      Tomorrow
                    </MenuItem>
                    <MenuItem
                      value={2}
                      selected={periodFilter === 2}
                      onClick={() => {
                        handleClose();
                        setPeriodFilter(2);
                      }}
                    >
                      Yesterday
                    </MenuItem>
                    <MenuItem
                      value={3}
                      selected={periodFilter === 3}
                      onClick={() => {
                        handleClose();
                        setPeriodFilter(3);
                      }}
                    >
                      This Week
                    </MenuItem>
                    <MenuItem
                      value={4}
                      selected={periodFilter === 4}
                      onClick={() => {
                        handleClose();
                        setPeriodFilter(4);
                      }}
                    >
                      This Month
                    </MenuItem>
                    <MenuItem
                      value={5}
                      selected={periodFilter === 5}
                      onClick={() => {
                        handleClose();
                        setPeriodFilter(5);
                      }}
                    >
                      This Year
                    </MenuItem>
                  </Menu>
                </div>
              </div>
              <div className="my_horoscope_flex">
                <div className="my_horoscope_match">
                  <div className="rating_card">
                    <div className="rating_title">
                      <h3>Rashi and Nakshatra</h3>
                    </div>
                    {isLoaded ? (
                      <div className="all_ratings">
                        <div className="horoscope_match_cta horoscope_cta">
                          <label> Moon sign </label>
                          <div className="tech-icon  ">
                            <img src={signImages[reportAstroData?.moon_sign]} alt="" />
                          </div>
                          <label>{reportAstroData?.moon_sign}</label>
                        </div>
                        <div className="horoscope_match_cta horoscope_cta">
                          <label> Nakshatra </label>
                          <div className="tech-icon  ">
                            <img src={signs} alt="" />
                          </div>
                          <label>{reportAstroData?.naksahtra}</label>
                        </div>
                        <div className="horoscope_match_cta horoscope_cta">
                          <label> Sun sign </label>
                          <div className="tech-icon  ">
                          <img src={signImages[reportAstroData?.sun_sign] || signImages[signDetails?.find(planet => planet.name === "Sun")?.sign]|| null} alt="" />
                          </div>
                          <label>{reportAstroData?.sun_sign || signDetails?.find(planet => planet.name === "Sun")?.sign || "NA" }</label>
                        </div>
                        <div className="horoscope_match_cta horoscope_cta">
                          <label>Ascendant</label>
                          <div className="tech-icon  ">
                            <img src={signImages[reportAstroData?.ascendant]} alt="" />
                          </div>
                          <label>{reportAstroData?.ascendant}</label>
                        </div>
                      </div>
                    ) : (
                      <ZodicSignLoader />
                    )}
                  </div>
                </div>
                <div className="horoscope_match">
                  <div className="rating_card">
                    <div className="rating_title">
                      <h3>{periodFilterMapping[periodFilter]} Matches</h3>
                    </div>
                    {isLoaded2 ? (
                      <div className="horoscope_match_flex">
                        <div className="horoscope_match_cta horoscope_cta">
                          <label> Love </label>
                          <div className="tech-icon  ">
                            <img src={signImages[matches?.love]} alt="" />
                          </div>
                          <label>{matches?.love}</label>
                        </div>
                        <div className="horoscope_match_cta horoscope_cta">
                          <label> Friendship </label>
                          <div className="tech-icon  ">
                            <img src={signImages[matches?.friendship]} alt="" />
                          </div>
                          <label>{matches?.friendship}</label>
                        </div>
                        <div className="horoscope_match_cta horoscope_cta">
                          <label> Career </label>
                          <div className="tech-icon  ">
                            <img src={signImages[matches?.career]} alt="" />
                          </div>
                          <label>{matches?.career}</label>
                        </div>
                      </div>
                    ) : (
                      <TodayMatch />
                    )}
                  </div>
                </div>
                <div className="horoscope_ratting">
                  <div className="rating_card">
                    <div className="rating_title">
                      <h3>{periodFilterMapping[periodFilter]} Star Rating</h3>
                    </div>
                    {isLoaded3 ? (
                      <div className="all_ratings">
                        <div className="ratings_cta">
                          <label>Overall</label>
                          <Rating name="read-only" value={ratings?.overallRating} readOnly />
                        </div>
                        <div className="ratings_cta">
                          <label>Love</label>
                          <Rating name="read-only" value={ratings?.loveRating} readOnly />
                        </div>
                        <div className="ratings_cta">
                          <label>Health</label>
                          <Rating name="read-only" value={ratings?.healthRating} readOnly />
                        </div>
                        <div className="ratings_cta">
                          <label>Work</label>
                          <Rating name="read-only" value={ratings?.workRating} readOnly />
                        </div>
                        <div className="ratings_cta">
                          <label>Travel</label>
                          <Rating name="read-only" value={ratings?.travelRating} readOnly />
                        </div>
                        <div className="ratings_cta">
                          <label>Finance</label>
                          <Rating name="read-only" value={ratings?.financeRating} readOnly />
                        </div>
                      </div>
                    ) : (
                      <RatingLoader />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="horoscope_wrapper">
              <div className="hotoscope_body">
                {/* {isLoaded4 ? ( */}
                <div className="horoscope_content_box">
                  <div className="report_section_card">
                    <div className="report_card_title">
                      <h3>Love & Relationships</h3>
                    </div>
                    {isLoaded4 ? (
                      <div className="report_card_content">
                        {isFreeTrial || hasExpired ? (
                          <div className="access_message accesspersonalize">
                            <div className="dashboard_plan_button">
                              <h4>Upgrade to Unlock Full Access</h4>
                              <Link to="/plans">
                                <button type="button" className="btn btn-primary  ">
                                  <AutoFixHighIcon />
                                  Upgrade Plan
                                </button>
                              </Link>
                            </div>
                          </div>
                        ) : (
                          <p>{predictions?.love}</p>
                        )}
                      </div>
                    ) : (
                      <CardContentLoader />
                    )}
                  </div>
                  <div className="report_section_card">
                    <div className="report_card_title">
                      <h3>Health & Wellness</h3>
                    </div>
                    {isLoaded4 ? (
                      <div className="report_card_content">
                        {isFreeTrial || hasExpired ? (
                          <div className="access_message accesspersonalize">
                            <div className="dashboard_plan_button">
                              <h4>Upgrade to Unlock Full Access</h4>
                              <Link to="/plans">
                                <button type="button" className="btn btn-primary  ">
                                  <AutoFixHighIcon />
                                  Upgrade Plan
                                </button>
                              </Link>
                            </div>
                          </div>
                        ) : (
                          <p>{predictions?.health} </p>
                        )}
                      </div>
                    ) : (
                      <CardContentLoader />
                    )}
                  </div>
                  <div className="report_section_card">
                    <div className="report_card_title">
                      <h3>Business & Finance</h3>
                    </div>
                    {isLoaded4 ? (
                      <div className="report_card_content">
                        {isFreeTrial || hasExpired ? (
                          <div className="access_message accesspersonalize">
                            <div className="dashboard_plan_button">
                              <h4>Upgrade to Unlock Full Access</h4>
                              <Link to="/plans">
                                <button type="button" className="btn btn-primary  ">
                                  <AutoFixHighIcon />
                                  Upgrade Plan
                                </button>
                              </Link>
                            </div>
                          </div>
                        ) : (
                          <p>{predictions?.finance}</p>
                        )}
                      </div>
                    ) : (
                      <CardContentLoader />
                    )}
                  </div>
                  <div className="report_section_card">
                    <div className="report_card_title">
                      <h3>Career</h3>
                    </div>
                    {isLoaded4 ? (
                      <div className="report_card_content">
                        {isFreeTrial || hasExpired ? (
                          <div className="access_message accesspersonalize">
                            <div className="dashboard_plan_button">
                              <h4>Upgrade to Unlock Full Access</h4>
                              <Link to="/plans">
                                <button type="button" className="btn btn-primary  ">
                                  <AutoFixHighIcon />
                                  Upgrade Plan
                                </button>
                              </Link>
                            </div>
                          </div>
                        ) : (
                          <p>{predictions?.career}</p>
                        )}
                      </div>
                    ) : (
                      <CardContentLoader />
                    )}
                  </div>
                  <div className="report_section_card">
                    <div className="report_card_title">
                      <h3>Travel and Adventure</h3>
                    </div>
                    {isLoaded4 ? (
                      <div className="report_card_content">
                        {isFreeTrial || hasExpired ? (
                          <div className="access_message accesspersonalize">
                            <div className="dashboard_plan_button">
                              <h4>Upgrade to Unlock Full Access</h4>
                              <Link to="plans">
                                <button type="button" className="btn btn-primary  ">
                                  <AutoFixHighIcon />
                                  Upgrade Plan
                                </button>
                              </Link>
                            </div>
                          </div>
                        ) : (
                          <p>{predictions?.travel}</p>
                        )}
                      </div>
                    ) : (
                      <CardContentLoader />
                    )}
                  </div>
                </div>
                {/* ) : (
                  <CardContentLoader />
                )} */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
