import React from "react";
import ContentLoader from "react-content-loader";

const RatingLoader = (props) => {
  return (
    <ContentLoader speed={1} width={340} viewBox="0 0 340 94" backgroundColor="#f6f6ef" foregroundColor="#e8e8e3" {...props}>
      <rect x="20" y="33" rx="0" ry="0" width="150" height="13" />
      <rect x="196" y="33" rx="0" ry="0" width="150" height="13" />

      <rect x="20" y="60" rx="0" ry="0" width="150" height="13" />
      <rect x="196" y="60" rx="0" ry="0" width="150" height="13" />

      <rect x="20" y="120" rx="0" ry="0" width="150" height="13" />
      <rect x="196" y="120" rx="0" ry="0" width="150" height="13" />
    </ContentLoader>
  );
};

RatingLoader.metadata = {
  name: "baptiste fkt",
  github: "baptistefkt",
  description: "Three column grid layout",
  filename: "RatingLoader",
};

export default RatingLoader;
