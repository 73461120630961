import React, { Fragment, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import { LoaderPage } from "./pages/loader-page";
import { Privacy } from "./pages/privacy";
import { ContactUs } from "./pages/contactus";
import { BlogDetail } from "./pages/blog-detail";
import { ErrorPage404 } from "./pages/404";
import { ErrorPage500 } from "./pages/500";
import { Plan } from "./pages/plan";
import { FAQ } from "./pages/faq";
import { Blogs } from "./pages/blogs";
import { Login } from "./pages/login";
import { Signup } from "./pages/signup";
import { ForgotPassword } from "./pages/forgot-password";
import { AstroChatbot } from "./pages/astro-chatbot";
import { Career } from "./pages/career";
import { Relationships } from "./pages/relationships";
import { LifeReport } from "./pages/life-report";
import { NewAstroChatbot } from "./pages/new-astro-chatbot";
import { PersonalizedHoroscope } from "./pages/personalized-horoscope";
import { LifeReportCampaigning } from "./pages/life-report-campaining";
import { LifeReportPayment } from "./pages/report-payment";
import { DashboardPage } from "./pages/dashboard";
import { Terms } from "./pages/terms";
import { SubscriptionPayment } from "./pages/subscription-payment";
import { Logout } from "./pages/logout";
import { GmailSignup } from "./pages/gmail-signup";
import RedirectToAstroHomepage from "./pages/nextjs-homepage";
import host from "./host";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const RoutesList = () => {
  const location = useLocation();

  return (
    <Fragment>
      <ScrollToTop />
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route path="dashboard" element={<DashboardPage />} />
          <Route path="astro-chatbot" element={<AstroChatbot />} />
          <Route path="new-astro-chatbot" element={<NewAstroChatbot />} />

          <Route path="career" element={<Career />} />
          <Route path="relationships" element={<Relationships />} />
          <Route path="life-report" element={<LifeReport />} />
          <Route path="report-payment" element={<LifeReportPayment />} />
          <Route path="life-report-campaigning" element={<LifeReportCampaigning />} />
          <Route path="personalized-horoscope" element={<PersonalizedHoroscope />} />
          <Route path="dashboard" element={<DashboardPage />} />

          <Route path="contact" element={<ContactUs />} />
          <Route path="blogs" element={<Blogs />} />
          <Route path="blog-detail" element={<BlogDetail />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="terms" element={<Terms />} />
          <Route path="plans" element={<Plan />} />
          <Route path="/subscription-payment/:id" element={<SubscriptionPayment />} />
          <Route path="faq" element={<FAQ />} />
          <Route path="404" element={<ErrorPage404 />} />
          <Route path="500" element={<ErrorPage500 />} />
          <Route path="/" element={<RedirectToAstroHomepage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="signup" element={<Signup />} />
          <Route path="googlelogin" element={<GmailSignup />} />
          <Route path="forgot-password" element={<ForgotPassword />} />

          <Route path="loader-page" element={<LoaderPage />} />
        </Routes>
      </AnimatePresence>
    </Fragment>
  );
};

export default RoutesList;
