import React, { useState } from "react";
import { Link } from "react-router-dom";

import { TextField, LinearProgress } from "@mui/material";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import MicOutlinedIcon from "@mui/icons-material/MicOutlined";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import MessageSnackbar from "../_layout/elements/snackbar";

const ChatForm = (props) => {
  const [snackbar, setSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");

  const onClose = () => {
    setSnackbar(false);
  };
  //filter open  function end//
  //audio listening    function start//
  const [isListening, setIsListening] = useState(false);
  const handleMicButtonClick = () => {
    setIsListening((prevState) => !prevState);
  };
  //audio listening    function end//]

  //Press enter ==> submit message && press shift+enter ==> next line
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevents the default action (form submission)
      props.handleMessage(props.request);
    }
  };

  //filter open  function start//
  const [anchorElfocus, setAnchorElfocus] = React.useState(null);
  const handleClickfocus = (event) => {
    setAnchorElfocus(event.currentTarget);
  };
  const handleClosefocus = () => {
    setAnchorElfocus(null);
  };
  const openfocus = Boolean(anchorElfocus);
  //filter open  function end//

  return (
    <>
      <MessageSnackbar open={snackbar} autoHideDuration={3000} onClose={onClose} severity={severity} message={message} />

      <div className="chat_form">
        {(props.hasExpired || (props.isFreeTrial && props.questionsAllowed === 0)) && (
          <div className="access_message">
            <div className="dashboard_plan_button">
              <h4>Upgrade to Unlock Full Access</h4>
              <Link to="/plans">
                <button type="button" className="btn btn-primary  ">
                  <AutoFixHighIcon />
                  Upgrade Plan
                </button>
              </Link>
            </div>
          </div>
        )}
        <div className="chat_form_box">
          <div className="chat_form_flexbox">
            <div className="messageform">
              <TextField
                id="outlined-multiline-flexible"
                placeholder="Ask anything..."
                multiline
                value={props.transcript?.length > 0 ? props.transcript : props.request}
                onChange={(e) => {
                  props.setRequest(e.target.value);
                }}
                onKeyDown={handleKeyDown}
                disabled={props.hasExpired || (props.isFreeTrial && props.questionsAllowed === 0)}
              />
              <div className={`listeningvoice ${props.listening ? "listening" : ""}`}>
                <LinearProgress />
                {props.transcript?.length === 0 && (
                  <div className="typing-box">
                    <span>{props.transcript?.length > 0 ? props.transcript : "Listening..."}</span>
                    <div class="typing_loader"> </div>
                  </div>
                )}
              </div>
            </div>
            <div className="desk_chat_actions">
              <div className="send_btn">
                <button
                  type="button"
                  className={`senderbtn bg-danger text-white ${props.listening ? "listening" : ""}`}
                  onClick={() => {
                    if (window.navigator.brave && window.navigator.brave.isBrave.name === "isBrave") {
                      props.setMicOn(false);
                      setSeverity("error");
                      setMessage("Not supported in Brave. Please use a different browser or type your prompt.");
                      setSnackbar(true);
                    } else {
                      props.handleMic();
                    }
                  }}
                  disabled={props.hasExpired || (props.isFreeTrial && props.questionsAllowed === 0)}
                >
                  <div className={`pulse-ring ${props.listening ? "listening" : ""}`}></div>
                  <MicOutlinedIcon />
                </button>
                {!props.listening && (
                  <button
                    type="button"
                    className="senderbtn "
                    disabled={!props.request}
                    onClick={() => {
                      props.handleMessage(props.request);
                    }}
                  >
                    <EastOutlinedIcon />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ChatForm;
