import React from "react";
import {} from "@mui/material";
import astroavtar from "../assets/images/chatavtar.webp";
import { Link } from "react-router-dom";
import logoicon from "../assets/images/logoicon.png";

function LoginSidebar() {
  return (
    <>
      <div className="auth-bg   pt-5   ">
        <div className="login_logo">
          <Link to={"/"}>
            <img src={logoicon} alt="logo" height={100} />
          </Link>
        </div>
        {/*    
   <div className='auth-center'>
      <div className="row justify-content-center align-items-center my-auto">
         <div className="col-xl-10">
            <div className="sliderimage">
               <img src={astroavtar} />
            </div>
         </div>
      </div>
   </div> */}
        <div className="login_title">
          <h5>
            Consult Guru Kashyap AI today to solve your problems with personalized astrological guidance. Unlock the wisdom of the stars and find clarity and solutions for your life's challenges!{" "}
          </h5>
        </div>
      </div>
    </>
  );
}
export default LoginSidebar;
