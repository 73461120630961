// ImageComponent.js
import React, { useState } from "react";

const ImageComponent = ({ src, alt }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    imageLoaded && (
      <div className="popover_img_box">
        <div className="popover_img">
          <img src={src} alt={alt} onLoad={() => setImageLoaded(true)} onError={() => setImageLoaded(false)} />
        </div>
      </div>
    )
  );
};

export default ImageComponent;
