import "./App.css";
import { Fragment, useEffect, useState } from "react";
import { Provider } from "react-redux";
import RoutesList from "./Routes";
import MainPage from "./_layout/main.page";
import store from "./store";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../src/assets/css/line-awesome.min.css";
import "../src/assets/css/style.css";
import "../src/assets/css/responsive.css";
import host from "./host";
// import AOS from 'aos';
//  import "aos/dist/aos.css"
//  AOS.init();
//  AOS.init({

//   duration: 2000, // values from 0 to 3000, with step 50ms

// });
function App() {
  const [theme, setTheme] = useState(localStorage.getItem("theme") ? localStorage.getItem("theme") : "dark");

  useEffect(() => {
    document.documentElement.setAttribute("data-theme", theme);
    localStorage.setItem("theme", theme);
  }, [theme]);

  return (
    <GoogleOAuthProvider clientId={host.googleClientId}>
      <Fragment>
        <Provider store={store}>
          <MainPage>
            <RoutesList />
          </MainPage>
        </Provider>
      </Fragment>
    </GoogleOAuthProvider>
  );
}

export default App;
