import React, { useState ,useEffect} from 'react'; 
import { Link } from 'react-router-dom';
import HeaderPage from '../_layout/elements/header';
import kundli from '../assets/images/Kundli.png';
import ChatForm from '../components/chatform';
import { Avatar,IconButton,Tooltip,Zoom,Checkbox,Chip ,CircularProgress,Button,FormControlLabel,Switch,List,Menu,MenuItem,ListItemIcon} from '@mui/material';
import { deepOrange, deepPurple } from '@mui/material/colors';
import { motion } from 'framer-motion';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import astroai from '../assets/images/pentagram.png';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import HistoryLoader from '../components/loader/history-loader';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import CheckIcon from '@mui/icons-material/Check';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { styled } from '@mui/material/styles';
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
// import { motion } from "framer-motion";
export const AstroChatbot = () => {
//this below function for skill suggition start//
const [isLoading, setIsLoading] = useState(false);
const [isSuccess, setIsSuccess] = useState(false);
const handleClick = () => {
setIsLoading(true);
// Simulate an asynchronous action, e.g., API call
setTimeout(() => {
setIsLoading(false);
setIsSuccess(true);
}, 2000);
};
//this below function for skill suggition end//
/*hindi eng  switch function start*/
const [currentLanguage, setCurrentLanguage] = useState('english');
const handleLanguageToggle = () => {
setCurrentLanguage((prevLanguage) =>
prevLanguage === 'english' ? 'hindi' : 'english'
);
};
/*hindi eng  switch function end*/
/*play pause function start */
const [isAudioPlaying, setIsAudioPlaying] = useState(false);
const handlePlayAudio = () => {
setIsAudioPlaying(true);
};
const handlePauseAudio = () => {
setIsAudioPlaying(false);
};
/*play pause function end */
const [page, setPage] = useState(1);
const totalPages = 3; 
const handleChange = (value) => {
setPage(value);
};
const handleBack = () => {
if (page > 1) {
handleChange(page - 1);
}
};
const handleNext = () => {
if (page < totalPages) {
handleChange(page + 1);
}
};
const IOSSwitch = styled((props) => (
<Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
width: 38,
height: 20,
padding: 0,
'& .MuiSwitch-switchBase': {
padding: 0,
margin: 2,
transitionDuration: '300ms',
'&.Mui-checked': {
transform: 'translateX(16px)',
color: '#fff',
'& + .MuiSwitch-track': {
backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
opacity: 1,
border: 0,
},
'&.Mui-disabled + .MuiSwitch-track': {
opacity: 0.5,
},
},
'&.Mui-focusVisible .MuiSwitch-thumb': {
color: '#33cf4d',
border: '6px solid #fff',
},
'&.Mui-disabled .MuiSwitch-thumb': {
color:
theme.palette.mode === 'light'
? theme.palette.grey[100]
: theme.palette.grey[600],
},
'&.Mui-disabled + .MuiSwitch-track': {
opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
},
},
'& .MuiSwitch-thumb': {
boxSizing: 'border-box',
width: 16,
height: 16,
},
'& .MuiSwitch-track': {
borderRadius: 26 / 2,
backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
opacity: 1,
transition: theme.transitions.create(['background-color'], {
duration: 500,
}),
},
}));
const [open, setOpen] = React.useState(true); 
const [anchorEl, setAnchorEl] = React.useState(null);
const openhistoryaction = Boolean(anchorEl);
const handleClickaction = (event) => {
setAnchorEl(event.currentTarget);
};
const handleClose = () => {
setAnchorEl(null);
};
//this below function for openknowledge     popup start// 
const [openeditfile, setOpeneditfile] = React.useState(false);
const activeeditfile = () => setOpeneditfile(true);
const handleCloseeditfile = () => setOpeneditfile(false);
//this below function for openknowledge     popup end// 
return (
<div>
   <div className='inner-header'>
      <HeaderPage />
   </div>
   <div className='astro_chatbot_wrapper'>
      <div className='astro_flex'>
         <div className='asrtro_aside'>
            <div className='asrtro_aside_flex'>
               <div className='astro_history'>
                  <div className='history_head'>
                     <Link to="/new-astro-chatbot"  className='history_title'>
                     <Avatar  sx={{ width: 25, height: 25 }} src={astroai}></Avatar>
                     <h3>New Chat</h3>
                     </Link>
                  </div>
                  <List component="div" disablePadding>
                     <div className='sidebar-history'>
                        <div className='sidebar-history-body'>
                           <div className='skeleton_loader' style={{ display: 'none' }}>
                           <HistoryLoader/>
                        </div>
                        <div className='chat_history_list  '>
                           <div className='list_box'>
                              <div className='list_title'>Today</div>
                              <ul>
                                 <li>
                                    <Link to="/">
                                    <div className='history_line'>A Journey through Love, Career, and Finance </div>
                                    </Link>
                                    <div className='history_action'>
                                       <Tooltip title="Actions">
                                          <Link
                                          onClick={handleClickaction}
                                          size="small"
                                          aria-controls={open ? 'account-menu' : undefined}
                                          aria-haspopup="true"
                                          aria-expanded={open ? 'true' : undefined}
                                          >
                                          <MoreHorizIcon />
                                          </Link>
                                       </Tooltip>
                                       <Menu
                                       anchorEl={anchorEl}
                                       id="account-menu"
                                       open={openhistoryaction}
                                       onClose={handleClose}
                                       onClick={handleClose}
                                       PaperProps={{
                                       elevation: 0,
                                       sx: {
                                       overflow: 'visible',
                                       filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                       mt: 1.5,
                                       '& .MuiAvatar-root': {
                                       width: 32,
                                       height: 32,
                                       ml: -0.5,
                                       mr: 1,
                                       },
                                       '&::before': {
                                       content: '""',
                                       display: 'block',
                                       position: 'absolute',
                                       top: 0,
                                       right: 14,
                                       width: 10,
                                       height: 10,
                                       bgcolor: 'background.paper',
                                       transform: 'translateY(-50%) rotate(45deg)',
                                       zIndex: 0,
                                       },
                                       },
                                       }}
                                       transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                       anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                       >
                                       <MenuItem onClick={activeeditfile}>
                                          <ListItemIcon>
                                             <ShareOutlinedIcon fontSize='small' />
                                          </ListItemIcon>
                                          Share Chat
                                       </MenuItem>
                                       <MenuItem onClick={handleClose}>
                                          <ListItemIcon>
                                             <FileDownloadOutlinedIcon fontSize='small' />
                                          </ListItemIcon>
                                          Download Chat
                                       </MenuItem>
                                       <MenuItem  >
                                          <ListItemIcon>
                                             <DeleteOutlineOutlinedIcon fontSize='small' />
                                          </ListItemIcon>
                                          Delete
                                       </MenuItem>
                                       </Menu>
                                    </div>
                                 </li>
                           
                                 <li>
                                    <Link to="/">
                                    <div className='history_line'> Navigating Life's Pathways </div>
                                    </Link>
                                    <div className='history_action'>
                                       <Tooltip title="Actions">
                                          <Link
                                          onClick={handleClickaction}
                                          size="small"
                                          aria-controls={open ? 'account-menu' : undefined}
                                          aria-haspopup="true"
                                          aria-expanded={open ? 'true' : undefined}
                                          >
                                          <MoreHorizIcon />
                                          </Link>
                                       </Tooltip>
                                       <Menu
                                       anchorEl={anchorEl}
                                       id="account-menu"
                                       open={openhistoryaction}
                                       onClose={handleClose}
                                       onClick={handleClose}
                                       PaperProps={{
                                       elevation: 0,
                                       sx: {
                                       overflow: 'visible',
                                       filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                       mt: 1.5,
                                       '& .MuiAvatar-root': {
                                       width: 32,
                                       height: 32,
                                       ml: -0.5,
                                       mr: 1,
                                       },
                                       '&::before': {
                                       content: '""',
                                       display: 'block',
                                       position: 'absolute',
                                       top: 0,
                                       right: 14,
                                       width: 10,
                                       height: 10,
                                       bgcolor: 'background.paper',
                                       transform: 'translateY(-50%) rotate(45deg)',
                                       zIndex: 0,
                                       },
                                       },
                                       }}
                                       transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                       anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                       >
                                       <MenuItem onClick={activeeditfile}>
                                          <ListItemIcon>
                                             <ShareOutlinedIcon fontSize='small' />
                                          </ListItemIcon>
                                          Share Chat
                                       </MenuItem>
                                       <MenuItem onClick={handleClose}>
                                          <ListItemIcon>
                                             <FileDownloadOutlinedIcon fontSize='small' />
                                          </ListItemIcon>
                                          Download Chat
                                       </MenuItem>
                                       <MenuItem  >
                                          <ListItemIcon>
                                             <DeleteOutlineOutlinedIcon fontSize='small' />
                                          </ListItemIcon>
                                          Delete
                                       </MenuItem>
                                       </Menu>
                                    </div>
                                 </li>
                                 <li>
                                    <Link to="/">
                                    <div className='history_line'> Exploring Destiny with AI Astrologer </div>
                                    </Link>
                                    <div className='history_action'>
                                       <Tooltip title="Actions">
                                          <Link
                                          onClick={handleClickaction}
                                          size="small"
                                          aria-controls={open ? 'account-menu' : undefined}
                                          aria-haspopup="true"
                                          aria-expanded={open ? 'true' : undefined}
                                          >
                                          <MoreHorizIcon />
                                          </Link>
                                       </Tooltip>
                                       <Menu
                                       anchorEl={anchorEl}
                                       id="account-menu"
                                       open={openhistoryaction}
                                       onClose={handleClose}
                                       onClick={handleClose}
                                       PaperProps={{
                                       elevation: 0,
                                       sx: {
                                       overflow: 'visible',
                                       filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                       mt: 1.5,
                                       '& .MuiAvatar-root': {
                                       width: 32,
                                       height: 32,
                                       ml: -0.5,
                                       mr: 1,
                                       },
                                       '&::before': {
                                       content: '""',
                                       display: 'block',
                                       position: 'absolute',
                                       top: 0,
                                       right: 14,
                                       width: 10,
                                       height: 10,
                                       bgcolor: 'background.paper',
                                       transform: 'translateY(-50%) rotate(45deg)',
                                       zIndex: 0,
                                       },
                                       },
                                       }}
                                       transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                       anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                       >
                                       <MenuItem onClick={activeeditfile}>
                                          <ListItemIcon>
                                             <ShareOutlinedIcon fontSize='small' />
                                          </ListItemIcon>
                                          Share Chat
                                       </MenuItem>
                                       <MenuItem onClick={handleClose}>
                                          <ListItemIcon>
                                             <FileDownloadOutlinedIcon fontSize='small' />
                                          </ListItemIcon>
                                          Download Chat
                                       </MenuItem>
                                       <MenuItem  >
                                          <ListItemIcon>
                                             <DeleteOutlineOutlinedIcon fontSize='small' />
                                          </ListItemIcon>
                                          Delete
                                       </MenuItem>
                                       </Menu>
                                    </div>
                                 </li>
                                 <li>
                                    <Link to="/">
                                    <div className='history_line'>Deep Dives with the Astrology AI </div>
                                    </Link>
                                    <div className='history_action'>
                                       <Tooltip title="Actions">
                                          <Link
                                          onClick={handleClickaction}
                                          size="small"
                                          aria-controls={open ? 'account-menu' : undefined}
                                          aria-haspopup="true"
                                          aria-expanded={open ? 'true' : undefined}
                                          >
                                          <MoreHorizIcon />
                                          </Link>
                                       </Tooltip>
                                       <Menu
                                       anchorEl={anchorEl}
                                       id="account-menu"
                                       open={openhistoryaction}
                                       onClose={handleClose}
                                       onClick={handleClose}
                                       PaperProps={{
                                       elevation: 0,
                                       sx: {
                                       overflow: 'visible',
                                       filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                       mt: 1.5,
                                       '& .MuiAvatar-root': {
                                       width: 32,
                                       height: 32,
                                       ml: -0.5,
                                       mr: 1,
                                       },
                                       '&::before': {
                                       content: '""',
                                       display: 'block',
                                       position: 'absolute',
                                       top: 0,
                                       right: 14,
                                       width: 10,
                                       height: 10,
                                       bgcolor: 'background.paper',
                                       transform: 'translateY(-50%) rotate(45deg)',
                                       zIndex: 0,
                                       },
                                       },
                                       }}
                                       transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                       anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                       >
                                       <MenuItem onClick={activeeditfile}>
                                          <ListItemIcon>
                                             <ShareOutlinedIcon fontSize='small' />
                                          </ListItemIcon>
                                          Share Chat
                                       </MenuItem>
                                       <MenuItem onClick={handleClose}>
                                          <ListItemIcon>
                                             <FileDownloadOutlinedIcon fontSize='small' />
                                          </ListItemIcon>
                                          Download Chat
                                       </MenuItem>
                                       <MenuItem  >
                                          <ListItemIcon>
                                             <DeleteOutlineOutlinedIcon fontSize='small' />
                                          </ListItemIcon>
                                          Delete
                                       </MenuItem>
                                       </Menu>
                                    </div>
                                 </li>
                                 <li>
                                    <Link to="/">
                                    <div className='history_line'> Let's take a look. Your birth chart indicates that you possess a strong drive for success </div>
                                    </Link>
                                    <div className='history_action'>
                                       <Tooltip title="Actions">
                                          <Link
                                          onClick={handleClickaction}
                                          size="small"
                                          aria-controls={open ? 'account-menu' : undefined}
                                          aria-haspopup="true"
                                          aria-expanded={open ? 'true' : undefined}
                                          >
                                          <MoreHorizIcon />
                                          </Link>
                                       </Tooltip>
                                       <Menu
                                       anchorEl={anchorEl}
                                       id="account-menu"
                                       open={openhistoryaction}
                                       onClose={handleClose}
                                       onClick={handleClose}
                                       PaperProps={{
                                       elevation: 0,
                                       sx: {
                                       overflow: 'visible',
                                       filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                       mt: 1.5,
                                       '& .MuiAvatar-root': {
                                       width: 32,
                                       height: 32,
                                       ml: -0.5,
                                       mr: 1,
                                       },
                                       '&::before': {
                                       content: '""',
                                       display: 'block',
                                       position: 'absolute',
                                       top: 0,
                                       right: 14,
                                       width: 10,
                                       height: 10,
                                       bgcolor: 'background.paper',
                                       transform: 'translateY(-50%) rotate(45deg)',
                                       zIndex: 0,
                                       },
                                       },
                                       }}
                                       transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                       anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                       >
                                       <MenuItem onClick={activeeditfile}>
                                          <ListItemIcon>
                                             <ShareOutlinedIcon fontSize='small' />
                                          </ListItemIcon>
                                          Share Chat
                                       </MenuItem>
                                       <MenuItem onClick={handleClose}>
                                          <ListItemIcon>
                                             <FileDownloadOutlinedIcon fontSize='small' />
                                          </ListItemIcon>
                                          Download Chat
                                       </MenuItem>
                                       <MenuItem  >
                                          <ListItemIcon>
                                             <DeleteOutlineOutlinedIcon fontSize='small' />
                                          </ListItemIcon>
                                          Delete
                                       </MenuItem>
                                       </Menu>
                                    </div>
                                 </li>
                                 <li>
                                    <Link to="/">
                                    <div className='history_line'>A Journey through Love, Career, and Finance </div>
                                    </Link>
                                    <div className='history_action'>
                                       <Tooltip title="Actions">
                                          <Link
                                          onClick={handleClickaction}
                                          size="small"
                                          aria-controls={open ? 'account-menu' : undefined}
                                          aria-haspopup="true"
                                          aria-expanded={open ? 'true' : undefined}
                                          >
                                          <MoreHorizIcon />
                                          </Link>
                                       </Tooltip>
                                       <Menu
                                       anchorEl={anchorEl}
                                       id="account-menu"
                                       open={openhistoryaction}
                                       onClose={handleClose}
                                       onClick={handleClose}
                                       PaperProps={{
                                       elevation: 0,
                                       sx: {
                                       overflow: 'visible',
                                       filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                       mt: 1.5,
                                       '& .MuiAvatar-root': {
                                       width: 32,
                                       height: 32,
                                       ml: -0.5,
                                       mr: 1,
                                       },
                                       '&::before': {
                                       content: '""',
                                       display: 'block',
                                       position: 'absolute',
                                       top: 0,
                                       right: 14,
                                       width: 10,
                                       height: 10,
                                       bgcolor: 'background.paper',
                                       transform: 'translateY(-50%) rotate(45deg)',
                                       zIndex: 0,
                                       },
                                       },
                                       }}
                                       transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                       anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                       >
                                       <MenuItem onClick={activeeditfile}>
                                          <ListItemIcon>
                                             <ShareOutlinedIcon fontSize='small' />
                                          </ListItemIcon>
                                          Share Chat
                                       </MenuItem>
                                       <MenuItem onClick={handleClose}>
                                          <ListItemIcon>
                                             <FileDownloadOutlinedIcon fontSize='small' />
                                          </ListItemIcon>
                                          Download Chat
                                       </MenuItem>
                                       <MenuItem  >
                                          <ListItemIcon>
                                             <DeleteOutlineOutlinedIcon fontSize='small' />
                                          </ListItemIcon>
                                          Delete
                                       </MenuItem>
                                       </Menu>
                                    </div>
                                 </li>
                           
                                 <li>
                                    <Link to="/">
                                    <div className='history_line'> Navigating Life's Pathways </div>
                                    </Link>
                                    <div className='history_action'>
                                       <Tooltip title="Actions">
                                          <Link
                                          onClick={handleClickaction}
                                          size="small"
                                          aria-controls={open ? 'account-menu' : undefined}
                                          aria-haspopup="true"
                                          aria-expanded={open ? 'true' : undefined}
                                          >
                                          <MoreHorizIcon />
                                          </Link>
                                       </Tooltip>
                                       <Menu
                                       anchorEl={anchorEl}
                                       id="account-menu"
                                       open={openhistoryaction}
                                       onClose={handleClose}
                                       onClick={handleClose}
                                       PaperProps={{
                                       elevation: 0,
                                       sx: {
                                       overflow: 'visible',
                                       filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                       mt: 1.5,
                                       '& .MuiAvatar-root': {
                                       width: 32,
                                       height: 32,
                                       ml: -0.5,
                                       mr: 1,
                                       },
                                       '&::before': {
                                       content: '""',
                                       display: 'block',
                                       position: 'absolute',
                                       top: 0,
                                       right: 14,
                                       width: 10,
                                       height: 10,
                                       bgcolor: 'background.paper',
                                       transform: 'translateY(-50%) rotate(45deg)',
                                       zIndex: 0,
                                       },
                                       },
                                       }}
                                       transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                       anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                       >
                                       <MenuItem onClick={activeeditfile}>
                                          <ListItemIcon>
                                             <ShareOutlinedIcon fontSize='small' />
                                          </ListItemIcon>
                                          Share Chat
                                       </MenuItem>
                                       <MenuItem onClick={handleClose}>
                                          <ListItemIcon>
                                             <FileDownloadOutlinedIcon fontSize='small' />
                                          </ListItemIcon>
                                          Download Chat
                                       </MenuItem>
                                       <MenuItem  >
                                          <ListItemIcon>
                                             <DeleteOutlineOutlinedIcon fontSize='small' />
                                          </ListItemIcon>
                                          Delete
                                       </MenuItem>
                                       </Menu>
                                    </div>
                                 </li>
                                 <li>
                                    <Link to="/">
                                    <div className='history_line'> Exploring Destiny with AI Astrologer </div>
                                    </Link>
                                    <div className='history_action'>
                                       <Tooltip title="Actions">
                                          <Link
                                          onClick={handleClickaction}
                                          size="small"
                                          aria-controls={open ? 'account-menu' : undefined}
                                          aria-haspopup="true"
                                          aria-expanded={open ? 'true' : undefined}
                                          >
                                          <MoreHorizIcon />
                                          </Link>
                                       </Tooltip>
                                       <Menu
                                       anchorEl={anchorEl}
                                       id="account-menu"
                                       open={openhistoryaction}
                                       onClose={handleClose}
                                       onClick={handleClose}
                                       PaperProps={{
                                       elevation: 0,
                                       sx: {
                                       overflow: 'visible',
                                       filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                       mt: 1.5,
                                       '& .MuiAvatar-root': {
                                       width: 32,
                                       height: 32,
                                       ml: -0.5,
                                       mr: 1,
                                       },
                                       '&::before': {
                                       content: '""',
                                       display: 'block',
                                       position: 'absolute',
                                       top: 0,
                                       right: 14,
                                       width: 10,
                                       height: 10,
                                       bgcolor: 'background.paper',
                                       transform: 'translateY(-50%) rotate(45deg)',
                                       zIndex: 0,
                                       },
                                       },
                                       }}
                                       transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                       anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                       >
                                       <MenuItem onClick={activeeditfile}>
                                          <ListItemIcon>
                                             <ShareOutlinedIcon fontSize='small' />
                                          </ListItemIcon>
                                          Share Chat
                                       </MenuItem>
                                       <MenuItem onClick={handleClose}>
                                          <ListItemIcon>
                                             <FileDownloadOutlinedIcon fontSize='small' />
                                          </ListItemIcon>
                                          Download Chat
                                       </MenuItem>
                                       <MenuItem  >
                                          <ListItemIcon>
                                             <DeleteOutlineOutlinedIcon fontSize='small' />
                                          </ListItemIcon>
                                          Delete
                                       </MenuItem>
                                       </Menu>
                                    </div>
                                 </li>
                                 <li>
                                    <Link to="/">
                                    <div className='history_line'>Deep Dives with the Astrology AI </div>
                                    </Link>
                                    <div className='history_action'>
                                       <Tooltip title="Actions">
                                          <Link
                                          onClick={handleClickaction}
                                          size="small"
                                          aria-controls={open ? 'account-menu' : undefined}
                                          aria-haspopup="true"
                                          aria-expanded={open ? 'true' : undefined}
                                          >
                                          <MoreHorizIcon />
                                          </Link>
                                       </Tooltip>
                                       <Menu
                                       anchorEl={anchorEl}
                                       id="account-menu"
                                       open={openhistoryaction}
                                       onClose={handleClose}
                                       onClick={handleClose}
                                       PaperProps={{
                                       elevation: 0,
                                       sx: {
                                       overflow: 'visible',
                                       filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                       mt: 1.5,
                                       '& .MuiAvatar-root': {
                                       width: 32,
                                       height: 32,
                                       ml: -0.5,
                                       mr: 1,
                                       },
                                       '&::before': {
                                       content: '""',
                                       display: 'block',
                                       position: 'absolute',
                                       top: 0,
                                       right: 14,
                                       width: 10,
                                       height: 10,
                                       bgcolor: 'background.paper',
                                       transform: 'translateY(-50%) rotate(45deg)',
                                       zIndex: 0,
                                       },
                                       },
                                       }}
                                       transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                       anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                       >
                                       <MenuItem onClick={activeeditfile}>
                                          <ListItemIcon>
                                             <ShareOutlinedIcon fontSize='small' />
                                          </ListItemIcon>
                                          Share Chat
                                       </MenuItem>
                                       <MenuItem onClick={handleClose}>
                                          <ListItemIcon>
                                             <FileDownloadOutlinedIcon fontSize='small' />
                                          </ListItemIcon>
                                          Download Chat
                                       </MenuItem>
                                       <MenuItem  >
                                          <ListItemIcon>
                                             <DeleteOutlineOutlinedIcon fontSize='small' />
                                          </ListItemIcon>
                                          Delete
                                       </MenuItem>
                                       </Menu>
                                    </div>
                                 </li>
                                 <li>
                                    <Link to="/">
                                    <div className='history_line'> Let's take a look. Your birth chart indicates that you possess a strong drive for success </div>
                                    </Link>
                                    <div className='history_action'>
                                       <Tooltip title="Actions">
                                          <Link
                                          onClick={handleClickaction}
                                          size="small"
                                          aria-controls={open ? 'account-menu' : undefined}
                                          aria-haspopup="true"
                                          aria-expanded={open ? 'true' : undefined}
                                          >
                                          <MoreHorizIcon />
                                          </Link>
                                       </Tooltip>
                                       <Menu
                                       anchorEl={anchorEl}
                                       id="account-menu"
                                       open={openhistoryaction}
                                       onClose={handleClose}
                                       onClick={handleClose}
                                       PaperProps={{
                                       elevation: 0,
                                       sx: {
                                       overflow: 'visible',
                                       filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                       mt: 1.5,
                                       '& .MuiAvatar-root': {
                                       width: 32,
                                       height: 32,
                                       ml: -0.5,
                                       mr: 1,
                                       },
                                       '&::before': {
                                       content: '""',
                                       display: 'block',
                                       position: 'absolute',
                                       top: 0,
                                       right: 14,
                                       width: 10,
                                       height: 10,
                                       bgcolor: 'background.paper',
                                       transform: 'translateY(-50%) rotate(45deg)',
                                       zIndex: 0,
                                       },
                                       },
                                       }}
                                       transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                       anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                       >
                                       <MenuItem onClick={activeeditfile}>
                                          <ListItemIcon>
                                             <ShareOutlinedIcon fontSize='small' />
                                          </ListItemIcon>
                                          Share Chat
                                       </MenuItem>
                                       <MenuItem onClick={handleClose}>
                                          <ListItemIcon>
                                             <FileDownloadOutlinedIcon fontSize='small' />
                                          </ListItemIcon>
                                          Download Chat
                                       </MenuItem>
                                       <MenuItem  >
                                          <ListItemIcon>
                                             <DeleteOutlineOutlinedIcon fontSize='small' />
                                          </ListItemIcon>
                                          Delete
                                       </MenuItem>
                                       </Menu>
                                    </div>
                                 </li>
                              </ul>
                           </div>
                       
                        </div>
                     </div>
               </div>
               </List>
            </div>
            <div className='astro-aside_cta'>
               <div className='astro-aside_cta_head'>
                  <div className='astro_aside_cta_title'>
                     <h3>Lagna / Ascendant / Basic Birth Chart</h3>
                  </div>
               </div>
               <div className='astro-aside_cta_body'>
                  <div className='kundli_image'>
                     <img src={kundli} alt='kundli'/>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div className='asrtro_chat_right'>
         <div className='deskhome_chat_wrapper '>
            <div className='desk_chat_wrapper'>
               <div className='desk_chat_head'>
                  <div className='top-chatbar'>
                     <div className='chat_title'>
                        <div class="d-flex align-items-center gap-3">
                           <h2>Untitled   </h2>
                           <span className="chattime"><i className="las la-clock"></i> 2h ago</span>
                        </div>
                     </div>
                  </div>
               </div>
               <div className='desk_chat_body'>
                  <div className='chat_container'>
                     <div className='desk_chat_panal'>
                        <div className='desk_chat_inner_box '>
                           <div className='desk_chat_contentwrapper'>
                              <div className='desk_chat_contentbox'>
                                 <div className='desk_chat_bodydown'>
                                    <div className='desk_chat_body_Inner'>
                                       <div className='desk_message_list_container'>
                                          <div className='desk_message_list '>
                                             <div className='chat_message'>
                                                <div className='chat-box'>
                                                   <div className='chat-flex'>
                                                      <div className='chat_avtar'>
                                                         <Avatar sx={{ bgcolor: deepOrange[500] }}>HS</Avatar>
                                                      </div>
                                                      <div className='chat_content'>
                                                         <div className='chat_sender'>you</div>
                                                         <div className='chat_responcebox'>
                                                            <div className='answer_content'> 
                                                               i am creating astrology ai product  ai chatboar  pridict and answer bass on people date of birth i want a banner tagline and small pera  related for this concept
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                                <div className='chat-box'>
                                                   <div className='chat-flex'>
                                                      <div className='chat_avtar'>
                                                         <Avatar sx={{ bgcolor: deepPurple[500] }} src={astroai}>AI</Avatar>
                                                      </div>
                                                      <div className='chat_content'>
                                                         <div className='chat_sender'> AI Astrologer</div>
                                                         <div className='chat_responcebox'>
                                                            <div className='answer_wrapper'>
                                                               <div className='answer_body'>
                                                                  {/* <motion.div
                                                                  initial={{ opacity: 0, y: 3 }}
                                                                  animate={{ opacity: 1, y: 0 }}
                                                                  transition={{ duration: 0.4, delay: 0.2, }}
                                                                  className='skeleton_loader' style={{ display: 'none' }}>
                                                                  <ChatContentLoader />
                                                                  </motion.div> */}
                                                                  <motion.div
                                                                  initial={{ opacity: 0, y: 5 }}
                                                                  animate={{ opacity: 1, y: 0 }}
                                                                  transition={{ duration: 0.4, delay: 0.2, }}
                                                                  className='answer_content   '>
                                                                  <p>Spring is a beautiful season to travel and explore new destinations. Some of the best spring destinations around the world include:</p>
                                                                  <ul className="list-outside list-disc pl-8">
                                                                     <li>
                                                                        <span className>
                                                                        <strong>Stavanger, Norway</strong>
                                                                        </span>
                                                                        <span className>: Famous among trekking lovers and lovers of the most beautiful landscapes in Europe.</span>
                                                                     </li>
                                                                     <li>
                                                                        <span className>
                                                                        <strong>Dublin, Ireland</strong>
                                                                        </span>
                                                                        <span className>: The capital of Ireland is even more alive on sunny spring days with numerous music and cooking festivals, walks, and discoveries.</span>
                                                                     </li>
                                                                     <li>
                                                                        <span className>
                                                                        <strong>Porto, Portugal</strong>
                                                                        </span>
                                                                        <span className>: Fantastic throughout the year, Porto has been elected three times as "European best destination".</span>
                                                                     </li>
                                                                     <li>
                                                                        <span className>
                                                                        <strong>Keukenhof, Netherlands</strong>
                                                                        </span>
                                                                        <span className>: A park that is open for a limited period each year, typically from late March to mid-May, and is famous for its tulips.</span>
                                                                     </li>
                                                                     <li>
                                                                        <span className>
                                                                        <strong>Washington DC, USA</strong>
                                                                        </span>
                                                                        <span className>: The city is famous for its cherry blossoms, which give rise to one of the biggest and most beloved spring festivals in the US.</span>
                                                                     </li>
                                                                     <li>
                                                                        <span className>
                                                                        <strong>Seoul, South Korea</strong>
                                                                        </span>
                                                                        <span className>: The city is famous for its cherry blossoms and azaleas, which typically take center stage from mid-March to early April.</span>
                                                                     </li>
                                                                     <li>
                                                                        <span className>
                                                                        <strong>Castelluccio, Italy</strong>
                                                                        </span>
                                                                        <span className>: A village famous for its wildflowers, which bloom between late May and early July.</span>
                                                                     </li>
                                                                     <li>
                                                                        <span className>
                                                                        <strong>Skagit Valley, USA</strong>
                                                                        </span>
                                                                        <span className>: A vibrant, living watercolor painting with tulips, daffodils, and irises blooming in the fields.</span>
                                                                     </li>
                                                                     <li>
                                                                        <span className>
                                                                        <strong>Washington DC, USA</strong>
                                                                        </span>
                                                                        <span className>: The city is famous for its cherry blossoms, which give rise to one of the biggest and most beloved spring festivals in the US.</span>
                                                                     </li>
                                                                     <li>
                                                                        <span className>
                                                                        <strong>Seoul, South Korea</strong>
                                                                        </span>
                                                                        <span className>: The city is famous for its cherry blossoms and azaleas, which typically take center stage from mid-March to early April.</span>
                                                                     </li>
                                                                     <li>
                                                                        <span className>
                                                                        <strong>Castelluccio, Italy</strong>
                                                                        </span>
                                                                        <span className>: A village famous for its wildflowers, which bloom between late May and early July.</span>
                                                                     </li>
                                                                     <li>
                                                                        <span className>
                                                                        <strong>Skagit Valley, USA</strong>
                                                                        </span>
                                                                        <span className>: A vibrant, living watercolor painting with tulips, daffodils, and irises blooming in the fields.</span>
                                                                     </li>
                                                                  </ul>
                                                                  </motion.div>
                                                                  <div className='answer_actions'>
                                                                     <div className='left_action'>
                                                                        <div className='regeneratechat'>
                                                                           <div className='changechat_flex'>
                                                                              <IconButton aria-label="previous page" onClick={handleBack} disabled={page === 1}>
                                                                              <ArrowBackIosIcon fontSize="small" />
                                                                              </IconButton>
                                                                              <span>{`${page}/${totalPages}`}</span>
                                                                              <IconButton aria-label="next page" onClick={handleNext} disabled={page === totalPages}>
                                                                                 <ArrowForwardIosIcon fontSize="small" />
                                                                              </IconButton>
                                                                           </div>
                                                                           <IconButton aria-label="previous page">
                                                                              <Tooltip className="blue-tooltip" title="Regenerate" placement="bottom" arrow>
                                                                                 <ReplayOutlinedIcon fontSize='small' />
                                                                              </Tooltip>
                                                                           </IconButton>
                                                                        </div>
                                                                        <div className='likedislike'>
                                                                           <Tooltip className="blue-tooltip" title="Like" TransitionComponent={Zoom} placement="top" arrow>
                                                                              <Checkbox {...label} icon={
                                                                              <ThumbUpAltOutlinedIcon />
                                                                              } checkedIcon={
                                                                              <ThumbUpIcon />
                                                                              } />
                                                                           </Tooltip>
                                                                           <Tooltip className="blue-tooltip" title="Dislike" TransitionComponent={Zoom} placement="top" arrow>
                                                                              <Checkbox {...label} icon={
                                                                              <ThumbDownAltOutlinedIcon />
                                                                              } checkedIcon={
                                                                              <ThumbDownIcon />
                                                                              } />
                                                                           </Tooltip>
                                                                        </div>
                                                                        <div className='sharechat'>
                                                                           <Tooltip className="blue-tooltip" title="Share" TransitionComponent={Zoom} placement="top" arrow>
                                                                              <Link to="">
                                                                              <Chip variant="outlined" color="info" label="Share" icon={
                                                                              <ShareOutlinedIcon fontSize='small' />
                                                                              } />
                                                                              </Link>
                                                                           </Tooltip>
                                                                        </div>
                                                                        <div className="copychat">
                                                                           <Tooltip className="blue-tooltip" title="Copy Message" TransitionComponent={Zoom} placement="top" arrow>
                                                                              <Chip
                                                                              label="Copy"
                                                                              variant="outlined"
                                                                              onClick={handleClick}
                                                                              color={isSuccess ? 'success' : 'primary'}
                                                                              icon={
                                                                              isLoading ? (
                                                                              <CircularProgress size={15} />
                                                                              ) : isSuccess ? (
                                                                              <CheckIcon fontSize='small' />
                                                                              ) : null
                                                                              }
                                                                              />
                                                                           </Tooltip>
                                                                        </div>
                                                                        <div className='playpause'>
                                                                           {isAudioPlaying ? (
                                                                           <>
                                                                           <Tooltip className="blue-tooltip" title="Pause Audio" TransitionComponent={Zoom} placement="top" arrow>
                                                                              <Button variant="text" style={{ textTransform: 'capitalize', display: 'flex', alignItems: 'center', gap: 4 }} onClick={handlePauseAudio} className='text-danger'>
                                                                              <PauseCircleOutlineOutlinedIcon />
                                                                              <span>Pause</span>
                                                                              </Button>
                                                                           </Tooltip>
                                                                           </>
                                                                           ) : (
                                                                           <>
                                                                           <Tooltip className="blue-tooltip" title="Listen Audio" TransitionComponent={Zoom} placement="top" arrow>
                                                                              <Button variant="text" style={{ textTransform: 'capitalize', display: 'flex', alignItems: 'center', gap: 4 }} onClick={handlePlayAudio} className=' ' >
                                                                              <PlayCircleFilledWhiteOutlinedIcon />
                                                                              <span>Listen</span>
                                                                              </Button>
                                                                           </Tooltip>
                                                                           </>
                                                                           )}
                                                                        </div>
                                                                        <div className='switchlanguage'>
                                                                           <Tooltip className="blue-tooltip" title="Change Language" TransitionComponent={Zoom} placement="top" arrow>
                                                                              <FormControlLabel size="small"
                                                                              control={<IOSSwitch size='small' sx={{ m: 1 }} fontSize="small" checked={currentLanguage === 'hindi'} onChange={handleLanguageToggle} />}
                                                                              label={currentLanguage === 'hindi' ? 'हिंदी' : 'En'}
                                                                              />
                                                                           </Tooltip>
                                                                        </div>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                          
                                       </div>
                                       
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className='bottom_chat_wrapper'>
                  <ChatForm/>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
</div>
)
}