import { useEffect } from 'react';
import host from '../host';

const RedirectToAstroHomepage = () => {
  useEffect(() => {
    window.location.href = host.astro_homepage;
  }, []);

  return null;
};

export default RedirectToAstroHomepage;