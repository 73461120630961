import React, { useState  } from 'react'; 
import HeaderPage from '../_layout/elements/header';
import FooterPage from '../_layout/elements/footer';
import {  Breadcrumbs,Typography } from '@mui/material'; 
 
import Assessmentapi from "../assets/images/AssessmentAPI.jpeg";
 
import { Link } from 'react-router-dom';
 
export const Blogs = () => {
 
return (
<div>
   <div className='inner-header'>
      <HeaderPage />
   </div>
   <section className='padding_medium  minibanner  blog-banner'  >
      <div className='container'>
         <div className='row align-items-center justify-content-between'>
            <div className='col-md-12'>
               <div className='minibanner-content'>
                  <h2>Blogs </h2>
                  <div className='blog-breadcrumbs'>
                     {/* <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" href="/home">
                        MUI
                        </Link>
                        <Link
                           underline="hover"
                           color="inherit"
                           href="/material-ui/getting-started/installation/"
                           >
                        Core
                        </Link>
                        <Typography color="text.primary">Breadcrumbs</Typography>
                     </Breadcrumbs> */}
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
   <section className='padding_medium' id="blog">
      <div className='container'>
         <div className='row '>
          <div className='blog-blocks-wrapper'>

<div className='row'>

<div className='col-lg-6'>

<div className='blog-block'>
<Link to="/blog-detail">
<div className='blog-image'>

<img src={Assessmentapi} />

</div>

<div className='blog-block-content'>
<div className='blog-type'>
<span className='update-date'>January 14 2024</span>

  <span className='type-btn'>Guide</span>

</div>
<div className='blog-title'>

<h4> Streamlining Evaluations: A Step-by-Step Tour of Assessment API</h4>

</div>
<div className='blog-pera'> 
<p>Effortlessly integrate our Assessment API from fetching your access key to utilizing key features like Assessment Creation, Invite, and Result APIs. Whether you're new or experienced, our step-by-step guide ensures seamless and effective evaluation processes.</p>
</div>
</div>
</Link>

</div>

</div>
 


</div>



          </div>
    
      </div>
</div>
</section>
<FooterPage />
</div>
)
}