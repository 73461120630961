import React, { useState  } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { TextField,Modal,Box,FormControl, MenuItem,Select,InputLabel,DialogActions} from '@mui/material';
 
export default function PaymentStatus(props) {
 const navigate = useNavigate();
return (
<>
<Modal
   open={props.openpaymentstatus}
   onClose={props.handleClosepaymentstatus} 
   aria-labelledby="modal-modal-title"
   aria-describedby="modal-modal-description"
   >
   <Box className=" commonpopup  modal-common   paymentbg  popup-w35 "  >
      <div className="modal-body">
         {/* <div className="card-item__content   mb-2">
            <div class="card-title">
               <h2>Signup</h2>
               <span>Add your detail to get Horoscope</span>
            </div>
            <Link  className="closepopup" onClick={props.handleClosepaymentstatus}>
            X</Link>
         </div> */}


         <div className='payment_status_wrapper'>

<div className='payment_success_wrapper'>

<div className='payment_head'>
<div className="success-checkmark">
          <div className="check-icon">
            <span className="icon-line line-tip" />
            <span className="icon-line line-long" />
            <div className="icon-circle" />
            <div className="icon-fix" />
          </div>
        </div>

        <div className='payment_status_title success_text'> Payment Successful</div>

</div>

<div className='payment_body'>
<div className='payment_content_modal'>
<p>Your Premium Personalised Kundali will be sent on your registered email id in 5-6 Working Days.</p>
</div>
<div className='payment_button'>

   <button type='button' className='btn btn-primary' onClick={() => {navigate('/')}}> Go to HomePage</button>
</div>
</div>


</div>

<div className='payment_error_wrapper  d-none'>

<div className='payment_head'>
<div className="ui-error">
        <svg viewBox="0 0 87 87" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
          <g id="Page-1" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
            <g id="Group-2" transform="translate(2.000000, 2.000000)">
              <circle id="Oval-2" stroke="rgba(252, 191, 191, .5)" strokeWidth={4} cx="41.5" cy="41.5" r="41.5" />
              <circle className="ui-error-circle" stroke="#F74444" strokeWidth={4} cx="41.5" cy="41.5" r="41.5" />
              <path className="ui-error-line1" d="M22.244224,22 L60.4279902,60.1837662" id="Line" stroke="#F74444" strokeWidth={3} strokeLinecap="square" />
              <path className="ui-error-line2" d="M60.755776,21 L23.244224,59.8443492" id="Line" stroke="#F74444" strokeWidth={3} strokeLinecap="square" />
            </g>
          </g>
        </svg>
      </div>

        <div className='payment_status_title unsuccess_text'> Payment Failed</div>

</div>

<div className='payment_body'>
<div className='payment_content_modal'>
   <p>Please go back and try again</p>
<p>Uh-oh! It looks like there was an issue processing your payment. Please double-check your payment details and try again. If the problem persists, feel free to reach out to our support team for assistance. We apologize for any inconvenience this may have caused.</p>
</div>
<div className='payment_button'>

   <button type='button' className='btn btn-primary'> Try Again</button>
</div>
</div>


</div>





         </div>
      
      </div>
   </Box>
</Modal>
</>
)
}