import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { changeAuth } from "../actions/loginAction";
import { useDispatch } from "react-redux";
import { Dots } from "loading-animations-react";
import { Helmet } from "react-helmet";
const TITLE = "Navivibe";
export const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    localStorage.clear();
    navigate("/login");
    // setLoading(true);
    // dispatch(changeAuth(false, null));
    // setLoading(false);
  }, []);

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      {loading ? (
        <div style={{ display: "flex", height: 95 + "vh", width: 100 + "%", justifyContent: "center", alignItems: "center", fontSize: 20, flexDirection: "column" }}>
          <Dots dotColors={["red", "white", "blue", "#123abc", "rgb(50,50,50)", "hsla(235, 100%, 50%, .5)"]} />
          Logging out ...
        </div>
      ) : (
        ""
      )}
    </>
  );
};
