import React from "react";
import { Link } from "react-router-dom";
import footerlogo from "../../assets/images/logo.png";
import { Tooltip, Zoom } from "@mui/material";
function FooterPage() {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  return (
    <div>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12">
              <div className="footer-logo">
                <img src={footerlogo} height={30} alt="" />
              </div>
              <div className="aboutcompany">
                <p>
                  NaviVibe is an AI-based astrology platform offering personalized and confidential astrological guidance. Users can chat with NaviVibe's AI Guruji about various life issues, receiving
                  advice tailored to their specific needs and experiences. The platform ensures privacy and leverages a vast database of astrological cases to provide accurate predictions. Users can
                  obtain personalized chats, comprehensive life reports, daily horoscopes, customized calendars, and more, making NaviVibe a modern, accessible way to explore astrology and gain
                  insights into their lives anytime, anywhere.
                </p>
              </div>
              <div className="Prepp-Community">
                <h5>Astroguruji Community </h5>
                <div className="social">
                <ul>
                        
                        <li>
                           <Tooltip title="Fecabook" TransitionComponent={Zoom} placement="top" arrow>
                              <Link to="https://www.facebook.com/MyNaviVibe/" target='blonk' className=''  >
                              <i class="lab la-facebook-f"></i></Link>
                           </Tooltip>
                        </li>
                        <li>
                           <Tooltip title="Instagram" TransitionComponent={Zoom} placement="top" arrow>
                              <Link to="https://www.instagram.com/navivibe.ai"  target='blonk' className=''  >
                              <i class="lab la-instagram"></i></Link>
                           </Tooltip>
                        </li>
               
                       
                        <li>
                           <Tooltip title="linkedin" TransitionComponent={Zoom} placement="top" arrow>
                              <Link to="https://www.linkedin.com/showcase/navivibe" target='blonk' className=''  >
                              <i class="lab la-linkedin-in"></i></Link>
                           </Tooltip>
                        </li>
                     </ul>
                </div>
              </div>
              <div className="Prepp-Community">
                <h5>Contact Sales </h5>
                <Link className="mt-2 d-block" to="mailto:sales@ngnext.tech">
                  sales@ngnext.tech
                </Link>
              </div>
            </div>
            <div className="col-lg-8 col-md-12">
              <div className="footer-all-links">
                <div className="footer-menubox">
                  <div className="footer-menu-head">
                    <h5>Important Links</h5>
                  </div>
                  <ul>
                    <li>
                      <Link to="/new-astro-chatbot" onClick={() => scrollToSection("generatebtai")}>
                        Consult Guruji
                      </Link>
                    </li>
                    <li>
                      <Link to="/personalized-horoscope" onClick={() => scrollToSection("CreateLibrary")}>
                        Personalized Horoscope
                      </Link>
                    </li>
                    <li>
                      <Link to="/life-report" onClick={() => scrollToSection("Industry")}>
                        Report
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="footer-menubox">
                  <div className="footer-menu-head">
                    <h5>Horoscope</h5>
                  </div>
                  <ul>
                    <li>
                      <Link to="/personalized-horoscope?sortBy=0">Today's Horoscope</Link>
                    </li>
                    <li>
                      <Link to="/personalized-horoscope?sortBy=1">Tomorrow's Horoscope</Link>
                    </li>
                    <li>
                      <Link to="/personalized-horoscope?sortBy=2">Yesterday's Horoscope</Link>
                    </li>
                    <li>
                      <Link to="/personalized-horoscope?sortBy=3">This Week's Horoscope</Link>
                    </li>
                    <li>
                      <Link to="/personalized-horoscope?sortBy=4">This Month's Horoscope</Link>
                    </li>
                    <li>
                      <Link to="/personalized-horoscope?sortBy=5">This Year's Horoscope</Link>
                    </li>
                  </ul>
                </div>
                <div className="footer-menubox">
                  <div className="footer-menu-head">
                    <h5>Corporate Info</h5>
                  </div>
                  <ul>
                    <li>
                      <a href="https://ngnext.tech/aboutus/" target="_blank" rel="noopener noreferrer">
                        About Us
                      </a>
                    </li>
                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                    <li>
                      <Link to="/plans">Plans</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="bottom-footer">
        <div className="container">
          <div className="row">
            <div className="bottom-footer-box">
              <div className="terms-link">
                <ul>
                  <li>
                    <Link to="/terms">Terms & Conditions</Link>
                  </li>
                  <li>
                    <Link to="/terms">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/terms">Refund Policy</Link>
                  </li>
                </ul>
              </div>
              <div className="terms-link">
                <ul>
                  <li>
                    Copyright @ 2020-2024
                    <Link to="https://ngnext.tech/" target="blank">
                      NG-NeXT Tech Private Limited
                    </Link>{" "}
                    , All rights reserved
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default FooterPage;
