import React, { useState  } from 'react';
import { Link } from 'react-router-dom'
import { TextField,Modal,Box,FormControl, MenuItem,Select,InputLabel,DialogActions} from '@mui/material';
 
export default function Signuppopup(props) {
 
return (
<>
<Modal
   open={props.opensignup}
   onClose={props.handleClosesignup} 
   aria-labelledby="modal-modal-title"
   aria-describedby="modal-modal-description"
   >
   <Box className=" commonpopup  modal-common popup-w35 "  >
      <div className="modal-body">
         <div className="card-item__content   mb-2">
            <div class="card-title">
               <h2>Signup</h2>
               <span>Add your detail to get Horoscope</span>
            </div>
            <Link  className="closepopup" onClick={props.handleClosesignup}>
            X</Link>
         </div>
         <div className='popup-knowledge'>
            <div className='whatpdf_wrapper'>
               <div className='setting_upload'>
                  <div className='row' >

                  <div className="col-xxl-12 col-lg-12 col-md-12 col-12">
                              <div className="form-group">
                                 <FormControl fullWidth>
                                 <TextField  
                              required
                                 size='small'
                                    type="text"
                                   label="Name"
                                   placeholder='Enter name' />

</FormControl>
                              </div>
                           </div>
                     <div className='col-md-12 '>
                        <div className='form-group  '>
                        <FormControl  fullWidth size='small' >
                        <InputLabel id="demo-simple-select-label">Select gender <span className='text-danger'><sup>*</sup></span></InputLabel>
     <Select
       labelId="demo-select-small-label"
       id="demo-select-small"
    placeholder="Select request" 
     >
     
       <MenuItem value={10}>Male </MenuItem>
       <MenuItem value={20}>Female</MenuItem>
    
     </Select>
   </FormControl>
                        </div>
                     </div>

                     <div className="col-xxl-12 col-lg-12 col-md-12 col-12">
                              <div className="form-group">
<label style={{fontWeight:'600'}}>Birth Details</label>
                                 </div>


<div className='row'>

<div className="col-xxl-4 col-lg-4 col-md-4 col-12" >
<div className="form-group">
<label>Day <span className='text-danger'><sup>*</sup></span></label>

<FormControl  fullWidth size='small' >
                     
     <Select
       labelId="demo-select-small-label"
       id="demo-select-small"
       defaultValue={0}
    placeholder="Select request" 
     >
      <MenuItem value={0}>Select </MenuItem>
       <MenuItem value={1}>1 </MenuItem>
       <MenuItem value={2}>2</MenuItem>
       <MenuItem value={3}>3 </MenuItem>
       <MenuItem value={4}>4</MenuItem>
       <MenuItem value={5}>5 </MenuItem>
       <MenuItem value={6}>6</MenuItem>
       <MenuItem value={7}>7 </MenuItem>
       <MenuItem value={8}>8</MenuItem>
       <MenuItem value={9}>9 </MenuItem>
       <MenuItem value={10}>10</MenuItem>
       <MenuItem value={11}>11 </MenuItem>
       <MenuItem value={12}>12</MenuItem>
       <MenuItem value={13}>13 </MenuItem>
       <MenuItem value={14}>14</MenuItem>
       <MenuItem value={15}>15 </MenuItem>
       <MenuItem value={16}>16</MenuItem>
       <MenuItem value={17}>17 </MenuItem>
       <MenuItem value={18}>18</MenuItem>
       <MenuItem value={19}>19 </MenuItem>
       <MenuItem value={20}>20</MenuItem>
       <MenuItem value={21}>21 </MenuItem>
       <MenuItem value={22}>22</MenuItem>
       <MenuItem value={23}>23 </MenuItem>
       <MenuItem value={24}>24</MenuItem>
       <MenuItem value={25}>25 </MenuItem>
       <MenuItem value={26}>26</MenuItem>
       <MenuItem value={27}>27 </MenuItem>
       <MenuItem value={28}>28</MenuItem>
       <MenuItem value={29}>29 </MenuItem>
       <MenuItem value={30}>30</MenuItem>
       <MenuItem value={31}>31 </MenuItem>
       
     </Select>
   </FormControl>
   </div>

   </div>



   <div className="col-xxl-4 col-lg-4 col-md-4 col-12" >
<div className="form-group">
<label>Month <span className='text-danger'><sup>*</sup></span></label>

<FormControl  fullWidth size='small' >
                     
     <Select
       labelId="demo-select-small-label"
       id="demo-select-small"
       defaultValue={0}
    placeholder="Select request" 
     >
      <MenuItem value={0}>Select </MenuItem>
       <MenuItem value={1}>Jan </MenuItem>
       <MenuItem value={2}>Feb</MenuItem>
       <MenuItem value={3}>Mar </MenuItem>
       <MenuItem value={4}>Apr</MenuItem>
       <MenuItem value={5}>May </MenuItem>
       <MenuItem value={6}>June</MenuItem>
       <MenuItem value={7}>Jul </MenuItem>
       <MenuItem value={8}>Aug</MenuItem>
       <MenuItem value={9}>Sep </MenuItem>
       <MenuItem value={10}>Oct</MenuItem>
       <MenuItem value={11}>Nov </MenuItem>
       <MenuItem value={12}>Dec</MenuItem>
  
       
     </Select>
   </FormControl>
   </div>

   </div>

   <div className="col-xxl-4 col-lg-4 col-md-4 col-12" >
<div className="form-group">
<label>Year <span className='text-danger'><sup>*</sup></span></label>

<FormControl  fullWidth size='small' >
                     
     <Select
       labelId="demo-select-small-label"
       id="demo-select-small"
       defaultValue={0}
    placeholder="Select request" 
     >
      <MenuItem value={0}>Select </MenuItem>
       <MenuItem value={1}>1991 </MenuItem>
       <MenuItem value={2}>1992</MenuItem>
       <MenuItem value={3}>1993 </MenuItem>
       <MenuItem value={4}>1994</MenuItem>
       <MenuItem value={5}>1995 </MenuItem>
       <MenuItem value={6}>1996</MenuItem>
       <MenuItem value={7}>1997 </MenuItem>
       <MenuItem value={8}>1998</MenuItem>
       <MenuItem value={9}>1999 </MenuItem>
       <MenuItem value={10}>2000</MenuItem>
       <MenuItem value={11}>2021 </MenuItem>
       <MenuItem value={12}>2022</MenuItem>
       <MenuItem value={11}>2023 </MenuItem>
       <MenuItem value={12}>2024</MenuItem>
  
       
     </Select>
   </FormControl>
   </div>

   </div>

   <div className="col-xxl-4 col-lg-4 col-md-4 col-12" >
<div className="form-group">
<label>Hour <span className='text-danger'><sup>*</sup></span></label>

<FormControl  fullWidth size='small' >
                     
     <Select
       labelId="demo-select-small-label"
       id="demo-select-small"
       defaultValue={0}
    placeholder="Select request" 
     >
      <MenuItem value={0}>Select </MenuItem>
       <MenuItem value={1}>1 </MenuItem>
       <MenuItem value={2}>2</MenuItem>
       <MenuItem value={3}>3 </MenuItem>
       <MenuItem value={4}>4</MenuItem>
       <MenuItem value={5}>5 </MenuItem>
       <MenuItem value={6}>6</MenuItem>
       <MenuItem value={7}>7 </MenuItem>
       <MenuItem value={8}>8</MenuItem>
       <MenuItem value={9}>9 </MenuItem>
       <MenuItem value={10}>10</MenuItem>
       <MenuItem value={11}>11 </MenuItem>
       <MenuItem value={12}>12</MenuItem>
       <MenuItem value={11}>13 </MenuItem>
       <MenuItem value={12}>14</MenuItem>
       <MenuItem value={12}>15</MenuItem>
       <MenuItem value={11}>16 </MenuItem>
       <MenuItem value={12}>17</MenuItem>
       <MenuItem value={12}>18</MenuItem>
       <MenuItem value={11}>19 </MenuItem>
       <MenuItem value={12}>20</MenuItem>
       <MenuItem value={12}>21</MenuItem>
       <MenuItem value={11}>22 </MenuItem>
       <MenuItem value={11}>23 </MenuItem>
     
     </Select>
   </FormControl>
   </div>

   </div>
   <div className="col-xxl-4 col-lg-4 col-md-4 col-12" >
<div className="form-group">
<label>Minute <span className='text-danger'><sup>*</sup></span></label>

<FormControl  fullWidth size='small' >
                     
     <Select
       labelId="demo-select-small-label"
       id="demo-select-small"
       defaultValue={0}
    placeholder="Select request" 
     >
      <MenuItem value={0}>Select </MenuItem>
       <MenuItem value={1}>1 </MenuItem>
       <MenuItem value={2}>2</MenuItem>
       <MenuItem value={3}>3 </MenuItem>
       <MenuItem value={4}>4</MenuItem>
       <MenuItem value={5}>5 </MenuItem>
       <MenuItem value={6}>6</MenuItem>
     
     
     </Select>
   </FormControl>
   </div>

   </div>
   <div className="col-xxl-4 col-lg-4 col-md-4 col-12" >
<div className="form-group">
<label>Second <span className='text-danger'><sup>*</sup></span></label>

<FormControl  fullWidth size='small' >
                     
     <Select
       labelId="demo-select-small-label"
       id="demo-select-small"
       defaultValue={0}
    placeholder="Select request" 
     >
      <MenuItem value={0}>Select </MenuItem>
       <MenuItem value={1}>1 </MenuItem>
       <MenuItem value={2}>2</MenuItem>
       <MenuItem value={3}>3 </MenuItem>
       <MenuItem value={4}>4</MenuItem>
       <MenuItem value={5}>5 </MenuItem>
       <MenuItem value={6}>6</MenuItem>
     
     
     </Select>
   </FormControl>
   </div>

   </div>


</div>

                                 </div>

                                 <div className="col-xxl-12 col-lg-12 col-md-12 col-12 mt-">
                              <div className="form-group">
                                 <label>Birth Place <span className='text-danger'><sup>*</sup></span></label>
                                 <FormControl fullWidth>
                                 <TextField  
                              required
                                 size='small'
                                    type="text"
                                   
                                   placeholder='Enter place name' />

</FormControl>
                              </div>
                           </div>

               
                  </div>
                 
               </div>

 


    
        <DialogActions>
        <button type='button' className='btn btn-default'  onClick={props.handleClosesignup}  >Close</button>
          <button type='button' className='btn btn-primary'   onClick={props.handleClosesignup} >
       Submit
          </button>
        </DialogActions>
               
            </div>
         </div>
      </div>
   </Box>
</Modal>
</>
)
}