import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Lottie from "lottie-react";  
import 'react-phone-number-input/style.css'
 
import  {Typography ,AccordionDetails,AccordionSummary,Accordion } from '@mui/material';
import HeaderPage from '../_layout/elements/header';
import FooterPage from '../_layout/elements/footer';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
export const FAQ = () => {
 
return (
<div>
<div className='inner-header'>
   <HeaderPage />
   </div>
   <section className="padding_medium  minibanner  blog-banner  ">
      <div className="container" style={{position:'relative'}}>
         <div className="row justify-content-center">
            <div className="col-md-12">
            <div className='minibanner-content'>
            <h2 className=" "     > FAQ's  </h2>
                 
 
                 

            </div>

               
         </div>
      </div>
</div>
</section>
<section className="padding_medium">
   <div className="container" style={{position:'relative'}}>
      <div className="row justify-content-center">
         <div className="col-md-8">
<div className='faq-wrapper'>
         <Accordion>
        <AccordionSummary
          expandIcon={<ArrowDownwardIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <h5>FAQ's 1</h5>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ArrowDownwardIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <h5>FAQ's 2</h5>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ArrowDownwardIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          <h5>FAQ's 3</h5>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ArrowDownwardIcon />}
          aria-controls="panel4-content"
          id="panel4-header"
        >
          <h5>FAQ's 4</h5>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ArrowDownwardIcon />}
          aria-controls="panel5-content"
          id="panel5-header"
        >
          <h5>FAQ's 5</h5>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>

      </div>
         </div>
      </div>
   </div>
</section>
 
<FooterPage />
</div>
)
}