const host = {
    astro_backend:process.env.REACT_APP_ASTRO_BACKEND_URL,
    astro_homepage:process.env.REACT_APP_ASTRO_HOMEPAGE_URL,
    username:process.env.REACT_APP_ASTRO_USERNAME,
    password:process.env.REACT_APP_ASTRO_PASSWORD,
    razorpayKey:process.env.REACT_APP_RAZOR_PAY_KEY,
    astro_url:process.env.REACT_APP_ASTRO_URL,
    textToSpeechApikey:process.env.REACT_APP_TEXT_TO_SPEECH_APIKEY,
    api_key:process.env.REACT_APP_API_KEY,
    googleClientId:process.env.REACT_APP_0AUTH_CLIENT_ID
}
 export default host

 