import React, { useState } from "react";
import HeaderPage from "../_layout/elements/header";
import FooterPage from "../_layout/elements/footer";
import helpbanner from "../assets/images/helpbanner.png";
import MessageSnackbar from "../_layout/elements/snackbar";
import { TextField, Checkbox } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import host from "../host";
export const ContactUs = () => {
  const navigate = useNavigate();
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [snackbar, setSnackbar] = useState(false);
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [question, setQuestion] = useState("");
  const [agree, setAgree] = useState(false);
  const onClose = () => {
    setSnackbar(false);
  };
  const contactUs = () => {
    if (!subject) {
      setSeverity("error");
      setMessage("Please fill the subject field.");
      setSnackbar(true);
      return;
    }
    if (!email) {
      setSeverity("error");
      setMessage("Please fill the email field.");
      setSnackbar(true);
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setSeverity("error");
      setMessage("Please enter a valid email.");
      setSnackbar(true);
      return;
    }

    if (!question) {
      setSeverity("error");
      setMessage("Please fill the question field.");
      setSnackbar(true);
      return;
    }
    if (!agree) {
      setSeverity("error");
      setMessage("Please agree to the terms and conditions.");
      setSnackbar(true);
      return;
    }
    fetch(`${host.astro_backend}/contact_us?email=${email}&subject=${subject}&question=${question}`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 400) {
          setSeverity("error");
          setMessage("Something went wrong. Please try again later.");
          setSnackbar(true);
        }
        return res.json();
      })
      .then((data) => {
        if (data.success) {
          setSeverity("success");
          setMessage("Your query has been submitted successfully. We will get back to you soon.");
          setSnackbar(true);
          setTimeout(() => {
            navigate("/dashboard");
          }, 3000);
        } else {
          console.log("Data has failed");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <MessageSnackbar open={snackbar} autoHideDuration={3000} onClose={onClose} severity={severity} message={message} />
      <div className="inner-header">
        <HeaderPage />
      </div>
      <section className="  section-pedding   contact-us" id="contactus">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-6">
              <div className="help-image">
                <img src={helpbanner} alt="banner" />
              </div>
            </div>
            <div className="col-md-5">
              <div className="card">
                <div className="contact-form-head">
                  <h4>Do you want to know more or contact our sales department? </h4>
                </div>
                <div className="card-body">
                  <div className="contact-form">
                    <div className="row">
                      <div className="col-xxl-12 col-lg-12 col-md-12">
                        <div className="form-group">
                          <label className="form-label">
                            {" "}
                            Subject <span className="text-danger">*</span>
                          </label>
                          <TextField size="small" type="text" placeholder="Enter the subject" value={subject} onChange={(e) => setSubject(e.target.value)} />
                        </div>
                      </div>

                      <div className="col-xxl-12 col-lg-12 col-md-12">
                        <div className="form-group">
                          <label className="form-label">
                            Your Email <span className="text-danger">*</span>
                          </label>
                          <TextField size="small" type="text" placeholder="Enter mail ID" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                      </div>
                      <div className="col-xxl-12 col-lg-12 col-md-12">
                        <div className="form-group">
                          <label className="form-label">
                            Question <span className="text-danger">*</span>
                          </label>
                          <TextField size="small" type="text" multiline rows={5} value={question} onChange={(e) => setQuestion(e.target.value)} />
                        </div>
                      </div>
                      <div className="col-xxl-12 col-lg-12 col-md-12">
                        <div className="form-group contactagree">
                          <div className="form-check">
                            <Checkbox checked={agree} onChange={(e) => setAgree(e.target.checked)} />
                            <label className="form-check-label" htmlFor="remember-check">
                              I consent to NaviVibe processing my personal data for purposes related to the use of the NaviVibe Website, in accordance with the Privacy Policy.*
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-xxl-12 col-lg-12 col-md-12 mt-3">
                        <div className="form-group">
                          <Link to="">
                            <button className="btn btn-primary w-100  " type="button" onClick={contactUs} disabled={agree === false}>
                              Submit
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterPage />
    </div>
  );
};
