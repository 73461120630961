import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { changeAuth } from "../actions/loginAction";
import { useDispatch } from "react-redux";
import { useTheme } from "@mui/material/styles";
import logo from "../assets/images/logo.png";
import { TextField, FormControl, MobileStepper, CircularProgress } from "@mui/material";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import MaleIcon from "@mui/icons-material/Male";
import FemaleIcon from "@mui/icons-material/Female";
import TransgenderIcon from "@mui/icons-material/Transgender";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import customMarkerIcon from "../assets/images/location.png";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import tzlookup from "tz-lookup";
import moment from "moment-timezone";
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
import MessageSnackbar from "../_layout/elements/snackbar";
import host from "../host";
export const GmailSignup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [redirectUrl, setRedirectUrl] = useState(null);

  const [snackbar, setSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const onClose = () => {
    setSnackbar(false);
  };

  const [loading, setLoading] = useState(false);
  const [fullname, setFullname] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [countryCode, setCountryCode] = useState("IN");
  const [gender, setGender] = useState("");
  const [birthDay, setBirthDay] = useState(null);
  const [birthYear, setBirthYear] = useState("");
  const [birthMonth, setBirthMonth] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [birthHour, setBirthHour] = useState("");
  const [birthMinute, setBirthMinute] = useState("");
  const [birthSecond, setBirthSecond] = useState("");
  const inputRef = useRef(null);
  const [birthPlace, setBirthPlace] = useState("");
  const [latLng, setLatLng] = useState({ lat: null, lng: null }); // Default lat/lng
  const [offsetTimezone, setOffsetTimezone] = useState(null);
  const [value, setValue] = useState();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    if (activeStep === 4) {
      // Call saveProfileDetails when activeStep is 4
      setLoading(true);
      saveProfileDetails();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const customIcon = L.icon({
    iconUrl: customMarkerIcon,
    iconSize: [32, 32], // Width and height of the icon
    iconAnchor: [16, 32], // Position where the icon's tip is anchored
  });

  const handleInputChange = (e) => {
    setBirthPlace(e.target.value);
  };

  const handlePlaceChanged = () => {
    const places = inputRef.current.getPlaces();
    if (places && places.length > 0) {
      const place = places[0];
      setLatLng({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
      let timezone = tzlookup(place.geometry.location.lat(), place.geometry.location.lng());
      let offset = moment.tz(timezone).utcOffset() / 60;
      setOffsetTimezone(offset);
      setBirthPlace(place.formatted_address);
    }
  };
  const isValidPhoneNumber = (phoneNumber) => {
    if (phoneNumber === "") {
    } else if (phoneNumber?.length !== 13 && countryCode === "IN") {
      return false;
    } else if (phoneNumber?.length < 7 || phoneNumber?.length > 16) {
      return false;
    } else {
      return true;
    }
  };

  function formatDate(date) {
    const birth_Day = new Date(date);
    let hours = birth_Day.getHours();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const minutes = birth_Day.getMinutes() < 10 ? "0" + birth_Day.getMinutes() : birth_Day.getMinutes();
    const formattedDate = `${birth_Day.getDate()}/${birth_Day.getMonth() + 1}/${birth_Day.getFullYear()} ${hours}:${minutes} ${ampm}`;
    return formattedDate;
  }

  const saveProfileDetails = () => {
    setLoading(true);
    if (gender === "") {
      setSeverity("error");
      setMessage("Please choose gender");
      setSnackbar(true);
      return;
    }
    if (birthDay === "") {
      setSeverity("error");
      setMessage("Please choose birth date");
      setSnackbar(true);
      return;
    }
    if (birthPlace === "") {
      setSeverity("error");
      setMessage("Please choose birth place");
      setSnackbar(true);
      return;
    }
    if (!isValidPhoneNumber(phoneNumber)) {
      setSeverity("error");
      setMessage("Please enter valid phone number");
      setSnackbar(true);
      return;
    }
    // const birthYear = birthDay.year();
    // const birthMonth = birthDay.month() + 1; // month is 0-indexed
    // const birthDate = birthDay.date();
    // const birthHour = birthDay.hour();
    // const birthMinute = birthDay.minute();
    // const birthSecond = birthDay.second();
    fetch(host.astro_backend + "/user/google/save", {
      method: "POST",
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.getItem("token")}` },
      body: JSON.stringify({
        fullname: fullname,
        phone_number: phoneNumber,
        gender: gender,
        birth_year: birthYear,
        birth_month: birthMonth,
        birth_day: birthDate,
        birth_hour: birthHour,
        birth_minute: birthMinute,
        birth_second: birthSecond,
        birth_place: birthPlace,
        lat: latLng.lat,
        long: latLng.lng,
        timezone: offsetTimezone,
      }),
    })
      .then(async (res) => {
        // Use async to allow await inside
        if (res.status === 400 || res.status === 403) {
          const data = await res.json(); // Wait for the promise to resolve
          setSeverity("error");
          setLoading(false);
          // For status 400, check data.detail; for 403, set a specific message
          if (res.status === 400) {
            setMessage(data.detail ? data.detail : "Something went wrong. Please try again later.");
            setLoading(false);
          } else if (res.status === 403) {
            setMessage("Not authorized to access this page. Please login first.");
            setLoading(false);
          }
          setSnackbar(true);
        }
        // Only attempt to parse JSON if not a 400 or 403 error to avoid double parsing
        else {
          return res.json();
        }
      })
      .then(async (data) => {
        if (data.is_profile_complete) {
          navigate("/loader-page");
          setLoading(false);
          setSeverity("success");
          setMessage("Account created successfully");
          setSnackbar(true);
          dispatch(changeAuth(true, localStorage.getItem("token")));
          setTimeout(() => {
            if (redirectUrl) {
              navigate(redirectUrl);
            } else {
              navigate("/dashboard");
            }
          }, 2000);
        } else {
          setLoading(false);
          setSeverity("error");
          setMessage("Something went wrong. Please try again.");
          setSnackbar(true);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    document.documentElement.setAttribute("data-theme", "dark");
    setFullname(localStorage.getItem("fullname") ? localStorage.getItem("fullname") : "");
  }, []);

  return (
    <div>
      <MessageSnackbar open={snackbar} autoHideDuration={3000} onClose={onClose} severity={severity} message={message} />
      <section className="g_signup">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="g_signup_wrapper">
                <div className="g_signup_head">
                  <div className="g_signup_logo">
                    <Link to={"/login"}>
                      <img src={logo} height={40} />
                    </Link>
                  </div>
                  <div className="stepper_bar ">
                    <div className="stepbar_title">
                      <h5>Unlock the secrets of your destiny</h5>
                      <div className="stepbar_count">
                        <MobileStepper variant="text" steps={5} position="static" activeStep={activeStep} />
                      </div>
                    </div>
                    <MobileStepper
                      variant="progress"
                      steps={5}
                      position="static"
                      activeStep={activeStep}
                      sx={{ width: "100%", maxWidth: "100%", flexGrow: 1, justifyContent: "center", margin: "auto" }}
                    />
                  </div>
                </div>
                <div className="g_signup_body  ">
                  {activeStep === 0 && (
                    <div className="g_signup_step_rows  ">
                      <div className="g_signup_step_head">
                        <h3>Choose Gender</h3>
                      </div>
                      <div className="g_signup_step_body">
                        <div className="gender_box">
                          <div className="gender_box_row">
                            <Link to="#" className={`gender_box_cta ${gender === "Male" ? "active" : ""}`} onClick={() => setGender("Male")}>
                              <div className="gender_icon">
                                <MaleIcon />
                              </div>
                              <div className="gender_name">Male</div>
                            </Link>
                            <Link to="#" className={`gender_box_cta ${gender === "Female" ? "active" : ""}`} onClick={() => setGender("Female")}>
                              <div className="gender_icon">
                                <FemaleIcon />
                              </div>
                              <div className="gender_name">Female</div>
                            </Link>
                            <Link to="#" className={`gender_box_cta ${gender === "Others" ? "active" : ""}`} onClick={() => setGender("Others")}>
                              <div className="gender_icon">
                                <TransgenderIcon />
                              </div>
                              <div className="gender_name">Others</div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {activeStep === 1 && (
                    <div className="g_signup_step_rows">
                      <div className="g_signup_step_head">
                        <h3>What’s your date of birth & time?</h3>
                      </div>
                      <div className="g_signup_step_body">
                        <div className="date_box d-flex justify-content-center">
                          <div className="col-xxl-7 col-lg-7 col-md-7 col-12">
                            <div className="form-group datetimepicker">
                              <FormControl fullWidth className="text-center">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <DateTimePicker
                                    name="birthDay"
                                    value={birthDay}
                                    onChange={(value) => {
                                      setBirthDay(value);
                                      setBirthYear(value ? value.year().toString() : "");
                                      setBirthMonth(value ? (value.month() + 1).toString() : ""); // month() returns 0-11, adding 1 for 1-12
                                      setBirthDate(value ? value.date().toString() : "");
                                      setBirthHour(value ? value.hour().toString() : "");
                                      setBirthMinute(value ? value.minute().toString() : "");
                                      setBirthSecond(value ? value.second().toString() : "");
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                  />
                                </LocalizationProvider>
                              </FormControl>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {activeStep === 2 && (
                    <div className="g_signup_step_rows   ">
                      <div className="g_signup_step_head">
                        <h3>Where were you born? </h3>
                      </div>
                      <div className="g_signup_step_body">
                        <div className="date_box d-flex justify-content-center">
                          <div className="col-xxl-7 col-lg-7 col-md-7 col-12">
                            <div className="form-group selectcity ">
                              <LoadScript googleMapsApiKey={host.api_key} libraries={["places"]}>
                                <StandaloneSearchBox onLoad={(ref) => (inputRef.current = ref)} onPlacesChanged={handlePlaceChanged}>
                                  <div className="worldwidephone locationinput">
                                    <TextField
                                      id="outlined-basic"
                                      type="text"
                                      // label="Location"
                                      placeholder="Enter Location"
                                      name="pinCode"
                                      variant="outlined"
                                      value={birthPlace}
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                </StandaloneSearchBox>
                              </LoadScript>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {activeStep === 3 && (
                    <div className="g_signup_step_rows    ">
                      <div className="g_signup_step_head">
                        <h3>What is your phone number? </h3>
                      </div>
                      <div className="g_signup_step_body">
                        <div className="date_box d-flex justify-content-center">
                          <div className="col-xxl-7 col-lg-7 col-md-7 col-12">
                            <div className="form-group  ">
                              <div className="form-group">
                                <PhoneInput
                                  international
                                  countryCallingCodeEditable={false}
                                  defaultCountry="IN"
                                  value={phoneNumber}
                                  onChange={setPhoneNumber}
                                  onCountryChange={(e) => {
                                    setCountryCode(e);
                                  }}
                                  className="worldwidephone"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {activeStep === 4 && (
                    <div className="finish-steps" sx={{ mt: 2, mb: 1 }}>
                      <div className="previous_details mb-4">
                        <div className="card">
                          <div className="card-body">
                            <div class="alert alert-primary p-1" style={{ fontSize: "12px", textAlign: "center" }} role="alert">
                              Please review your details and click on ' Submit ' button to proceed
                            </div>
                            <MapContainer center={[latLng.lat, latLng.lng]} zoom={13} style={{ width: "100%", height: "200px" }}>
                              <TileLayer url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png" />
                              <Marker position={[latLng.lat, latLng.lng]} icon={customIcon}>
                                <Popup>Your Location</Popup>
                              </Marker>
                            </MapContainer>

                            <div className="getin-touch">
                              <div className="address">
                                <h5> Name</h5>
                                <span>-</span>
                                <p>
                                  {fullname ? fullname : "NA"}
                                  <br />
                                </p>
                              </div>
                              <div className="address">
                                <h5>Gender</h5>
                                <span>-</span>
                                <p>
                                  {gender ? gender : "NA"}
                                  <br />
                                </p>
                              </div>
                              <div className="address">
                                <h5>Birth Details</h5>
                                <span>-</span>{" "}
                                <p>
                                  {birthDay ? formatDate(birthDay) : "NA"}

                                  <br />
                                </p>
                              </div>
                              <div className="address  ">
                                <h5>Phone</h5>
                                <span>-</span>
                                <div className="contact-link">
                                  <div className="contact-icon">
                                    <PhoneOutlinedIcon fontSize="small" />
                                  </div>
                                  <p>{phoneNumber ? phoneNumber : ""}</p>
                                </div>
                              </div>

                              <div className="address">
                                <h5>Birth Place</h5>
                                <span>-</span>
                                <p>
                                  {birthPlace ? birthPlace : "NA"}
                                  <br />
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="g_signup_step_button">
                    <button className="btn btn-secondry" onClick={handleBack} disabled={loading || activeStep === 0}>
                      {theme.direction === " " ? <KeyboardArrowRight className="m-0" /> : <KeyboardArrowLeft className="m-0" />}
                      Back
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={handleNext}
                      disabled={
                        loading ||
                        activeStep === 5 ||
                        (activeStep === 0 && gender === "") ||
                        (activeStep === 1 &&
                          (birthDay === null || birthDate === null || birthHour === null || birthMinute === null || birthSecond === null || birthMonth === null || birthYear === null)) ||
                        (activeStep === 2 && birthPlace === "") ||
                        (activeStep === 3 && !isValidPhoneNumber(phoneNumber, countryCode))
                      }
                    >
                      {activeStep === 4 ? "Submit" : "Next"}
                      {loading ? (<CircularProgress size={20}/>):""}
                      {theme.direction === " " ? <KeyboardArrowLeft className="m-0" /> : <KeyboardArrowRight className="m-0" />}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
