import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { TextField, InputAdornment, IconButton ,Tooltip,Zoom} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LoginSidebar from "../components/login-slider";
import MessageSnackbar from "../_layout/elements/snackbar";
import logo from "../assets/images/logo.png";

import host from "../host";
import ContentLoader from "../components/loader/content-loader";
export const ForgotPassword = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showVerificationCodeForm, setShowVerificationCodeForm] = useState(false);

  const [snackbar, setSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");

  const onClose = () => {
    setSnackbar(false);
  };

  function validateEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  const handleVerificationCodeChange = (e) => {
    const input = e.target.value;
    if (/^\d+$/.test(input) || input === "") {
      setVerificationCode(input);
    } 
  };
  const handleSendEmail = async () => {
    if (validateEmail(email) === false) {
      setSeverity("error");
      setMessage("Please enter the email address");
      setSnackbar(true);
    } else {
      setLoading(true);
      fetch(host.astro_backend + "/user/forgot_mail", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          email: email,
        }),
      })
        .then((res) => {
          setLoading(false);

          if (res.status === 401) {
          }
          return res.json();
        })
        .then((data) => {
          if (data.success) {
            setLoading(false);
            setShowVerificationCodeForm(true);
          } else {
            setSeverity("error");
            setMessage(data.message);
            setSnackbar(true);
          }
        })
        .catch((_err) => {
          setLoading(false);
        });
    }
  };

  const handleSubmitNewPassword = async () => {
    if (verificationCode.length !== 6) {
      setMessage("Provide valid otp");
    } else if (newPassword.length < 8) {
      setMessage("Password is too short");
    } else if (newPassword !== confirmPassword) {
      setMessage("Passwords don't match");
    } else {
      setLoading(true);
      fetch(host.astro_backend + "/user/forgot_password", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          email,
          otp: parseInt(verificationCode),
          password: newPassword,
        }),
      })
        .then((res) => {
          setLoading(false);

          if (res.status === 401) {
          }
          return res.json();
        })
        .then((data) => {
          setLoading(false);
          if (data.success) {
            navigate("/login");
          } else {
            setSeverity("error");
            setMessage(data.message);

            setSnackbar(true);
          }
        })
        .catch((_err) => {
          setLoading(false);
        });
    }
  };
  return (
    <div className="bglogin">
      <MessageSnackbar open={snackbar} autoHideDuration={3000} onClose={onClose} severity={severity} message={message} />
      <div className="login_wrapper">
        <div className="login_flex">
          <div className="login_left">
            <div className="login_left_content">
              <LoginSidebar />
            </div>
          </div>
          <div className="login_right">
            <div className="login_right_content">
              <div className="auth-full-page-content justify-content-center ">
                <div className="form-width login-width flex-column ">
                  <div className="d-flex flex-column h-100 ">
                    <div className="auth-content my-auto logincorporate  ">
                    <div className="login_logo text-center mb-2">
                        <Link to={"/"}>
                          <img className=" " src={logo} height={50} />
                        </Link>
                      </div>
                      {loading ? (
                        <ContentLoader />
                      ) : (
                        <>
                          {!showVerificationCodeForm ? (
                            <form className=" mt-2" >
                              <div className="welcometext text-center">
                                <h5 className="mb-0">Forgot Password </h5>

                                <p className="text-muted mt-2">Enter your email and password. Reset instructions will be sent to you </p>
                              </div>


                              <div className="form-group">
                                <label className="form-label"> Email</label>
                                <TextField type="text" placeholder="Enter business email" value={email} onChange={(e) => setEmail(e.target.value)} />
                              </div>

                              <div className="mb-3">
                                <button className="btn btn-primary w-100" type="button" onClick={handleSendEmail}>
                                  Send verification code
                                </button>
                              </div>
                            </form>
                          ) : (
                            <form className="  ">
                              <div className="welcometext text-center mb-2">
                                <h5 className="mb-0">Enter Verification Code </h5>
                                <p className="text-muted mt-2">Please enter the verification code sent to {email}</p>
                              </div>
                              <div className="form-group">
                                <label className="form-label"> Verification code</label>
                                <TextField type="text" placeholder="Enter verification code" value={verificationCode} onChange={handleVerificationCodeChange} />
                              </div>
                              <div className="form-group">
                                <label className="form-label"> Enter New Password</label>
                                <TextField
                                  type={showPassword ? "text" : "password"}
                                  placeholder="Enter new password"
                                  value={newPassword}
                                  onChange={(e) => setNewPassword(e.target.value)}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton onClick={() => setShowPassword(!showPassword)}>{showPassword ? <Visibility /> : <VisibilityOff />}</IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </div>
                              <div className="form-group">
                                <label className="form-label">Confirm Password</label>
                                <TextField
                                  type={showConfirmPassword ? "text" : "password"}
                                  placeholder="Confirm password"
                                  value={confirmPassword}
                                  onChange={(e) => setConfirmPassword(e.target.value)}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)}>{showConfirmPassword ? <Visibility /> : <VisibilityOff />}</IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </div>
                              {!(newPassword === "" && confirmPassword === "") && newPassword !== confirmPassword && (
                                <span className="error text-danger mt-2" id="confirm_password_error">
                                  {" "}
                                  Passwords do not match.
                                </span>
                              )}
                              <div className="mb-3">
                                <button className="btn btn-primary w-100" type="button" disabled={!(newPassword === confirmPassword)} onClick={handleSubmitNewPassword}>
                                  Submit
                                </button>
                              </div>
                            </form>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
