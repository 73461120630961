import React, {  } from 'react';
 
import pageloader from "../assets/images/ai.gif";
 
 
 
export default function PageLoader
() { 


  
  return ( 
   <> 
     <div className="page-loader" style={{display:'none'}}  >
                  <div className="inner-loader">
                  <img src={pageloader} style={{width:'100px'}} alt=''/>
                  <span>Loading... Won't be a minute</span>
                  </div>
               </div>
   </>


  )



}