import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { changeAuth } from "../actions/loginAction";
import { useDispatch } from "react-redux";
import { TextField, InputAdornment, IconButton, OutlinedInput, FormControl, Select, MenuItem, Tooltip, Zoom ,CircularProgress} from "@mui/material";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useFormik } from "formik";
import host from "../host";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import MessageSnackbar from "../_layout/elements/snackbar";
import LoginSidebar from "../components/login-slider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { useGoogleLogin } from "@react-oauth/google";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import customMarkerIcon from "../assets/images/location.png";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import tzlookup from "tz-lookup";
import moment from "moment-timezone";
import logo from "../assets/images/logo.png";
import googleicon from "../assets/images/google-icon.svg";
export const Signup = () => {
  const dispatch = useDispatch();
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [snackbar, setSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const onClose = () => {
    setSnackbar(false);
  };

  useEffect(() => {
    document.documentElement.setAttribute("data-theme", "dark");
    const urlParams = new URLSearchParams(window.location.search);
    const topic = urlParams.get("topic");
    const redirect = urlParams.get("redirectUrl");
    localStorage.setItem("topic", topic);
    if (redirect) {
      setRedirectUrl(redirect);
    }
  }, []);

  function formatDate(date) {
    const birth_Day = new Date(date);
    let hours = birth_Day.getHours();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const minutes = birth_Day.getMinutes() < 10 ? "0" + birth_Day.getMinutes() : birth_Day.getMinutes();
    const formattedDate = `${birth_Day.getDate()}/${birth_Day.getMonth() + 1}/${birth_Day.getFullYear()} ${hours}:${minutes} ${ampm}`;
    return formattedDate;
  }

  const getPasswordValidationFeedback = (password) => {
    const feedback = [];
    if (password.length < 8) {
      feedback.push("at least 8 characters");
    }
    if (!/\d/.test(password)) {
      feedback.push("a number");
    }
    if (!/[A-Z]/.test(password)) {
      feedback.push("an uppercase letter");
    }
    if (!/[\W_]+/.test(password)) {
      feedback.push("a symbol");
    }

    return feedback.length > 0 ? `Password must include ${feedback.join(", ")}` : "";
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("First name is required"),
    email: Yup.string().email("Invalid email address").required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .matches(/\d/, "Password must contain at least one number")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/[\W_]+/, "Password must contain at least one symbol"),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
    gender: Yup.string().required("Gender is required").notOneOf(["Select"], "Please select a gender"),
    birthDay: Yup.date().required("Birth date is required"),
    // max(new Date(), "Birth date cannot be in the future"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      gender: "Select",
      birthDay: null,
    },
    validationSchema: validationSchema,
  });

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [googleLoading, setGoogleLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState("");
  const [latLng, setLatLng] = useState({ lat: null, lng: null }); // Default lat/lng
  const [offsetTimezone, setOffsetTimezone] = useState(null);

  const [phoneError, setPhoneError] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [countryCode, setCountryCode] = useState("IN");
  const [otp, setOtp] = useState("");

  const [otpCount, setOtpCount] = useState(3);
  const [otpSent, setOtpSent] = useState(false);

  const signup = (values) => {
    if (!latLng.lat || !latLng.lng) {
      setSeverity("error");
      setMessage("Please select a valid location");
      setSnackbar(true);

      return;
    }
    if (phoneNo === "") {
      setPhoneError("Please provide valid phone");
      return;
    } else if (phoneNo.length !== 13 && countryCode === "IN") {
      setPhoneError("Please provide valid phone");
      return;
    } else if (phoneNo.length < 7 || phoneNo.length > 16) {
      setPhoneError("Please provide valid phone");
      return;
    } else {
      setPhoneError("");
      values.phone = phoneNo.replace("+", "");
    }
    setLoading(true);
    const birth_Day = new Date(formik.values.birthDay);
    fetch(host.astro_backend + "/user/register", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        fullname: formik.values.name,
        email: formik.values.email,
        phone_number: phoneNo,
        password: formik.values.password,
        gender: formik.values.gender,
        birth_year: birth_Day.getFullYear(),
        birth_month: birth_Day.getMonth() + 1,
        birth_day: birth_Day.getDate(),
        birth_hour: birth_Day.getHours(),
        birth_minute: birth_Day.getMinutes(),
        birth_second: 0,
        birth_place: inputValue,
        lat: latLng.lat,
        long: latLng.lng,
        timezone: offsetTimezone,
        city: inputValue,
      }),
    })
      .then(async (res) => {
        const data = await res.json();
        setLoading(false);
        if (!res.ok) {
          setSeverity("error");
          setOtpSent(false);
          if (res.status === 400) {
            setMessage(data.detail);
          } else {
            setMessage(data.message);
          }
          setSnackbar(true);
        } else {
          setLoading(false);
          setSeverity("success");
          setOtpSent(true);
          setMessage(otpCount === 3 ? "OTP sent successfully!" : "OTP resent successfully!");
          setOtpCount(otpCount - 1);
          setTimeLeft(20);

          const timerInterval = setInterval(() => {
            setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
          }, 1000);

          setTimeout(() => {
            clearInterval(timerInterval);
            setTimeLeft(0);
          }, 20000);
          setSnackbar(true);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        setSeverity("error");
        setMessage("Something Went Wrong !!");
        setSnackbar(true);
      });
  };

  const verifyOtp = () => {
    if (!otp || otp.length !== 6) {
      setMessage("Enter a valid OTP");
      setSeverity("error");
      setLoading(false);
      setSnackbar(true);
    } else {
      setLoading(true);
      setMessage("");
      fetch(host.astro_backend + "/user/verify_email", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: formik.values.email,
          otp: otp,
        }),
      })
        .then(async (res) => {
          const data = await res.json();
          setLoading(false);
          if (res.status === 400) {
            setSeverity("error");
            setMessage(data.detail);
            setSnackbar(true);
          } else if (data.success) {
            setSeverity("success");
            setMessage("Account created successfully");
            setSnackbar(true);
            setTimeout(() => {
              dispatch(changeAuth(true, data.token));
              setLoading(false);
              if (
                localStorage.getItem("topic") === "none" ||
                localStorage.getItem("topic") === "Family" ||
                localStorage.getItem("topic") === "Love" ||
                localStorage.getItem("topic") === "Career" ||
                localStorage.getItem("topic") === "Business" ||
                localStorage.getItem("topic") === "Finance" ||
                localStorage.getItem("topic") === "Marriage"
              ) {
                navigate("/new-astro-chatbot");
              } else if (redirectUrl) {
                navigate(redirectUrl);
              } else {
                navigate("/dashboard");
              }
            }, 1000);
          } else {
            setSeverity("error");
            setMessage(data.message || "Something Went Wrong !!");
            setSnackbar(true);
          }
        })
        .catch((_err) => {
          setLoading(false);
        });
    }
  };

  //google login starts
  const googleLogin = (tokenResponse) => {
    setMessage("");
    setGoogleLoading(true);
    fetch(host.astro_backend + "/user/google", {
      method: "POST",
      body: JSON.stringify({ credential: tokenResponse }),
      headers: {
        "Content-type": "application/json",
      },
    })
      .then(async (res) => {
        if (res.status === 401) {
          return res.json().then((data) => {
            throw new Error(data.message);
          });
        } else if (res.status === 403) {
          return res.json().then((data) => {
            setGoogleLoading(false);
            setSeverity("error");
            setMessage(data.detail ? data.detail : "Something went wrong");
            setSnackbar(true);
          });
        } else if (res.status === 400) {
          return res.json().then((data) => {
            setGoogleLoading(false);
            setSeverity("error");
            setMessage(data.detail ? data.detail : "Something went wrong");
            setSnackbar(true);
          });
        } else if (res.status === 500) {
          return res.json().then((data) => {
            setGoogleLoading(false);
            setSeverity("error");
            setMessage(data.detail ? data.detail : "Something went wrong");
            setSnackbar(true);
          });
        } else {
          return res.json();
        }
      })
      .then(async (data) => {
        setGoogleLoading(false);
        if (data.success) {
          setGoogleLoading(false);
          if (data.user?.is_profile_complete === true) {
            dispatch(changeAuth(true, data.token));
            if (redirectUrl) {
              navigate(redirectUrl);
            } else {
              navigate("/dashboard");
            }
          } else if (data.user?.is_profile_complete === false) {
            setGoogleLoading(false);
            dispatch(changeAuth(false, data.token));
            localStorage.setItem("fullname", data.user?.fullname);
            navigate("/googlelogin");
          } else {
            setMessage("something went wrong");
          }
        } else {
          setGoogleLoading(false);
          setMessage(data.message);
        }
      })
      .catch((err) => {
        setGoogleLoading(false);
        console.log(err);
      });
  };

  const loginGoogle = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      //console.log(tokenResponse)
      googleLogin(tokenResponse);
    },
    // scope: 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/user.gender.read https://www.googleapis.com/auth/user.birthday.read',
  });
  //google login ends

  const handlePlaceChanged = () => {
    const places = inputRef.current.getPlaces();
    if (places && places.length > 0) {
      const place = places[0];
      setLatLng({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
      let timezone = tzlookup(place.geometry.location.lat(), place.geometry.location.lng());
      let offset = moment.tz(timezone).utcOffset() / 60;
      setOffsetTimezone(offset);
      setInputValue(place.formatted_address);
    }
  };

  const customIcon = L.icon({
    iconUrl: customMarkerIcon,
    iconSize: [32, 32], // Width and height of the icon
    iconAnchor: [16, 32], // Position where the icon's tip is anchored
  });

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  return (
    <div className="bglogin">
      <MessageSnackbar open={snackbar} autoHideDuration={3000} onClose={onClose} severity={severity} message={message} />
      <div className="login_wrapper">
        <div className="login_flex">
          <div className="login_left">
            <div className="login_left_content">
              <LoginSidebar />
            </div>
          </div>
          <div className="login_right">
            <div className="login_right_content">
              <div className="auth-full-page-content  ">
                <div className="form-width login-width ">
                  <div className="d-flex  h-100 ">
                    <div className="auth-content my-auto logincorporate my-auto">
                      <div className="login_logo text-center mb-2">
                        <Link to={"/"}>
                          <img className=" " src={logo} height={50} alt="logo" />
                        </Link>
                      </div>
                      <div className="welcometext  " style={{ display: otpSent ? "none" : "block" }}>
                        <h5 className="mb-0">Sign Up </h5>
                        <p className="text-muted mt-2 "> To Unlock Your Personallization</p>
                      </div>
                      <div className="mt-4 mb-2">
                        <Link
                          to="#"
                          className="  btn-google w-100"
                          onClick={(e) => {
                            e.preventDefault();
                            loginGoogle();
                          }}
                          loading={loading}
                        >
                          <span className="">
                            {" "}
                            <img src={googleicon} alt="" />{" "}
                          </span>{" "}
                          Continue with Google{"   "}
                          {googleLoading? (<CircularProgress size={30}/>):""}
                        </Link>
                      </div>
                      <div className="d-flex align-items-center mt-3  ">
                        <hr className="flex-grow-1 bg-dark-4" />
                        <span className="mx-2 text-2 text-muted">or connect with </span>
                        <hr className="flex-grow-1 bg-dark-4" />
                      </div>
                      <form className=" mt-2" action="index.html" style={{ display: otpSent ? "none" : "block" }}>
                        <div className="row mt-5">
                          <div className="col-xxl-12 col-lg-12 col-md-12 col-12">
                            <div className="form-group">
                              <label className="form-label">
                                Name <span className="text-danger">*</span>
                              </label>
                              <TextField
                                type="text"
                                placeholder="Enter your full name  "
                                name="name"
                                disabled={loading}
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                              />
                            </div>
                          </div>
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-12">
                            <div className="form-group">
                              <label className="form-label">
                                Email <span className="text-danger">*</span>
                              </label>
                              <TextField
                                type="text"
                                placeholder="Enter mail ID"
                                name="email"
                                disabled={loading}
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                              />
                            </div>
                          </div>
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-12">
                            <div className="form-group">
                              <label className="form-label">
                                {" "}
                                Phone Number <span className="text-danger">*</span>
                              </label>
                              <PhoneInput
                                className="worldwidephone"
                                international
                                countryCallingCodeEditable={false}
                                value={phoneNo}
                                disabled={loading}
                                onChange={(e) => {
                                  setPhoneNo(e ? e : "");
                                }}
                                defaultCountry="IN"
                                onCountryChange={(e) => {
                                  setCountryCode(e);
                                }}
                              />
                              {phoneError && phoneError.length > 0 && (
                                <span
                                  style={{
                                    color: "#d32f2f",
                                  }}
                                >
                                  {phoneError}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-12">
                            <div className="form-group">
                              <label className="form-label">
                                {" "}
                                Password <span className="text-danger">*</span>
                              </label>

                              <div className=" auth-pass-inputgroup">
                                <FormControl fullwidth variant="standard">
                                  <OutlinedInput
                                    placeholder="Enter password"
                                    name="password"
                                    type={showPassword ? "text" : "password"}
                                    disabled={loading}
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                    helperText={formik.touched.password && formik.errors.password}
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                                          {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                      </InputAdornment>
                                    }
                                  />
                                </FormControl>
                              </div>
                            </div>
                          </div>
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-12">
                            <div className="form-group">
                              <label className="form-label">
                                Confirm Password <span className="text-danger">*</span>
                              </label>
                              <div className=" auth-pass-inputgroup">
                                <FormControl fullwidth variant="standard">
                                  <OutlinedInput
                                    placeholder="Confirm password"
                                    name="confirmPassword"
                                    type={showConfirmPassword ? "text" : "password"}
                                    disabled={loading}
                                    value={formik.values.confirmPassword}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                                    helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <IconButton aria-label="toggle password visibility" onClick={handleClickShowConfirmPassword} onMouseDown={handleMouseDownConfirmPassword}>
                                          {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                      </InputAdornment>
                                    }
                                  />
                                </FormControl>
                              </div>
                            </div>
                          </div>
                          {!(formik.values.password === "" && formik.values.confirmPassword === "") && (
                            <div className="col-xxl-12 col-lg-12 col-md-12">
                              <div className="form-group">
                                {getPasswordValidationFeedback(formik.values.password) ? (
                                  <span className="error text-danger" id="password_conditions">
                                    {getPasswordValidationFeedback(formik.values.password)}
                                  </span>
                                ) : formik.values.password !== formik.values.confirmPassword ? (
                                  <span className="error text-danger" id="confirm_password_error">
                                    Passwords do not match
                                  </span>
                                ) : (
                                  <span className="error text-success" id="password_conditions">
                                    Passwords match
                                  </span>
                                )}
                              </div>
                            </div>
                          )}
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-12">
                            <div className="form-group  ">
                              <label className="form-label">
                                Select Gender <span className="text-danger">*</span>
                              </label>
                              <FormControl fullWidth>
                                <Select
                                  labelId="demo-select-small-label"
                                  defaultValue={Select}
                                  name="gender"
                                  placeholder="Select request"
                                  value={formik.values.gender}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                >
                                  <MenuItem value="Select">Select-- </MenuItem>
                                  <MenuItem value="Male">Male </MenuItem>
                                  <MenuItem value="Female">Female</MenuItem>
                                  <MenuItem value="Other">Other</MenuItem>
                                </Select>
                              </FormControl>
                            </div>
                          </div>
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-12">
                            <div className="form-group datetimepicker">
                              <label className="form-label">
                                Birth Details <span className="text-danger">*</span>
                              </label>
                              <FormControl fullWidth>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <DemoContainer components={["DateTimePicker", "MobileDateTimePicker", "DesktopDateTimePicker", "StaticDateTimePicker"]}>
                                    <DateTimePicker
                                      name="birthDay"
                                      value={formik.values.birthDay}
                                      onChange={(value) => formik.setFieldValue("birthDay", value)}
                                      renderInput={(params) => (
                                        <TextField {...params} error={formik.touched.birthDay && Boolean(formik.errors.birthDay)} helperText={formik.touched.birthDay && formik.errors.birthDay} />
                                      )}
                                    />
                                  </DemoContainer>
                                </LocalizationProvider>
                              </FormControl>
                            </div>
                          </div>
                          <div className="col-xxl-12 col-lg-12 col-md-12 col-12">
                            <div className="form-group">
                              <label className="form-label">
                                Location <span className="text-danger">*</span>
                              </label>

                              <LoadScript googleMapsApiKey={host.api_key} libraries={["places"]}>
                                <StandaloneSearchBox onLoad={(ref) => (inputRef.current = ref)} onPlacesChanged={handlePlaceChanged}>
                                  <div className="worldwidephone locationinput">
                                    <TextField
                                      id="outlined-basic"
                                      type="text"
                                      // label="Location"
                                      placeholder="Enter Location"
                                      name="pinCode"
                                      variant="outlined"
                                      value={inputValue}
                                      onChange={handleInputChange}
                                    />
                                    {/* <input size="small" type="text" placeholder="Enter location" className="PhoneInputInput searchBoxInput" aria-label="Location" /> */}
                                  </div>
                                </StandaloneSearchBox>
                              </LoadScript>
                            </div>
                          </div>
                          <div className="col-xxl-12 col-lg-12 col-md-12 mt-3">
                            <div className="form-group  ">
                              <LoadingButton
                                style={{ textTransform: "capitalize" }}
                                disabled={
                                  !formik.isValid ||
                                  formik.values.password === "" ||
                                  formik.values.confirmPassword === "" ||
                                  formik.values.name === "" ||
                                  phoneNo === "" ||
                                  inputValue === "" ||
                                  !latLng.lat ||
                                  !latLng.lng ||
                                  offsetTimezone === null
                                }
                                type="button"
                                variant="contained"
                                className="  w-100  "
                                onClick={(e) => {
                                  e.preventDefault();
                                  signup(formik.values);
                                }}
                                controlled={true}
                                loading={loading}
                              >
                                {otpSent ? "Submit" : "Next"}
                              </LoadingButton>
                            </div>
                          </div>
                        </div>
                      </form>
                      {otpSent && (
                        <form className=" mt-2  " action="index.html" style={{ display: "block" }}>
                          <div className="welcometext  ">
                            <h5 className="mb-0">OTP Verification </h5>
                            <p className="text-muted mt-2 ">OTP has been sent to your mail ID : {formik.values.email}</p>
                          </div>
                          <div className="row mt-2">
                            <div className="col-xxl-12 col-lg-12 col-md-12 mt-3">
                              <div className="form-group">
                                <div className="previous_details mb-4">
                                  <div className="card">
                                    <div className="card-body">
                                      <div class="alert alert-primary p-1" style={{ fontSize: "12px", textAlign: "center" }} role="alert">
                                        Please confirm all the details and enter your OTP
                                      </div>
                                      <MapContainer center={[latLng.lat, latLng.lng]} zoom={13} style={{ width: "100%", height: "200px" }}>
                                        <TileLayer url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png" />
                                        <Marker position={[latLng.lat, latLng.lng]} icon={customIcon}>
                                          <Popup>Your Location</Popup>
                                        </Marker>
                                      </MapContainer>

                                      <div className="getin-touch">
                                        <div className="address">
                                          <h5> Name</h5>
                                          <span>-</span>
                                          <p>
                                            {formik.values.name}
                                            <br />
                                          </p>
                                        </div>
                                        <div className="address">
                                          <h5> Email</h5>
                                          <span>-</span>
                                          <p>
                                            {formik.values.email}
                                            <br />
                                          </p>
                                        </div>
                                        <div className="address">
                                          <h5>Gender</h5>
                                          <span>-</span>
                                          <p>
                                            {formik.values.gender}
                                            <br />
                                          </p>
                                        </div>
                                        <div className="address">
                                          <h5>Birth Details</h5>
                                          <span>-</span>
                                          <p>
                                            {formatDate(formik.values.birthDay)}
                                            <br />
                                          </p>
                                        </div>
                                        <div className="address  ">
                                          <h5>Phone</h5>
                                          <span>-</span>
                                          <div className="contact-link">
                                            <div className="contact-icon">
                                              <PhoneOutlinedIcon fontSize="small" />
                                            </div>
                                            <p>{phoneNo}</p>
                                          </div>
                                        </div>

                                        <div className="address">
                                          <h5>Birth Place</h5>
                                          <span>-</span>
                                          <p>
                                            {inputValue}
                                            <br />
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-xxl-12 col-lg-12 col-md-12">
                              <div className="form-group">
                                <label className="form-label"> OTP</label>
                                <TextField
                                  type="text"
                                  placeholder="Enter OTP"
                                  value={otp}
                                  onChange={(e) => {
                                    setOtp(e.target.value);
                                  }}
                                />
                                <div className="resentotp mt-2 d-flex justify-content-between">
                                  <span className="text-muted">
                                    {otpCount > 0 ? (
                                      timeLeft > 0 ? (
                                        <small>
                                          Resend OTP in <b>{timeLeft}</b> sec
                                        </small>
                                      ) : (
                                        <Link
                                          to=""
                                          onClick={(e) => {
                                            e.preventDefault();
                                            signup(formik.values);
                                          }}
                                        >
                                          <small>Resend OTP</small>
                                        </Link>
                                      )
                                    ) : null}
                                  </span>
                                  <span className="otpcount">
                                    <small>
                                      {otpCount > 0 ? (
                                        <p className="mb-0">
                                          Resend attempts left: <b> {otpCount}</b>
                                        </p>
                                      ) : (
                                        <p className="text-danger mb-0">Maximum resend attempts reached</p>
                                      )}
                                    </small>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-xxl-12 col-lg-12 col-md-12 mt-3">
                              <div className="form-group">
                                <div className="d-flex justify-content-center gap-2">
                                  <button
                                    type="button"
                                    className="btn btn-secondry w-50"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setOtpSent(false);
                                      setOtpCount(3);
                                      setOtp("");
                                    }}
                                  >
                                    Back
                                  </button>
                                  <LoadingButton
                                    style={{ textTransform: "capitalize" }}
                                    disabled={!formik.isValid || formik.values.password === "" || formik.values.confirmPassword === "" || formik.values.name === "" || phoneNo === ""}
                                    type="button"
                                    variant="contained"
                                    className="  w-50  "
                                    onClick={(e) => {
                                      e.preventDefault();
                                      verifyOtp();
                                    }}
                                    controlled={true}
                                    loading={loading}
                                  >
                                    {otpSent ? "Submit" : "Next"}
                                  </LoadingButton>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      )}
                      <div className="  text-center notmember">
                        <p className="text-muted mb-0">
                          Already have an account ?{" "}
                          <Link to={redirectUrl ? `/login?redirectUrl=${redirectUrl}` : "/login"} className="fw-semibold">
                            {" "}
                            Login{" "}
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
