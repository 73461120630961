import { motion } from "framer-motion"
const MainPage = (props) => {
return (
<>
<div class="layout-wrapper">
<motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.1 }} // Set the transition duration to 0.5 seconds
    > 
   {props.children}
   </motion.div>
</div>
</>
)
}
export default MainPage;