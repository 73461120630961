import React, { useState  } from 'react'; 
import HeaderPage from '../_layout/elements/header';
import FooterPage from '../_layout/elements/footer';
import {  Breadcrumbs,Typography,Avatar } from '@mui/material'; 
 
import Assessmentapi from "../assets/images/AssessmentAPI.jpeg";
 
import { Link } from 'react-router-dom';
 
export const Career = () => {
 
return (
<div>
   <div className='inner-header'>
      <HeaderPage />
   </div>
   {/* <section className='padding_medium  minibanner  career-banner'  >
      <div className='container'>
         <div className='row align-items-center justify-content-between'>
            <div className='col-md-12'>
               <div className='minibanner-content'>
                  <h2>Career </h2>
                  <div className='blog-breadcrumbs'>
                     <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" href="/home">
                        MUI
                        </Link>
                        <Link
                           underline="hover"
                           color="inherit"
                           href="/material-ui/getting-started/installation/"
                           >
                        Core
                        </Link>
                        <Typography color="text.primary">Breadcrumbs</Typography>
                     </Breadcrumbs>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section> */}
   <section className='padding_medium'  >
      <div className='container'>
         <div className='row '>
         <div className='ai-proctoring  '>
                  <div className='step-heading'>
                     <span class="border-verticle borderbg"></span>
                  
                     <h2 className='text-center mt-3'>Career</h2>
                     <p className='text-center   margbottom-50  paddinglr '>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In viverra quis odio sit amet accumsan. Maecenas at augue ut leo ultricies scelerisque id sit amet ligula. Quisque congue augue eu ante mollis venenatis.</p>
                  </div>
                  <div className='aiproctoring-box'>
                     <div className='aiproctoring-flex'>
                        <div className='proctor-box'>
                           <div className='aiproctoring-cta'>
                              <div className='proctor-icon bg_videoproctor'>
                                 
                              </div>
                              <div className='proctor-content'>
                                 <h4>Success Calendar</h4>
                                 <h5>Check good times for your career</h5>
                                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In viverra quis odio sit amet accumsan. Maecenas at augue ut leo ultricies scelerisque id sit amet ligula. Quisque congue augue eu ante mollis venenatis.</p>
                              </div>
                           </div>
                        </div>
                        <div className='centerbordered'>
                           <span class="border-verticle borderbg"></span>
                           <span className='circle-dot'></span>
                           <span class="border-dashed  "></span>
                        </div>
                        <div className='proctor-box'>
                           <div className='aiproctoring-cta'>
                              <div className='proctor-icon'>
                                
                              </div>
                              <div className='proctor-content'>
                                 <h4>Career Matchmaking</h4>
                                 <h5>Find the astrologically best career for you</h5>
                                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In viverra quis odio sit amet accumsan. Maecenas at augue ut leo ultricies scelerisque id sit amet ligula. Quisque congue augue eu ante mollis venenatis.</p>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className='aiproctoring-flex'>
                        <div className='proctor-box'>
                           <div className='aiproctoring-cta'>
                              <div className='proctor-icon'>
                               
                              </div>
                              <div className='proctor-content'>
                                 <h4>Job application Muhurat</h4>
                                 <h5>Find muhurat for applying for a job</h5>
                                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In viverra quis odio sit amet accumsan. Maecenas at augue ut leo ultricies scelerisque id sit amet ligula. Quisque congue augue eu ante mollis venenatis.</p>
                              </div>
                           </div>
                        </div>
                        <div className='centerbordered'>
                           <span class="border-verticle borderbg"></span>
                           <span className='circle-dot'></span>
                           <span class="border-dashed  "></span>
                        </div>
                        <div className='proctor-box'>
                           <div className='aiproctoring-cta'>
                              <div className='proctor-icon'>
                                 
                              </div>
                              <div className='proctor-content'>
                                 <h4>Business Leader Style Match</h4>
                                 <h5>Check which business leader is like you</h5>
                                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In viverra quis odio sit amet accumsan. Maecenas at augue ut leo ultricies scelerisque id sit amet ligula. Quisque congue augue eu ante mollis venenatis.</p>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className='aiproctoring-flex'>
                        <div className='proctor-box'>
                           <div className='aiproctoring-cta'>
                              <div className='proctor-icon'>
                               
                              </div>
                              <div className='proctor-content'>
                                 <h4>Ask a Question</h4>
                                 <h5>Get personalized career advice from our AI Guru</h5>
                                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In viverra quis odio sit amet accumsan. Maecenas at augue ut leo ultricies scelerisque id sit amet ligula. Quisque congue augue eu ante mollis venenatis.</p>
                              </div>
                           </div>
                        </div>
                        <div className='centerbordered'>
                           <span class="border-verticle borderbg"></span>
                           <span className='circle-dot'></span>
                           <span class="border-dashed  "></span>
                        </div>
                        <div className='proctor-box'>
                           <div className='aiproctoring-cta'>
                              <div className='proctor-icon'>
                               
                              </div>
                              <div className='proctor-content'>
                                 <h4>Personalized Career Horoscope</h4>
                                 <h5>Check daily, weekly, monthly personalized career horoscope</h5>
                                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In viverra quis odio sit amet accumsan. Maecenas at augue ut leo ultricies scelerisque id sit amet ligula. Quisque congue augue eu ante mollis venenatis.</p>
                              </div>
                           </div>
                        </div>
                     </div>
             
                  </div>
               </div>
    
      </div>
</div>
</section>
<FooterPage />
</div>
)
}