import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
 
import logowblue from "../assets/images/logo.png";
 

 
import  {TextField, Select, MenuItem, Radio, RadioGroup ,FormControlLabel,FormControl  } from '@mui/material';
export const ErrorPage500 = () => {
 //matrix function    start// 
useEffect(() => {
   const canvas = window.document.querySelector('canvas')
   let letters = '123456789101234567891012345678910123456789101234567891012345678910123456789101234567891012345678910123456789101234567891012345678910';
   canvas.width = window.innerWidth;
   canvas.height = window.innerHeight;
   const fontSize = 10;
   const columns = canvas.width / fontSize;
   const ctx = canvas.getContext('2d');
   const drops = [];
   // Setting the width and height of the canvas
   // Setting up the letters
   letters = letters.split('');
   // Setting up the columns
   // Setting up the drops
   for (let i = 0; i < columns; i++) {
   drops[i] = 1;
   }
   // Setting up the draw function
   function draw() {
   ctx.fillStyle = 'rgba(255, 255,255, .1)';
   ctx.fillRect(0, 0, canvas.width, canvas.height);
   for (let i = 0; i < drops.length; i++) {
   const text = letters[Math.floor(Math.random() * letters.length)];
   ctx.fillStyle = '#b6b6b6';
   ctx.fillText(text, i * fontSize, drops[i] * fontSize);
   drops[i]++;
   if (drops[i] * fontSize > canvas.height && Math.random() > .95) {
   drops[i] = 0;
   }
   }
   }
   setInterval(draw, 30);
   }, [])
   //matrix function    end// 
return (
<div>
<canvas className='matrixeffect' >
      </canvas>
<section className="errorpage">

<div className="errorlogo">
         <Link to="/">   <img src={logowblue} /> </Link>
         </div>
 
        <div className="errorpage__content">

       
          <div className="errorpage__message message">
            <h1 className="message__title text-blue"> 500</h1>
            <h1 className=' message__title2'>Internal Server Error</h1>
            <p className="message__text">There was an error, please try again later.
            </p>
          </div>

          <div className="errorpagebtn">
          <Link to="/">
            <button type='button' className='btn btn-primary' >Go to homepage</button>
            </Link>
          </div>
     
        </div>
        
      </section>
</div>
)
}