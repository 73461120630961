import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Avatar, Tooltip, Zoom, Menu, MenuItem, Divider, ListItemIcon, IconButton, Switch, FormControlLabel, FormGroup } from "@mui/material";
import logo from "../../assets/images/logo.png";
import MenuIcon from "@mui/icons-material/Menu";
import Signuppopup from "../../components/signup-modal";
import Logout from "@mui/icons-material/Logout";
import CloseIcon from "@mui/icons-material/Close";
import { changeAuth } from "../../actions/loginAction";
import host from "../../host";
export default function HeaderPage() {
  const location = useLocation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = localStorage.getItem("login") === "true";

  useEffect(() => {
    if (!auth) {
      dispatch(changeAuth(false, null));
      navigate("/logout");
    }
  }, [auth]);

  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Empty dependency array ensures that the effect runs only once on mount

  const [isClassAdded, setIsClassAdded] = useState(false);
  const handleButtonClick = () => {
    setIsClassAdded((prevIsClassAdded) => !prevIsClassAdded);
  };

  //this below function for add folder   popup start//
  const [opensignup, setOpensignup] = React.useState(false);
  const activesignup = () => setOpensignup(true);
  const handleClosesignup = () => setOpensignup(false);
  //this below function for add brain   popup end//

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [theme, setTheme] = useState(localStorage.getItem("theme") ? localStorage.getItem("theme") : "dark");

  useEffect(() => {
    document.documentElement.setAttribute("data-theme", theme);
    localStorage.setItem("theme", theme);

    if (theme === "dark") {
      setDarkChecked(true);
      setHcChecked(false);
    } else if (theme === "high-contrast") {
      setDarkChecked(false);
      setHcChecked(true);
    } else {
      setDarkChecked(false);
      setHcChecked(false);
    }
  }, [theme]);

  const [darkChecked, setDarkChecked] = useState(theme === "dark");
  const [hcChecked, setHcChecked] = useState(theme === "high-contrast");

  const handleThemeChange = (e) => {
    if (e.target.checked) {
      setTheme("dark");
    } else {
      setTheme("light");
    }
  };

  const handleLogOut = () => {
    dispatch(changeAuth(false, null));
    localStorage.clear();
    navigate("/logout");
  };

  const [profile, setProfile] = useState(null);

  const getProfile = () => {
    // setIsLoaded(false);
    fetch(host.astro_backend + `/user`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 403) {
          dispatch(changeAuth(false, null));
          navigate("/logout");
        }
      })
      .then((data) => {
        if (data) {
          setProfile(data);
          // setIsLoaded(true);
        }
      })
      .catch((err) => {
        console.log(err);
        // setIsLoaded(true);
      });
  };

  const [plan, setPlan] = useState(null);

  const getUserPlan = () => {
    // setIsLoaded(false);
    fetch(host.astro_backend + `/plans/user/plan`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 403) {
          dispatch(changeAuth(false, null));
          navigate("/login");
        } else {
          // setIsLoaded(true);
          // setSeverity("error");
          // setMessage("Something went wrong");
          // setSnackbar(true);
        }
      })
      .then((data) => {
        if (data) {
          if (data.is_full_life_report) {
            setPlan("Premium Tier");
          } else if (data.is_free_trial) {
            setPlan("Free Trial");
          } else {
            setPlan("Basic Tier");
          }
        }
      })
      .catch((err) => {
        console.log(err);
        // setIsLoaded(true);
      });
  };

  useEffect(() => {
    getProfile();
    getUserPlan();
  }, []);

  return (
    <>
      <header className={isScrolled ? " fixed-header" : " "}>
        <div className="mini-header"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <nav>
                <div className={`nav-flex ${isClassAdded ? "sidebarmobilemenu" : ""}`}>
                  <div className="navbar-brand">
                    <div className="menubar">
                      <Link to="" className="menuopen" onClick={handleButtonClick}>
                        <MenuIcon />
                      </Link>
                      <Link to="#" className="menuclose" onClick={handleButtonClick}>
                        <CloseIcon />
                      </Link>
                    </div>
                    <div className="logo">
                      <Link to="/dashboard">
                        <img src={logo} alt="logo" />
                      </Link>
                    </div>
                  </div>
                  <div className="navbar-menu">
                    {auth && (
                      <div className="mobilemenu">
                        {/* <Link to="" className="close closemenubar" onClick={handleButtonClick}>
                          X
                        </Link> */}

                        <ul>
                          <li className=" ">
                            <Link to="/dashboard" className={location.pathname === "/dashboard" ? "main-menu active" : "main-menu"}>
                              Dashboard
                            </Link>
                          </li>
                          <li className=" ">
                            <Link
                              to="/new-astro-chatbot"
                              className={
                                location.pathname === "/new-astro-chatbot"
                                  ? //   ||
                                    //   location.pathname === `/home/${id}`
                                    "main-menu active"
                                  : "main-menu"
                              }
                              onClick={() => {
                                localStorage.removeItem("chat_id");
                              }}
                            >
                              {" "}
                              Consult Guruji
                            </Link>{" "}
                          </li>

                          <li className=" ">
                            <Link to="/personalized-horoscope" className={location.pathname === "/personalized-horoscope" ? "main-menu active" : "main-menu"}>
                              Personalized Horoscope
                            </Link>
                          </li>
                          <li>
                            <Link to="/life-report" className={location.pathname === "/life-report" ? "main-menu active" : "main-menu"}>
                              Life Report
                            </Link>
                          </li>
                          {/* <li>
                            <Link to="/dashboard" className="main-menu">
                              Calendar
                            </Link>
                          </li>
                          <li>
                            <Link to="/career" className="main-menu">
                              Career
                            </Link>
                          </li>
                          <li>
                            <Link to="/relationships" className="main-menu">
                              Relationships
                            </Link>
                          </li>

                          <li>
                            <Link to="/" className="main-menu">
                              Store
                            </Link>
                          </li> */}
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="navbar-button">
                    {!auth && (
                      <>
                        <button
                          type="button"
                          className="btn btn-primary "
                          onClick={() => {
                            navigate("/login");
                          }}
                        >
                          Login
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondry "
                          onClick={() => {
                            navigate("/signup");
                          }}
                        >
                          Sign Up
                        </button>
                      </>
                    )}
                    {profile && auth && (
                      <div className="user-profile border-right head_right_icon">
                        <div className="user-profile-head">
                          <Tooltip title="Profile" TransitionComponent={Zoom} placement="top" arrow>
                            <IconButton
                              onClick={handleClick}
                              size="small"
                              sx={{ ml: 2 }}
                              aria-controls={open ? "account-menu" : undefined}
                              aria-haspopup="true"
                              aria-expanded={open ? "true" : undefined}
                            >
                              <Avatar
                                sx={{
                                  width: 32,
                                  height: 32,
                                  backgroundColor: "#ff840b",
                                  fontSize: "14px",
                                }}
                              >
                                {profile?.fullname?.split(/\s/).reduce((response, word) => (response += word.slice(0, 1)), "") ?? "NA"}
                              </Avatar>
                            </IconButton>
                            {/* <Link to="">
                              <div className="user-profile-pick">
                                <Avatar>{profile?.fullname?.split(/\s/).reduce((response, word) => (response += word.slice(0, 1)), "") ?? "NA"}</Avatar>
                              </div>
                              <div className="user-profile-content">
                                <span className="user-name">{profile?.fullname ?? "NA"}</span>
                                <span>{profile?.email ?? "NA"}</span>
                              </div>
                            </Link> */}
                          </Tooltip>
                          <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}
                            PaperProps={{
                              elevation: 0,
                              sx: {
                                overflow: "visible",
                                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                mt: 1.5,
                                "& .MuiAvatar-root": {
                                  width: 32,
                                  height: 32,
                                  ml: -0.5,
                                  mr: 1,
                                },
                                "&::before": {
                                  content: '""',
                                  display: "block",
                                  position: "absolute",
                                  top: 0,
                                  right: 14,
                                  width: 10,
                                  height: 10,
                                  bgcolor: "background.paper",
                                  transform: "translateY(-50%) rotate(45deg)",
                                  zIndex: 0,
                                },
                              },
                            }}
                            transformOrigin={{
                              horizontal: "right",
                              vertical: "top",
                            }}
                            anchorOrigin={{
                              horizontal: "right",
                              vertical: "bottom",
                            }}
                          >
                            <MenuItem onClick={handleClose}>
                              <div className="user-profile-content">
                                <span className="user-name">{profile?.fullname ?? "NA"}</span>
                                <span>{profile?.email ?? "NA"}</span>
                                <span>{plan ? plan : "NA"}</span>
                              </div>
                            </MenuItem>

                            <Divider />

                            <MenuItem onClick={handleLogOut}>
                              <ListItemIcon>
                                <Logout fontSize="small" />
                              </ListItemIcon>
                              Logout
                            </MenuItem>
                            <MenuItem>
                              <FormGroup className="theam_toggal">
                                <FormControlLabel control={<Switch size="small" checked={darkChecked} onChange={handleThemeChange} color="primary" />} label="Dark Theme" />
                              </FormGroup>
                            </MenuItem>
                          </Menu>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>

      <Signuppopup opensignup={opensignup} handleClosesignup={handleClosesignup} />
    </>
  );
}
