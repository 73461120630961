import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { changeAuth } from "../actions/loginAction";
import { useDispatch } from "react-redux";
import HeaderPage from "../_layout/elements/header";
import FooterPage from "../_layout/elements/footer";
import Aries from "../assets/images/aries.png";
import taurus from "../assets/images/taurus.png";
import gemini from "../assets/images/gemini.png";
import cancer from "../assets/images/cancer.png";
import leo from "../assets/images/leo.png";
import virgo from "../assets/images/virgo.png";
import libra from "../assets/images/libra.png";
import Scorpio from "../assets/images/Scorpio.png";
import Sagittarius from "../assets/images/sagittarius.png";
import capricorn from "../assets/images/capricorn.png";
import Aquarius from "../assets/images/aquarius.png";
import Pisces from "../assets/images/pisces.png";
import signs from "../assets/images/moonsign.png";
import { TextField, Rating, FormControl } from "@mui/material";
import { motion } from "framer-motion";
import relationshiphome from "../assets/images/relationshiphome.png";
import SuccessCalendar from "../assets/images/calander1.png";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import lifereport from "../assets/images/lifereport2.png";
import consultguruji from "../assets/images/chatavtar.png";
import PlanDuration from "../components/plan_duration";
import MessageSnackbar from "../_layout/elements/snackbar";
import host from "../host";
import PageLoader from "../components/loader/page-loader";
import ZodicSignLoader from "../components/loader/zodicsign_loader";
import TodayMatch from "../components/loader/today-match";
import RatingLoader from "../components/loader/rating";

export const DashboardPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = localStorage.getItem("login") === "true";

  useEffect(() => {
    if (!auth) {
      dispatch(changeAuth(false, null));
      navigate("/logout");
    }
  }, [auth]);

  const [value, setValue] = React.useState(5);

  const [question, setQuestion] = useState("");

  const [severity, setSeverity] = useState("success");
  const [snackbar, setSnackbar] = useState(false);
  const [message, setMessage] = useState("");

  const onClose = () => {
    setSnackbar(false);
  };
  //chat history functionality begins
  const [profile, setProfile] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoaded2, setIsLoaded2] = useState(false);
  const [isLoaded3, setIsLoaded3] = useState(false);
  const [isLoaded4, setIsLoaded4] = useState(false);
  const [isLoaded5, setIsLoaded5] = useState(false);
  const [reportAstroData, setReportAstroData] = useState(null);
  const [signDetails, setSignDetails] = useState(null);
  const [matches, setMatches] = useState(null);
  const [ratings, setRatings] = useState(null);

  const signImages = {
    Aries: Aries,
    Taurus: taurus,
    Gemini: gemini,
    Cancer: cancer,
    Leo: leo,
    Virgo: virgo,
    Libra: libra,
    Scorpio: Scorpio,
    Sagittarius: Sagittarius,
    Capricorn: capricorn,
    Aquarius: Aquarius,
    Pisces: Pisces,
  };

  const getProfile = () => {
    setIsLoaded(false);
    fetch(host.astro_backend + `/user`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 403) {
          dispatch(changeAuth(false, null));
          navigate("/logout");
        } else {
          setIsLoaded(true);
          setSeverity("error");
          setMessage("Something went wrong");
          setSnackbar(true);
        }
      })
      .then((data) => {
        if (data) {
          setProfile(data);
          setIsLoaded(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoaded(true);
      });
  };

  const [isFreeTrial, setIsFreeTrial] = useState(null);
  const [isFullReport, setIsFullReport] = useState(null);
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [questionsAllowed, setQuestionsAllowed] = useState(null);
  const [hasExpired, setHasExpired] = useState(false);

  const getUserPlan = () => {
    setIsLoaded4(false);
    fetch(host.astro_backend + `/plans/user/plan`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 403) {
          dispatch(changeAuth(false, null));
          navigate("/logout");
        } else {
          setIsLoaded4(true);
          setSeverity("error");
          setMessage("Something went wrong");
          setSnackbar(true);
        }
      })
      .then((data) => {
        if (data) {
          if (data.is_free_trial) {
            setIsFreeTrial(true);
          } else {
            setIsFreeTrial(false);
          }
          if (data.is_full_life_report) {
            setIsFullReport(true);
          } else {
            setIsFullReport(false);
          }
          setQuestionsAllowed(data.questions_remaining);

          // Calculate time remaining until expiration
          const currentDate = new Date();
          const expirationDate = new Date(data.expires_at);
          const temp = expirationDate - currentDate;
          setTimeRemaining(temp);
          if (currentDate > expirationDate) {
            setHasExpired(true);
          } else {
            setHasExpired(false);
          }
          setIsLoaded4(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoaded4(true);
      });
  };

  const getReport = () => {
    setIsLoaded5(false);
    fetch(host.astro_backend + `/report`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 403) {
          dispatch(changeAuth(false, null));
          navigate("/logout");
        } else if (res.status === 400) {
          setIsLoaded5(true);
        } else {
          setIsLoaded5(true);
          setSeverity("error");
          setMessage("Something went wrong");
          setSnackbar(true);
        }
      })
      .then((data) => {
        if (data) {
          setReportAstroData(data.vedic_horoscope?.astro_details);
          setSignDetails(data.vedic_horoscope?.planets_position);
          setIsLoaded5(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoaded5(true);
      });
  };

  const getMatches = () => {
    setIsLoaded2(false);
    fetch(host.astro_backend + `/personal_horoscope/matches?period_filter=0`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 403) {
          dispatch(changeAuth(false, null));
          navigate("/logout");
        } else {
          setIsLoaded2(true);
          setSeverity("error");
          setMessage("Something went wrong");
          setSnackbar(true);
        }
      })
      .then((data) => {
        if (data) {
          setMatches(data.matches);
          setIsLoaded2(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoaded2(true);
      });
  };

  const getRatings = () => {
    setIsLoaded3(false);
    fetch(host.astro_backend + `/personal_horoscope/ratings?period_filter=0`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 403) {
          dispatch(changeAuth(false, null));
          navigate("/logout");
        } else {
          setIsLoaded3(true);
          setSeverity("error");
          setMessage("Something went wrong");
          setSnackbar(true);
        }
      })
      .then((data) => {
        if (data) {
          setRatings(data.ratings);
          setIsLoaded3(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoaded3(true);
      });
  };

  useEffect(() => {
    getProfile();
    getReport();
    getMatches();
    getRatings();
    getUserPlan();
  }, []);

  const convertTime = (milliseconds) => {
    const seconds = milliseconds / 1000;
    const days = seconds / (60 * 60 * 24);
    if (days >= 1) {
      return `${Math.floor(days)} day(s)`;
    } else {
      const hours = seconds / (60 * 60);
      return `${Math.floor(hours)} hour(s)`;
    }
  };
  const RemainingTime = convertTime(timeRemaining);
  return (
    <div>
      <MessageSnackbar open={snackbar} autoHideDuration={3000} onClose={onClose} severity={severity} message={message} />

      <HeaderPage />
      {isLoaded && isLoaded4 ? (
        <>
          <section className="dashboard_top">
            <div className="container">
              <div className="row">
                {/* {isFreeTrial !== null && timeRemaining !== null && (isFreeTrial || timeRemaining < ((2.592 * 10) ^ 8)) && (
                  <div className="fix_plan_wrapper">
                    <PlanDuration timeRemaining={timeRemaining} hasExpired={hasExpired} isFreeTrial={isFreeTrial} questionsAllowed={null} isChat={false} />
                  </div>
                )} */}
                <div className="col-md-8">
                  <div className="welcome_title">
                    <h2>
                      Hello, <span className="text-primary">{profile?.fullname}</span>
                    </h2>
                  </div>
                  <div className="dashboard_zudic_detail">
                    {/* <h4>Stars and you today</h4> */}
                    <p>
                      Welcome to NaviVibe, your cosmic companion for life's journey! Our AI-powered astrology platform offers personalized guidance tailored just for you. Chat with our AI Guruji to
                      uncover insights into your unique path. Your privacy is our priority; explore astrology with confidence as we keep your data secure. With NaviVibe, dive into personalized chats,
                      detailed reports, daily horoscopes, and more. It's astrology made modern, accessible, and oh-so captivating. Join us and unlock the secrets of the stars today!
                    </p>
                    <div className="my_horoscope_flex">
                      <div className="my_horoscope_match ">
                        <div className="rating_card">
                          <div className="rating_title">
                            <h3>Rashi and Nakshatra</h3>
                          </div>
                          {isLoaded ? (
                            <div className="all_ratings">
                        <div className="horoscope_match_cta horoscope_cta">
                          <label> Moon sign </label>
                          <div className="tech-icon  ">
                            <img src={signImages[reportAstroData?.moon_sign]} alt="" />
                          </div>
                          <label>{reportAstroData?.moon_sign}</label>
                        </div>
                        <div className="horoscope_match_cta horoscope_cta">
                          <label> Nakshatra </label>
                          <div className="tech-icon  ">
                            <img src={signs} alt="" />
                          </div>
                          <label>{reportAstroData?.naksahtra}</label>
                        </div>
                        <div className="horoscope_match_cta horoscope_cta">
                          <label> Sun sign </label>
                          <div className="tech-icon  ">
                          <img src={signImages[reportAstroData?.sun_sign] || signImages[signDetails?.find(planet => planet.name === "Sun")?.sign]|| null} alt="" />
                          </div>
                          <label>{reportAstroData?.sun_sign || signDetails?.find(planet => planet.name === "Sun")?.sign || "NA" }</label>
                        </div>
                        <div className="horoscope_match_cta horoscope_cta">
                          <label>Ascendant</label>
                          <div className="tech-icon  ">
                            <img src={signImages[reportAstroData?.ascendant]} alt="" />
                          </div>
                          <label>{reportAstroData?.ascendant}</label>
                        </div>
                      </div>
                          ) : (
                            <ZodicSignLoader />
                          )}
                        </div>
                      </div>
                      <div className="horoscope_match">
                        <div className="rating_card">
                          <div className="rating_title">
                            <h3>Today's Matches</h3>
                          </div>
                          {isLoaded2 ? (
                            <div className="horoscope_match_flex">
                              <div className="horoscope_match_cta horoscope_cta">
                                <label> Love </label>
                                <div className="tech-icon  ">
                                  <img src={signImages[matches?.love]} alt="" />
                                </div>
                                <label>{matches?.love}</label>
                              </div>
                              <div className="horoscope_match_cta horoscope_cta">
                                <label> Friendship </label>
                                <div className="tech-icon  ">
                                  <img src={signImages[matches?.friendship]} alt="" />
                                </div>
                                <label>{matches?.friendship}</label>
                              </div>
                              <div className="horoscope_match_cta horoscope_cta">
                                <label> Career </label>
                                <div className="tech-icon  ">
                                  <img src={signImages[matches?.career]} alt="" />
                                </div>
                                <label>{matches?.career}</label>
                              </div>
                            </div>
                          ) : (
                            <TodayMatch />
                          )}
                        </div>
                      </div>
                      <div className="horoscope_ratting">
                        <div className="rating_card">
                          <div className="rating_title">
                            <h3>Today's Star Rating</h3>
                          </div>
                          {isLoaded3 ? (
                            <div className="all_ratings">
                              <div className="ratings_cta">
                                <label>Overall</label>
                                <Rating name="read-only" value={ratings?.overallRating} readOnly />
                              </div>
                              <div className="ratings_cta">
                                <label>Love</label>
                                <Rating name="read-only" value={ratings?.loveRating} readOnly />
                              </div>
                              <div className="ratings_cta">
                                <label>Health</label>
                                <Rating name="read-only" value={ratings?.healthRating} readOnly />
                              </div>
                              <div className="ratings_cta">
                                <label>Work</label>
                                <Rating name="read-only" value={ratings?.workRating} readOnly />
                              </div>
                              <div className="ratings_cta">
                                <label>Travel</label>
                                <Rating name="read-only" value={ratings?.travelRating} readOnly />
                              </div>
                              <div className="ratings_cta">
                                <label>Finance</label>
                                <Rating name="read-only" value={ratings?.financeRating} readOnly />
                              </div>
                            </div>
                          ) : (
                            <RatingLoader />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="feature_button  ">
                      <button
                        type="button"
                        className="btn btn-secondry w-50"
                        onClick={() => {
                          navigate("/personalized-horoscope");
                        }}
                      >
                        {" "}
                        See Full Personalized Horoscope
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="dashboard_plan_box">
                    <div className="dashboard_plan_details">
                      <div className="dashboard_plan_heading">
                        <h3>Subscription Details</h3>
                        <span className="badge Standard">{isFreeTrial ? "Free Tier" : isFullReport ? "Premium Tier" : "Basic Tier"}</span>
                      </div>
                      {(hasExpired || isFreeTrial || timeRemaining < 2.592e8) && <div className="text-center ">{hasExpired ? "The plan has been expired" : `${RemainingTime}  remaining`} </div>}
                      <div className="dashboard_plan_body">
                        <div className="trail_box">
                          <h4>Enjoy a {isFreeTrial ? "7-Day Free Trial" : "Month"} of Our Astrology Features! </h4>
                        </div>
                        <div className="plan_feature_list">
                          <h4 className="  mb-2"> Features Include</h4>
                          {isFreeTrial ? (
                            <ul>
                              <li>
                                <h5>10 free questions with Kashyapji</h5>
                              </li>
                              <li>
                                <h5>Summary of personalized horoscope</h5>
                              </li>
                              <li>
                                <h5>Basic Life Report</h5>
                              </li>
                            </ul>
                          ) : !isFullReport ? (
                            <ul>
                              <li>
                                <h5>Unlimited questions</h5>
                              </li>
                              <li>
                                <h5>Complete daily/ weekly/ monthly personalized horoscope with love/ career/ finance advice</h5>
                              </li>
                              <li>
                                <h5> Basic Life Report</h5>
                              </li>
                            </ul>
                          ) : (
                            <ul>
                              <li>
                                <h5>Unlock Complete Life Report permanently</h5>
                              </li>
                              <li>
                                <h5>Unlimited questions</h5>
                              </li>
                              <li>
                                <h5>Complete daily/ weekly/ monthly personalized horoscope with love/ career/ finance advice</h5>
                              </li>
                            </ul>
                          )}
                        </div>
                      </div>
                      <div className="dashboard_plan_button pb-5">
                        {isFreeTrial && <h4>Upgrade to Unlock Full Access</h4>}
                        <button
                          type="button"
                          className="btn btn-primary w-100"
                          onClick={() => {
                            navigate("/plans");
                          }}
                        >
                          <AutoFixHighIcon />
                          {isFreeTrial ? "Upgrade" : "View"} Plan
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="padding_medium commingsoon">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-6 colmargin">
                  <motion.div initial={{ opacity: 0, y: 30 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1.9 }} className="dashboard_features">
                    <div className="dashboard_features_flex">
                      <div className="dash_feature_icon tech-icon  ">
                        <img src={lifereport} alt="Personalized Life Report" />
                      </div>
                      <div className="dashboard_features_content">
                        <h2>Your Personal Life Report</h2>
                        <p>
                          Discover the mysteries of your life and future with NaviVibe's comprehensive Life Report. Uncover personalized insights into your astrological profile, revealing your
                          strengths, challenges, and opportunities.
                        </p>
                        <p>
                          <b>Continue reading to unlock the complete analysis and personalized guidance that our Life Report offers.</b>
                        </p>
                        <div className="feature_button  text-center ">
                          <Link to="/life-report" className="w-100">
                            <button type="button" className="btn btn-secondry w-100 ">
                              {" "}
                              View Full Life Report
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                </div>
                <div className="col-md-6 colmargin">
                  <motion.div initial={{ opacity: 0, y: 30 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1.9 }} className="dashboard_features">
                    <div className="dashboard_features_flex">
                      <div className="dash_feature_icon tech-icon  ">
                        <img src={consultguruji} alt="Personalized Life Report" />
                      </div>
                      <div className="dashboard_features_content">
                        <h2>Wondering how today's cosmic events affect you? Ask Guruji for personalized insights!</h2>
                        <p> Get personalized advice on today's cosmic challenges, career decisions, relationship dilemmas, and personal growth issues.</p>

                        <div className="" style={{ position: "relative" }}>
                          {(hasExpired || (isFreeTrial && questionsAllowed === 0)) && (
                            <div className="access_message">
                              <div className="dashboard_plan_button">
                                <h4>Upgrade to Unlock Full Access</h4>
                                <Link to="/plans">
                                  <button type="button" className="btn btn-primary  ">
                                    <AutoFixHighIcon />
                                    Upgrade Plan
                                  </button>
                                </Link>
                              </div>
                            </div>
                          )}

                          <div className="dash_chat_prompt">
                            <div className="dash_chat_field">
                              <FormControl fullWidth>
                                <TextField
                                  type="text"
                                  placeholder="Ask your question"
                                  value={question}
                                  onChange={(e) => setQuestion(e.target.value)}
                                  disabled={hasExpired || (isFreeTrial && questionsAllowed === 0)}
                                />
                              </FormControl>
                            </div>
                          </div>
                          <div className="feature_button  text-center mt-3 ">
                            {/* <Link to="/new-astro-chatbot" className="w-100"> */}
                            <button
                              type="button"
                              className="btn btn-secondry w-100 "
                              onClick={() => {
                                console.log(hasExpired, isFreeTrial, questionsAllowed);
                                if (question !== "") {
                                  localStorage.setItem("question", question);
                                  navigate("/new-astro-chatbot");
                                } else {
                                  setSeverity("error");
                                  setMessage("Please enter a question to continue");
                                  setSnackbar(true);
                                }
                              }}
                              disabled={hasExpired || (isFreeTrial && questionsAllowed === 0)}
                            >
                              {" "}
                              Chat with Guruji
                            </button>
                            {/* </Link> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                </div>
              </div>
            </div>
          </section>
          <section className="padding_medium commingsoon">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-11">
                  <div className="step-heading">
                    <h2 className="text-center mt-3"> Get Ready for More! </h2>
                    <p className="text-center   margbottom-50  paddinglr "> Exciting new features are on the way! Get a sneak peek at what's coming to NaviVibe :</p>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-5 colmargin">
                  <motion.div initial={{ opacity: 0, y: 30 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1.9 }} className="our_features_box">
                    <div className="our_features_flex  ">
                      <div className="feature_image_box">
                        <img src={SuccessCalendar} alt="consult guruji" height={200} />
                      </div>
                      <div className="feature_content">
                        <div className="feature_title">
                          <h2>
                            Never Miss a Muhurta with the Astro <br /> Calendar
                          </h2>
                          <p>Stay in sync with the universe and receive timely alerts for celestial events, keeping you aligned with the cosmos.</p>
                        </div>
                        <div className="feature_button  text-center ">
                          <button type="button" className="btn btn-secondry ">
                            {" "}
                            Coming Soon
                          </button>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                </div>
                <div className="col-md-5 colmargin">
                  <motion.div initial={{ opacity: 0, y: 30 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1.9 }} className="our_features_box">
                    <div className="our_features_flex  ">
                      <div className="feature_image_box">
                        <img src={relationshiphome} alt="consult guruji" height={100} />
                      </div>
                      <div className="feature_content">
                        <div className="feature_title">
                          <h2>Celestial Matchmaking and Relationship Calculators</h2>
                          <p>Explore cosmic connections with our matchmaking and relationship calculators.</p>
                        </div>
                        <div className="feature_button text-center  ">
                          <button type="button" className="btn btn-secondry ">
                            {" "}
                            Coming Soon
                          </button>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <PageLoader />
      )}
      <FooterPage />
    </div>
  );
};
