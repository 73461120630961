import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { changeAuth } from "../actions/loginAction";
import HeaderPage from "../_layout/elements/header";
import MessageSnackbar from "../_layout/elements/snackbar";
import { styled } from "@mui/material/styles";
import host from "../host";
import {
  TableHead,
  Switch,
  Tooltip,
  Zoom,
  FormControlLabel,
  Tab,
  Tabs,
  Popover,
  Table,
  List,
  ListItemButton,
  CircularProgress,
  TableRow,
  Avatar,
  TableCell,
  TableBody,
  TableContainer,
  ListItemText,
  Collapse,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ImageComponent from "../components/image-component";
import ExpandMore from "@mui/icons-material/ExpandMore";
import DoneIcon from "@mui/icons-material/Done";
import TableLoader from "../components/loader/table-loader";
import dictionary from "../dictionary.json";
import tooltipInfo from "../tooltipInfo.json";
import PlanDuration from "../components/plan_duration";
import dresscode from "../assets/images/dresscode.png";
import Deity from "../assets/images/Deity.png";
import Fasting from "../assets/images/fasting.png";
import Alms from "../assets/images/alms.png";
import Poojas from "../assets/images/pooja.png";
import Mantra from "../assets/images/mantra.png";
import Yantras from "../assets/images/yantra.png";
import colour from "../assets/images/colour.png";
import Gemstones from "../assets/images/gemstone.png";
import ContentLoader from "../components/loader/content-loader";
import CloseIcon from '@mui/icons-material/Close';
import { use } from "marked";
export const LifeReport = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = localStorage.getItem("login") === "true";

  useEffect(() => {
    if (!auth) {
      navigate("/logout");
    }
  }, [auth]);
  const [severity, setSeverity] = useState("success");
  const [snackbar, setSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const onClose = () => {
    setSnackbar(false);
  };

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [open, setOpen] = React.useState(true);
  const handleClick = () => {
    setOpen(!open);
  };
  const [open2, setOpen2] = React.useState(false);
  const handleClick2 = () => {
    setOpen2(!open2);
  };
  const [open3, setOpen3] = React.useState(false);
  const handleClick3 = () => {
    setOpen3(!open3);
  };
  const [open4, setOpen4] = React.useState(false);
  const handleClick4 = () => {
    setOpen4(!open4);
  };
  const [open5, setOpen5] = React.useState(false);
  const handleClick5 = () => {
    setOpen5(!open5);
  };
  const [open6, setOpen6] = React.useState(false);
  const handleClick6 = () => {
    setOpen6(!open6);
  };
  const IOSSwitch = styled((props) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(({ theme }) => ({
    width: 38,
    height: 20,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 16,
      height: 16,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const [isClassAdded, setIsClassAdded] = useState(false);
  const handleButtonClick = () => {
    setIsClassAdded((prevIsClassAdded) => !prevIsClassAdded);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const openpopover = Boolean(anchorEl);

  const [popoverContent, setPopoverContent] = React.useState(null);

  function wrapWithTooltip(text) {
    let words = text.split(" ");
    let result = [];
    for (let i = 0; i < words.length; i++) {
      let found = false;
      for (let j = words.length; !found && j > i; j--) {
        let phrase = words.slice(i, j).join(" ");
        let tooltipKey = Object.keys(tooltipInfo).find((key) => key === phrase || tooltipInfo[key].title === phrase);
        if (tooltipKey) {
          result.push(
            <Link
              key={i}
              aria-owns={openpopover ? "mouse-over-popover" : undefined}
              aria-haspopup="true"
              onMouseEnter={(event) => {
                setPopoverContent(tooltipInfo[tooltipKey]);
                handlePopoverOpen(event);
              }}
              onMouseLeave={handlePopoverClose}
            >
              {phrase}
            </Link>
          );
          i = j - 1; // Skip to the next word after the phrase
          found = true;
        }
      }
      if (!found) {
        result.push(words[i]);
      }
    }
    return result.reduce((prev, curr, i) => (prev === null ? [curr] : [...prev, " ", curr]), null);
  }

  function convertTo12HourFormat(hour, minute) {
    const suffix = hour >= 12 ? "PM" : "AM";
    hour = hour > 12 ? hour - 12 : hour;
    hour = hour === 0 ? 12 : hour;
    minute = minute < 10 ? "0" + minute : minute;
    return `${hour}:${minute} ${suffix}`;
  }

  const imageMap = {
    dress_code: dresscode,
    deity_worship: Deity,
    fasting: Fasting,
    alms: Alms,
    poojas: Poojas,
    mantra_chanting: Mantra,
    yantras: Yantras,
  };

  const [reportData, setReportData] = useState(null);
  const [reportDataId, setReportDataId] = useState(null);
  const [reportItem, setReportItem] = useState(null);
  const [profile, setProfile] = useState(null);
  const [basics, setBasics] = useState(null);
  const [reportHeading, setReportHeading] = useState("Birth Chart");
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoaded2, setIsLoaded2] = useState(false);
  const [isLoaded3, setIsLoaded3] = useState(false);
  const [overviewLoaded, setOverviewLoaded] = useState(false);
  const [lifeThemesLoaded, setLifeThemeLoaded] = useState(false);
  const [bhavaInsightsLoaded, setBhavaInsightsLoaded] = useState(false);
  const [forecastsLoaded, setForecastsLoaded] = useState(false);
  const [favourablePeriodsLoaded, setFavourablePeriodsLoaded] = useState(false);
  const [planetaryInfluencesLoaded, setPlanetaryInfluencesLoaded] = useState(false);
  const [doshaRemediesLoaded, setDoshaRemediesLoaded] = useState(false);
  const [astrologicalCalculationsLoaded, setAstrologicalCalculationsLoaded] = useState(false);
  const [enhancementsLoaded, setEnhancementsLoaded] = useState(false);
  const [reportStatus, setReportStatus] = useState(false);

  const getProfile = () => {
    setIsLoaded(false);
    fetch(host.astro_backend + `/user`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 403) {
          dispatch(changeAuth(false, null));
          navigate("/logout");
        } else {
          setIsLoaded(true);
          setSeverity("error");
          setMessage("Something went wrong");
          setSnackbar(true);
        }
      })
      .then((data) => {
        if (data) {
          setProfile(data);
          setIsLoaded(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoaded(true);
      });
  };

  /*hindi eng  switch function start*/
  const [currentLanguage, setCurrentLanguage] = useState("english");
  const handleLanguageToggle = () => {
    setCurrentLanguage((prevLanguage) => (prevLanguage === "english" ? "hindi" : "english"));
  };
  useEffect(() => {
    if (profile) {
      if (currentLanguage === "hindi") {
        getHindiReport();
      } else if (currentLanguage === "english") {
        getReport();
      }
    }
  }, [currentLanguage, profile]);
  /*hindi eng  switch function end*/

  //kundli svg functionality begins

  const [kundliData, setKundliData] = useState(null);
  const [kundliLoading, setKundliLoading] = useState(false);

  const getKundli = () => {
    setKundliLoading(true);
    if (!profile) {
      return;
    }

    // Encode the credentials in base64
    const auth = btoa(`${host.username}:${host.password}`);

    fetch(host.astro_url + "/horo_chart_image/:D1", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${auth}`,
      },
      body: JSON.stringify({
        day: profile.birth_day,
        month: profile.birth_month,
        year: profile.birth_year,
        hour: profile.birth_hour,
        lat: profile.lat,
        lon: profile.long,
        min: profile.birth_minute,
        tzone: profile.timezone,
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 403) {
          dispatch(changeAuth(false, null));
          navigate("/logout");
        } else {
          setKundliLoading(false);
          setSeverity("error");
          setMessage("Something went wrong");
          setSnackbar(true);
        }
      })
      .then((data) => {
        if (data) {
          setKundliData(data.svg);
          setKundliLoading(false);
        }
      })
      .catch((err) => {
        setKundliLoading(false);
        console.log(err);
      });
  };

  //kundli svg functionality ends

  const getReport = () => {
    setIsLoaded2(false);
    fetch(host.astro_backend + `/report`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 403) {
          dispatch(changeAuth(false, null));
          navigate("/logout");
        } else if (res.status === 400) {
          setIsLoaded2(true);
        } else {
          setIsLoaded2(true);
          setSeverity("error");
          setMessage("Something went wrong");
          setSnackbar(true);
        }
      })
      .then((data) => {
        if (data) {
          setReportData(data);
          setReportDataId(data._id);
          if (data.status === "completed" && data.birth_chart === null) {
            getKundli();
          } else {
            setKundliData(data.birth_chart?.svg);
          }
          // if (data.status === "completed" && data.translated_status === "completed") {
          //   setReportStatus(true);
          // }
          if (data.status === "completed") {
            setReportStatus(true);
            setReportHeading("Birth Chart");
            setBasics(data.vedic_horoscope.astro_details);
            setIsLoaded2(true);
            if (data.life_summary) {
              setOverviewLoaded(true);
            }
            if (data.life_theme) {
              setLifeThemeLoaded(true);
            }
            if (data.bhava_insights) {
              setBhavaInsightsLoaded(true);
            }
            if (data.forecast) {
              setForecastsLoaded(true);
            }
            if (data.favourable_periods) {
              setFavourablePeriodsLoaded(true);
            }
            if (data.dashas) {
              setPlanetaryInfluencesLoaded(true);
            }
            if (data.graha_dosham && data.remedies) {
              setDoshaRemediesLoaded(true);
            }
            if (data.ashtakavarga) {
              setAstrologicalCalculationsLoaded(true);
            }
            if (data.enhancements_personal_growth) {
              setEnhancementsLoaded(true);
            }
          } else {
            setIsLoaded2(false);
            setTimeout(() => {
              getReport();
            }, 20000);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoaded2(true);
      });
  };

  const getHindiReport = () => {
    setReportHeading("Birth Chart");
    setIsLoaded2(false);
    fetch(host.astro_backend + `/report/${reportDataId}/translate`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 403) {
          dispatch(changeAuth(false, null));
          navigate("/logout");
        } else if (res.status === 400) {
          setIsLoaded2(true);
        } else {
          setIsLoaded2(true);
          setSeverity("error");
          setMessage("Something went wrong");
          setSnackbar(true);
        }
      })
      .then((data) => {
        if (data) {
          setReportData(data);
          if (data.status === "completed" && data.birth_chart === null) {
            getKundli();
          } else {
            setKundliData(data.birth_chart?.svg);
          }
          if (data.status === "completed") {
            setBasics(data.vedic_horoscope.astro_details);
            setIsLoaded2(true);
            if (data.life_summary) {
              setOverviewLoaded(true);
            }
            if (data.life_theme) {
              setLifeThemeLoaded(true);
            }
            if (data.bhava_insights) {
              setBhavaInsightsLoaded(true);
            }
            if (data.forecast) {
              setForecastsLoaded(true);
            }
            if (data.favourable_periods) {
              setFavourablePeriodsLoaded(true);
            }
            if (data.dashas) {
              setPlanetaryInfluencesLoaded(true);
            }
            if (data.graha_dosham && data.remedies) {
              setDoshaRemediesLoaded(true);
            }
            if (data.ashtakavarga) {
              setAstrologicalCalculationsLoaded(true);
            }
            if (data.enhancements_personal_growth) {
              setEnhancementsLoaded(true);
            }
          } else {
            setIsLoaded2(false);
            setTimeout(() => {
              if (currentLanguage === "hindi") {
                getHindiReport();
              }
            }, 20000);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoaded2(true);
      });
  };

  const handleGenerateReport = () => {
    setReportHeading("Birth Chart");
    setIsLoaded2(false);
    fetch(host.astro_backend + `/report/generate`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 403) {
          dispatch(changeAuth(false, null));
          navigate("/logout");
        } else {
          setSeverity("error");
          setMessage("Something went wrong");
          setSnackbar(true);
        }
      })
      .then((data) => {
        if (data.success) {
          // setProfile(data);
          setSeverity("success");
          setMessage(data.message);
          setSnackbar(true);
          getReport();
        }
      })
      .catch((err) => {
        setIsLoaded2(true);
        console.log(err);
      });
  };

  const [selectedTabData, setSelectedTabData] = useState(null);
  useEffect(() => {
    if (!reportItem) return;
    else if (reportHeading === "Complete Life Summary") {
      switch (value) {
        case 0:
          setSelectedTabData(reportItem.complete_summary?.life_walkthrough);
          break;
        case 1:
          setSelectedTabData(reportItem.planets);
          break;
        case 2:
          setSelectedTabData(reportItem.houses);
          break;
        case 3:
          setSelectedTabData(reportItem.ascendant);
          break;
        case 4:
          setSelectedTabData(reportItem.progressions);
          break;
        case 5:
          setSelectedTabData(reportItem.moon_phases_eclipses);
          break;
        case 6:
          setSelectedTabData(reportItem.midheaven);
          break;
        default:
          setSelectedTabData(null);
      }
    }
  }, [value, reportItem]);

  const [isFreeTrial, setIsFreeTrial] = useState(null);
  const [isFullReport, setIsFullReport] = useState(null);
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [hasExpired, setHasExpired] = useState(false);

  const getUserPlan = () => {
    setIsLoaded3(false);
    fetch(host.astro_backend + `/plans/user/plan`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 403) {
          dispatch(changeAuth(false, null));
          navigate("/logout");
        } else {
          setIsLoaded3(true);
          setSeverity("error");
          setMessage("Something went wrong");
          setSnackbar(true);
        }
      })
      .then((data) => {
        if (data) {
          if (data.is_free_trial) {
            setIsFreeTrial(true);
          } else {
            setIsFreeTrial(false);
          }
          if (data.is_full_life_report) {
            setIsFullReport(true);
          } else {
            setIsFullReport(false);
          }
          // Calculate time remaining until expiration
          const currentDate = new Date();
          const expirationDate = new Date(data.expires_at);
          const temp = expirationDate - currentDate;
          setTimeRemaining(temp);
          if (currentDate > expirationDate) {
            setHasExpired(true);
          } else {
            setHasExpired(false);
          }
          setIsLoaded3(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoaded3(true);
      });
  };

  useEffect(() => {
    getProfile();
    getUserPlan();
  }, []);

  useEffect(() => {}, [reportData, reportItem]);

  return (
    <div>
      <MessageSnackbar open={snackbar} autoHideDuration={3000} onClose={onClose} severity={severity} message={message} />

      <div className="inner-header">
        <HeaderPage />
      </div>
      <section className="kundli_reports">
        <div className="reports">
          <div className="reports_tab_content">
            <div className="mobile_report_head">
              <button type="button" className="btn btn-primary w-100" onClick={handleButtonClick}>
                {" "}
                View Report Menu{" "}
              </button>
            </div>
            <div className="reports_flex">
              <div className={`reports_aside ${isClassAdded ? "showreportmenu" : ""}`}>
                <div className="reports_aside_fix">
                  {reportStatus && (
                    <div className="mobmenuhead">
                      <div className="switchlanguage">
                        <Tooltip className="blue-tooltip" title="Change Language" TransitionComponent={Zoom} placement="top" arrow>
                          <FormControlLabel
                            size="small"
                            control={<IOSSwitch size="small" sx={{ m: 1 }} fontSize="small" checked={currentLanguage === "hindi"} onChange={handleLanguageToggle} />}
                            label={currentLanguage === "hindi" ? "हिंदी" : "English"}
                          />
                        </Tooltip>
                      </div>
                      <div className="report_menu_close">
                    <Link to="" className="close closemenubar" onClick={handleButtonClick}>
                     <CloseIcon/>
                    </Link>
                  </div>
                      {/* {isLoaded && isLoaded2 && isLoaded3 && (
                      <div className="regenerate pt-2 pb-2">
                        <button type="button" className=" btn btn-primary w-100" onClick={handleGenerateReport} disabled={hasExpired}>
                          {" "}
                          <RefreshIcon />
                          Regenerate Report
                        </button>
                      </div>
                    )} */}
                    </div>
                  )}
              
                  {/* </div> */}
                  <div className="reports_aside_body">
                    <List component="nav" aria-labelledby="nested-list-subheader">
                      <div className="report_aside_cta">
                        <div className={`report_steper ${overviewLoaded && isLoaded2 ? "active" : ""}`}>
                          <div className="indication-icon ">
                            <Avatar className={overviewLoaded && isLoaded2 ? "bg-success" : ""}>{overviewLoaded && isLoaded2 ? <DoneIcon /> : <CircularProgress size={15} />}</Avatar>
                          </div>
                        </div>
                        <div className="report_aside_menubox">
                          <ListItemButton
                            onClick={handleClick}
                            selected={
                              reportHeading === "Birth Chart" ||
                              reportHeading === "Complete Life Summary" ||
                              reportHeading === "Personal Astrological Details" ||
                              reportHeading === "Panchanga Predictions" ||
                              reportHeading === "Astrological Charts"
                            }
                          >
                            <ListItemText className="report_side_headtitle" primary={dictionary["Overview"]?.[currentLanguage] || dictionary["Overview"]?.english || "Overview"} />
                            {open ? <ExpandLess /> : <ExpandMore />}
                          </ListItemButton>
                          <Collapse in={open} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                              <ListItemButton
                                sx={{ pl: 4 }}
                                selected={reportHeading === "Birth Chart"}
                                onClick={() => {
                                  // setReportItem(reportData);
                                  setReportHeading("Birth Chart");
                                  handleButtonClick();
                                }}
                                disabled={!(overviewLoaded && isLoaded2)}
                              >
                                <ListItemText primary={dictionary["Birth Chart"]?.[currentLanguage] || dictionary["Birth Chart"]?.english || "Birth Chart"} />
                              </ListItemButton>
                              {/* {reportData?.life_summary && ( */}
                              <ListItemButton
                                sx={{ pl: 4 }}
                                selected={reportHeading === "Complete Life Summary"}
                                onClick={() => {
                                  setReportItem(reportData.life_summary);
                                  handleButtonClick();
                                  setReportHeading("Complete Life Summary");
                                }}
                                disabled={!(overviewLoaded && isLoaded2)}
                              >
                                <ListItemText primary={dictionary["Complete Life Summary"]?.[currentLanguage] || dictionary["Complete Life Summary"]?.english || "Complete Life Summary"} />
                              </ListItemButton>
                              {/* )} */}
                              {/* {reportData?.overview_personal && ( */}
                              <ListItemButton
                                sx={{ pl: 4 }}
                                selected={reportHeading === "Personal Astrological Details"}
                                onClick={() => {
                                  setReportItem(reportData.overview_personal);
                                  handleButtonClick();
                                  setReportHeading("Personal Astrological Details");
                                }}
                                disabled={!(overviewLoaded && isLoaded2) || !isFullReport || reportData.overview_personal === null}
                              >
                                <ListItemText
                                  primary={dictionary["Personal Astrological Details"]?.[currentLanguage] || dictionary["Personal Astrological Details"]?.english || "Personal Astrological Details"}
                                />
                              </ListItemButton>
                              {/* )} */}
                              {/* {reportData?.overview_panchanga && ( */}
                              <ListItemButton
                                sx={{ pl: 4 }}
                                selected={reportHeading === "Panchanga Predictions"}
                                onClick={() => {
                                  setReportItem(reportData.overview_panchanga);
                                  handleButtonClick();
                                  setReportHeading("Panchanga Predictions");
                                }}
                                disabled={!(overviewLoaded && isLoaded2) || !isFullReport || reportData.overview_panchanga === null}
                              >
                                <ListItemText primary={dictionary["Panchanga Predictions"]?.[currentLanguage] || dictionary["Panchanga Predictions"]?.english || "Panchanga Predictions"} />
                              </ListItemButton>
                              {/* )} */}
                              {reportData?.birth_chart_analysis && reportData?.navmasa_chart_analysis && (
                                <ListItemButton
                                  sx={{ pl: 4 }}
                                  selected={reportHeading === "Astrological Charts"}
                                  onClick={() => {
                                    setReportItem({
                                      ...reportData.birth_chart_analysis,
                                      ...reportData.navmasa_chart_analysis,
                                    });
                                    handleButtonClick();
                                    setReportHeading("Astrological Charts");
                                  }}
                                  disabled={!(overviewLoaded && isLoaded2) || !isFullReport}
                                >
                                  <ListItemText primary={dictionary["Astrological Charts"]?.[currentLanguage] || dictionary["Astrological Charts"]?.english || "Astrological Charts"} />
                                </ListItemButton>
                              )}
                            </List>
                          </Collapse>
                        </div>
                      </div>
                      <div className="report_aside_cta">
                        <div className={`report_steper ${lifeThemesLoaded && isLoaded2 ? "active" : ""}`}>
                          <div className="indication-icon">
                            <div className="indication-icon">
                              <Avatar className={lifeThemesLoaded && isLoaded2 ? "bg-success" : ""}>{lifeThemesLoaded && isLoaded2 ? <DoneIcon /> : <CircularProgress size={15} />}</Avatar>
                            </div>
                          </div>
                        </div>
                        <div className="report_aside_menubox">
                          <ListItemButton
                            selected={reportHeading === "Life Themes"}
                            onClick={() => {
                              setReportItem(reportData.life_theme);
                              handleButtonClick();
                              setReportHeading("Life Themes");
                            }}
                            disabled={!(lifeThemesLoaded && isLoaded2) || !isFullReport}
                          >
                            <ListItemText className="report_side_headtitle" primary={dictionary["Life Themes"]?.[currentLanguage] || dictionary["Life Themes"]?.english || "Life Themes"} />
                          </ListItemButton>
                        </div>
                      </div>
                      <div className="report_aside_cta">
                        <div className={`report_steper ${bhavaInsightsLoaded && isLoaded2 ? "active" : ""}`}>
                          <div className="indication-icon">
                            <div className="indication-icon">
                              <Avatar className={bhavaInsightsLoaded && isLoaded2 ? "bg-success" : ""}>{bhavaInsightsLoaded && isLoaded2 ? <DoneIcon /> : <CircularProgress size={15} />}</Avatar>
                            </div>
                          </div>
                        </div>
                        <div className="report_aside_menubox">
                          <ListItemButton
                            selected={reportHeading === "Bhava Insights"}
                            onClick={() => {
                              setReportItem(reportData.bhava_insights);
                              handleButtonClick();
                              setReportHeading("Bhava Insights");
                            }}
                            disabled={!(bhavaInsightsLoaded && isLoaded2) || !isFullReport}
                          >
                            <ListItemText className="report_side_headtitle" primary={dictionary["Bhava Insights"]?.[currentLanguage] || dictionary["Bhava Insights"]?.english || "Bhava Insights"} />
                          </ListItemButton>
                        </div>
                      </div>
                      <div className="report_aside_cta">
                        <div className={`report_steper ${forecastsLoaded && isLoaded2 ? "active" : ""}`}>
                          <div className="indication-icon">
                            <div className="indication-icon">
                              <Avatar className={forecastsLoaded && isLoaded2 ? "bg-success" : ""}>{forecastsLoaded && isLoaded2 ? <DoneIcon /> : <CircularProgress size={15} />}</Avatar>
                            </div>
                          </div>
                        </div>
                        <div className="report_aside_menubox">
                          <ListItemButton onClick={handleClick2} selected={reportHeading === "Short-Term Forecasts (Next 12 Months)" || reportHeading === "Long-Term Forecasts (Next 10 Years)"}>
                            <ListItemText className="report_side_headtitle" primary={dictionary["Forecasts"]?.[currentLanguage] || dictionary["Forecasts"]?.english || "Forecasts"} />
                            {open2 ? <ExpandLess /> : <ExpandMore />}
                          </ListItemButton>
                          <Collapse in={open2} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                              <ListItemButton
                                sx={{ pl: 4 }}
                                selected={reportHeading === "Short-Term Forecasts (Next 12 Months)"}
                                onClick={() => {
                                  setReportItem(reportData.forecast?.short_term_forecasts);
                                  handleButtonClick();
                                  setReportHeading("Short-Term Forecasts (Next 12 Months)");
                                }}
                                disabled={!(forecastsLoaded && isLoaded2) || !isFullReport}
                              >
                                <ListItemText
                                  primary={
                                    dictionary["Short-Term Forecasts (Next 12 Months)"]?.[currentLanguage] ||
                                    dictionary["Short-Term Forecasts (Next 12 Months)"]?.english ||
                                    "Short-Term Forecasts (Next 12 Months)"
                                  }
                                />
                              </ListItemButton>
                              <ListItemButton
                                sx={{ pl: 4 }}
                                selected={reportHeading === "Long-Term Forecasts (Next 10 Years)"}
                                onClick={() => {
                                  setReportItem(reportData.forecast?.long_term_forecasts);
                                  handleButtonClick();
                                  setReportHeading("Long-Term Forecasts (Next 10 Years)");
                                }}
                                disabled={!(forecastsLoaded && isLoaded2) || !isFullReport}
                              >
                                <ListItemText
                                  primary={
                                    dictionary["Long-Term Forecasts (Next 10 Years)"]?.[currentLanguage] ||
                                    dictionary["Long-Term Forecasts (Next 10 Years)"]?.english ||
                                    "Long-Term Forecasts (Next 10 Years)"
                                  }
                                />
                              </ListItemButton>
                            </List>
                          </Collapse>
                        </div>
                      </div>
                      <div className="report_aside_cta">
                        <div className={`report_steper ${favourablePeriodsLoaded && isLoaded2 ? "active" : ""}`}>
                          <div className="indication-icon">
                            <div className="indication-icon">
                              <Avatar className={favourablePeriodsLoaded && isLoaded2 ? "bg-success" : ""}>
                                {favourablePeriodsLoaded && isLoaded2 ? <DoneIcon /> : <CircularProgress size={15} />}
                              </Avatar>
                            </div>
                          </div>
                        </div>
                        <div className="report_aside_menubox">
                          <ListItemButton
                            selected={reportHeading === "Favourable Periods"}
                            onClick={() => {
                              setReportItem(reportData.favourable_periods);
                              handleButtonClick();
                              setReportHeading("Favourable Periods");
                            }}
                            disabled={!(favourablePeriodsLoaded && isLoaded2) || !isFullReport}
                          >
                            <ListItemText
                              className="report_side_headtitle"
                              primary={dictionary["Favourable Periods"]?.[currentLanguage] || dictionary["Favourable Periods"]?.english || "Favourable Periods"}
                            />
                          </ListItemButton>
                        </div>
                      </div>
                      <div className="report_aside_cta">
                        <div className={`report_steper ${planetaryInfluencesLoaded && isLoaded2 ? "active" : ""}`}>
                          <div className="indication-icon">
                            <Avatar className={planetaryInfluencesLoaded && isLoaded2 ? "bg-success" : ""}>
                              {planetaryInfluencesLoaded && isLoaded2 ? <DoneIcon /> : <CircularProgress size={15} />}
                            </Avatar>
                          </div>
                        </div>
                        <div className="report_aside_menubox">
                          <ListItemButton
                            onClick={handleClick3}
                            selected={
                              reportHeading === "Effect of Dasa/Apahara" ||
                              reportHeading === "Paryanthar Dasa" ||
                              reportHeading === "Yoga Analysis" ||
                              reportHeading === "Auspicious planetary combinations" ||
                              reportHeading === "Planetary Conditions" ||
                              reportHeading === "Sub-Planetary Details"
                            }
                          >
                            <ListItemText
                              className="report_side_headtitle"
                              primary={
                                dictionary["Planetary Influences and Yoga Analysis"]?.[currentLanguage] ||
                                dictionary["Planetary Influences and Yoga Analysis"]?.english ||
                                "Planetary Influences and Yoga Analysis"
                              }
                            />
                            {open3 ? <ExpandLess /> : <ExpandMore />}
                          </ListItemButton>
                          <Collapse in={open3} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                              <ListItemButton
                                sx={{ pl: 4 }}
                                selected={reportHeading === "Effect of Dasa/Apahara"}
                                onClick={() => {
                                  setReportItem(reportData.dashas);
                                  handleButtonClick();
                                  setReportHeading("Effect of Dasa/Apahara");
                                }}
                                disabled={!(planetaryInfluencesLoaded && isLoaded2) || !isFullReport}
                              >
                                <ListItemText primary={dictionary["Effect of Dasa/Apahara"]?.[currentLanguage] || dictionary["Effect of Dasa/Apahara"]?.english || "Effect of Dasa/Apahara"} />
                              </ListItemButton>
                              <ListItemButton
                                sx={{ pl: 4 }}
                                selected={reportHeading === "Paryanthar Dasa"}
                                onClick={() => {
                                  setReportItem(reportData.paryanthar_dashas);
                                  handleButtonClick();
                                  setReportHeading("Paryanthar Dasa");
                                }}
                                disabled={!(planetaryInfluencesLoaded && isLoaded2) || !isFullReport}
                              >
                                <ListItemText primary={dictionary["Paryanthar Dasa"]?.[currentLanguage] || dictionary["Paryanthar Dasa"]?.english || "Paryanthar Dasa"} />
                              </ListItemButton>
                              <ListItemButton
                                sx={{ pl: 4 }}
                                selected={reportHeading === "Yoga Analysis"}
                                onClick={() => {
                                  setReportItem(reportData.yoga_analysis);
                                  handleButtonClick();
                                  setReportHeading("Yoga Analysis");
                                }}
                                disabled={!(planetaryInfluencesLoaded && isLoaded2) || !isFullReport}
                              >
                                <ListItemText primary={dictionary["Yoga Analysis"]?.[currentLanguage] || dictionary["Yoga Analysis"]?.english || "Yoga Analysis"} />
                              </ListItemButton>
                              <ListItemButton
                                sx={{ pl: 4 }}
                                selected={reportHeading === "Auspicious planetary combinations"}
                                onClick={() => {
                                  setReportItem(reportData.auspicious_combinations);
                                  handleButtonClick();
                                  setReportHeading("Auspicious planetary combinations");
                                }}
                                disabled={!(planetaryInfluencesLoaded && isLoaded2) || !isFullReport}
                              >
                                <ListItemText
                                  primary={
                                    dictionary["Auspicious planetary combinations"]?.[currentLanguage] ||
                                    dictionary["Auspicious planetary combinations"]?.english ||
                                    "Auspicious planetary combinations"
                                  }
                                />
                              </ListItemButton>
                              <ListItemButton
                                sx={{ pl: 4 }}
                                selected={reportHeading === "Planetary Conditions"}
                                onClick={() => {
                                  setReportItem(reportData.planetary_conditions);
                                  handleButtonClick();
                                  setReportHeading("Planetary Conditions");
                                }}
                                disabled={!(planetaryInfluencesLoaded && isLoaded2) || !isFullReport}
                              >
                                <ListItemText primary={dictionary["Planetary Conditions"]?.[currentLanguage] || dictionary["Planetary Conditions"]?.english || "Planetary Conditions"} />
                              </ListItemButton>
                              <ListItemButton
                                sx={{ pl: 4 }}
                                selected={reportHeading === "Sub-Planetary Details"}
                                onClick={() => {
                                  setReportItem(reportData.sub_planetary);
                                  handleButtonClick();
                                  setReportHeading("Sub-Planetary Details");
                                }}
                                disabled={!(planetaryInfluencesLoaded && isLoaded2) || !isFullReport}
                              >
                                <ListItemText primary={dictionary["Sub-Planetary Details"]?.[currentLanguage] || dictionary["Sub-Planetary Details"]?.english || "Sub-Planetary Details"} />
                              </ListItemButton>
                            </List>
                          </Collapse>
                        </div>
                      </div>
                      <div className="report_aside_cta">
                        <div className={`report_steper ${doshaRemediesLoaded && isLoaded2 ? "active" : ""}`}>
                          <div className="indication-icon">
                            <Avatar className={doshaRemediesLoaded && isLoaded2 ? "bg-success" : ""}>{doshaRemediesLoaded && isLoaded2 ? <DoneIcon /> : <CircularProgress size={15} />}</Avatar>
                          </div>
                        </div>
                        <div className="report_aside_menubox">
                          <ListItemButton onClick={handleClick4} selected={reportHeading === "Graha dosham and Remedies" || reportHeading === "Remedies"}>
                            <ListItemText
                              className="report_side_headtitle"
                              primary={
                                dictionary["Inauspicious Conditions (Dosha) & Remedies"]?.[currentLanguage] ||
                                dictionary["Inauspicious Conditions (Dosha) & Remedies"]?.english ||
                                "Inauspicious Conditions (Dosha) & Remedies"
                              }
                            />
                            {open4 ? <ExpandLess /> : <ExpandMore />}
                          </ListItemButton>
                          <Collapse in={open4} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                              <ListItemButton
                                sx={{ pl: 4 }}
                                selected={reportHeading === "Graha dosham and Remedies"}
                                onClick={() => {
                                  setReportItem(reportData.graha_dosham);
                                  handleButtonClick();
                                  setReportHeading("Graha dosham and Remedies");
                                }}
                                disabled={!(doshaRemediesLoaded && isLoaded2) || !isFullReport}
                              >
                                <ListItemText primary={dictionary["Graha dosham and Remedies"]?.[currentLanguage] || dictionary["Graha dosham and Remedies"]?.english || "Graha dosham and Remedies"} />
                              </ListItemButton>
                              <ListItemButton
                                sx={{ pl: 4 }}
                                selected={reportHeading === "Remedies"}
                                onClick={() => {
                                  setReportItem(reportData.remedies);
                                  setReportHeading("Remedies");
                                  handleButtonClick();
                                }}
                                disabled={!(doshaRemediesLoaded && isLoaded2) || !isFullReport}
                              >
                                <ListItemText primary={dictionary["Remedies"]?.[currentLanguage] || dictionary["Remedies"]?.english || "Remedies"} />
                              </ListItemButton>
                            </List>
                          </Collapse>
                        </div>
                      </div>
                      <div className="report_aside_cta">
                        <div className={`report_steper ${astrologicalCalculationsLoaded && isLoaded2 ? "active" : ""}`}>
                          <div className="indication-icon">
                            <Avatar className={astrologicalCalculationsLoaded && isLoaded2 ? "bg-success" : ""}>
                              {astrologicalCalculationsLoaded && isLoaded2 ? <DoneIcon /> : <CircularProgress size={15} />}
                            </Avatar>
                          </div>
                        </div>
                        <div className="report_aside_menubox">
                          <ListItemButton
                            onClick={handleClick5}
                            selected={reportHeading === "Ashtakavarga Predictions" || reportHeading === "Transit forecast" || reportHeading === "Advanced Vedic Calculations"}
                          >
                            <ListItemText
                              className="report_side_headtitle"
                              primary={
                                dictionary["Astrological Calculations and Forecasts"]?.[currentLanguage] ||
                                dictionary["Astrological Calculations and Forecasts"]?.english ||
                                "Astrological Calculations and Forecasts"
                              }
                            />
                            {open5 ? <ExpandLess /> : <ExpandMore />}
                          </ListItemButton>
                          <Collapse in={open5} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                              <ListItemButton
                                sx={{ pl: 4 }}
                                selected={reportHeading === "Ashtakavarga Predictions"}
                                onClick={() => {
                                  setReportItem(reportData.ashtakavarga);
                                  handleButtonClick();
                                  setReportHeading("Ashtakavarga Predictions");
                                }}
                                disabled={!(astrologicalCalculationsLoaded && isLoaded2) || !isFullReport}
                              >
                                <ListItemText primary={dictionary["Ashtakavarga Predictions"]?.[currentLanguage] || dictionary["Ashtakavarga Predictions"]?.english || "Ashtakavarga Predictions"} />
                              </ListItemButton>
                              <ListItemButton
                                sx={{ pl: 4 }}
                                selected={reportHeading === "Transit forecast"}
                                onClick={() => {
                                  setReportItem(reportData.transits);
                                  handleButtonClick();
                                  setReportHeading("Transit forecast");
                                }}
                                disabled={!(astrologicalCalculationsLoaded && isLoaded2) || !isFullReport}
                              >
                                <ListItemText primary={dictionary["Transit forecast"]?.[currentLanguage] || dictionary["Transit forecast"]?.english || "Transit forecast"} />
                              </ListItemButton>
                              {/* <ListItemButton
                                sx={{ pl: 4 }}
                                selected={reportHeading === "Advanced Vedic Calculations"}
                                onClick={() => {
                                  // setReportItem(reportData.favourable_periods);
                                  setReportHeading("Advanced Vedic Calculations");
                                }}
                                disabled={true}
                              >
                                <ListItemText primary="Advanced Vedic Calculations" />
                              </ListItemButton> */}
                            </List>
                          </Collapse>
                        </div>
                      </div>
                      <div className="report_aside_cta">
                        <div className={`report_steper ${enhancementsLoaded && isLoaded2 ? "active" : ""}`}>
                          <div className="indication-icon">
                            <Avatar className={enhancementsLoaded && isLoaded2 ? "bg-success" : ""}>{enhancementsLoaded && isLoaded2 ? <DoneIcon /> : <CircularProgress size={15} />}</Avatar>
                          </div>
                        </div>
                        <div className="report_aside_menubox">
                          <ListItemButton onClick={handleClick6} selected={reportHeading === "Lucky Colors and Gemstones" || reportHeading === "Celebrity Comparisons"}>
                            <ListItemText
                              className="report_side_headtitle"
                              primary={dictionary["Enhancements & Personal Growth"]?.[currentLanguage] || dictionary["Enhancements & Personal Growth"]?.english || "Enhancements & Personal Growth"}
                            />
                            {open6 ? <ExpandLess /> : <ExpandMore />}
                          </ListItemButton>
                          <Collapse in={open6} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                              <ListItemButton
                                sx={{ pl: 4 }}
                                selected={reportHeading === "Lucky Colors and Gemstones"}
                                onClick={() => {
                                  setReportItem(reportData.enhancements_personal_growth?.lucky_colors_gemstones);
                                  handleButtonClick();
                                  setReportHeading("Lucky Colors and Gemstones");
                                }}
                                disabled={!(enhancementsLoaded && isLoaded2) || !isFullReport}
                              >
                                <ListItemText
                                  primary={dictionary["Lucky Colors and Gemstones"]?.[currentLanguage] || dictionary["Lucky Colors and Gemstones"]?.english || "Lucky Colors and Gemstones"}
                                />
                              </ListItemButton>
                              <ListItemButton
                                sx={{ pl: 4 }}
                                selected={reportHeading === "Celebrity Comparisons"}
                                onClick={() => {
                                  setReportItem(reportData.enhancements_personal_growth?.celebrity_comparisons);
                                  handleButtonClick();
                                  setReportHeading("Celebrity Comparisons");
                                }}
                                disabled={!(enhancementsLoaded && isLoaded2) || !isFullReport}
                              >
                                <ListItemText primary={dictionary["Celebrity Comparisons"]?.[currentLanguage] || dictionary["Celebrity Comparisons"]?.english || "Celebrity Comparisons"} />
                              </ListItemButton>
                            </List>
                          </Collapse>
                        </div>
                      </div>
                      {/* <div className="report_aside_cta">
                        <div className="report_steper">
                          <div className="indication-icon">
                            <Avatar>
                              <CircularProgress size={15} />
                              
                            </Avatar>
                          </div>
                        </div>
                        <div className="report_aside_menubox">
                          <ListItemButton
                            selected={reportHeading === "Appendices"}
                            onClick={() => {
                              // setReportItem(reportData.favourable_periods);
                              setReportHeading("Appendices");
                            }}
                            disabled={true}
                          >
                            <ListItemText className="report_side_headtitle" primary="Appendices" />
                          </ListItemButton>
                        </div>
                      </div> */}
                    </List>
                  </div>
                </div>
              </div>
              <div className=" " style={{ flexGrow: "1" }}>
                {isFreeTrial !== null && timeRemaining !== null && (isFreeTrial || !isFullReport || timeRemaining < ((2.592 * 10) ^ 8)) && (
                  <div className="fix_plan_wrapper">
                    <PlanDuration timeRemaining={timeRemaining} hasExpired={hasExpired} isFreeTrial={isFreeTrial} questionsAllowed={null} isChat={false} showLifeBanner={isFullReport ? false : true} />
                  </div>
                )}
                <div className="reports_rightsection">
                  <div className="reports_body_wrapper">
                    <section id="general">
                      {reportHeading !== "Birth Chart" &&
                        reportHeading !== "Complete Life Summary" &&
                        reportHeading !== "Yoga Analysis" &&
                        reportHeading !== "Auspicious planetary combinations" &&
                        reportHeading !== "Planetary Conditions" &&
                        reportHeading !== "Favourable Periods" &&
                        reportHeading !== "Paryanthar Dasa" &&
                        reportHeading !== "Graha dosham and Remedies" &&
                        reportHeading !== "Remedies" &&
                        reportHeading !== "Transit forecast" &&
                        reportHeading !== "Lucky Colors and Gemstones" &&
                        reportHeading !== "Celebrity Comparisons" &&
                        reportItem &&
                        Object.entries(reportItem).map(([key, value]) => (
                          <div className="report_section_card" key={key}>
                            <div className="report_card_title">
                              <h3>{wrapWithTooltip(dictionary[key]?.[currentLanguage] !== undefined ? dictionary[key][currentLanguage] : key)}</h3>
                            </div>
                            <div className="report_card_content">
                              <p>{value}</p>
                            </div>
                          </div>
                        ))}
                      {reportHeading === "Birth Chart" && profile && reportData && reportData.birth_details && basics && overviewLoaded && isLoaded2 ? (
                        <div className="basic_details">
                          <div className="row">
                            <div className="col-lg-5 col-12">
                              <div className="basic_card colmargin">
                                <div className="basic_head">
                                  <h3>{dictionary["Basic Details"]?.[currentLanguage]}</h3>
                                </div>
                                <div className="card-body">
                                  <div className="web-table ">
                                    <TableContainer className=" ">
                                      <Table aria-label="simple table">
                                        <TableBody>
                                          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                            <TableCell align="right">{dictionary["Name"]?.[currentLanguage]}</TableCell>
                                            <TableCell align="right">{currentLanguage === "english" ? profile?.fullname : reportData.user_details?.fullname}</TableCell>
                                          </TableRow>
                                          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                            <TableCell align="right">{dictionary["Date of Birth"]?.[currentLanguage]}</TableCell>
                                            <TableCell align="right">
                                              {profile.birth_day}/{profile.birth_month}/{profile.birth_year}
                                            </TableCell>
                                          </TableRow>
                                          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                            <TableCell align="right">{dictionary["Time of Birth"]?.[currentLanguage]}</TableCell>
                                            <TableCell align="right">{convertTo12HourFormat(profile.birth_hour, profile.birth_minute)}</TableCell>
                                          </TableRow>
                                          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                            <TableCell align="right">{dictionary["Place"]?.[currentLanguage]}</TableCell>
                                            <TableCell align="right">{currentLanguage === "english" ? profile?.birth_place : reportData.user_details?.birth_place}</TableCell>
                                          </TableRow>
                                          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                            <TableCell align="right">{dictionary["Gender"]?.[currentLanguage]}</TableCell>
                                            <TableCell align="right">{currentLanguage === "english" ? profile?.gender : reportData.user_details?.gender}</TableCell>
                                          </TableRow>
                                          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                            <TableCell align="right">{dictionary["Latitude"]?.[currentLanguage]} </TableCell>
                                            <TableCell align="right">{profile.lat}</TableCell>
                                          </TableRow>
                                          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                            <TableCell align="right">{dictionary["Longitude"]?.[currentLanguage]} </TableCell>
                                            <TableCell align="right">{profile.long}</TableCell>
                                          </TableRow>
                                          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                            <TableCell align="right">{dictionary["Time Zone"]?.[currentLanguage]} </TableCell>
                                            <TableCell align="right">{profile.timezone}</TableCell>
                                          </TableRow>
                                          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                            <TableCell align="right">{dictionary["Sunrise"]?.[currentLanguage]} </TableCell>
                                            <TableCell align="right">{reportData.birth_details.sunrise}</TableCell>
                                          </TableRow>
                                          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                            <TableCell align="right">{dictionary["Sunset"]?.[currentLanguage]} </TableCell>
                                            <TableCell align="right">{reportData.birth_details.sunset}</TableCell>
                                          </TableRow>
                                          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                            <TableCell align="right">{dictionary["Ayanamsha"]?.[currentLanguage]}</TableCell>
                                            <TableCell align="right">{reportData.birth_details.ayanamsha}</TableCell>
                                          </TableRow>
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  </div>
                                </div>
                              </div>
                              <div className="basic_card">
                                <div className="basic_head">
                                  <h3>{dictionary["Lagna / Ascendant / Basic Birth Chart"]?.[currentLanguage]}</h3>
                                </div>
                                {kundliLoading ? (
                                  <ContentLoader />
                                ) : (
                                  <div className="astro-aside_cta_body">
                                    <div className="kundli_data_wrapper">{kundliData && <div dangerouslySetInnerHTML={{ __html: kundliData }} />}</div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-5 col-12">
                              <div className="basic_card colmargin">
                                <div className="basic_head">
                                  <h3>{dictionary["Avakhada Details"]?.[currentLanguage]}</h3>
                                </div>
                                <div className="card-body">
                                  <div className="web-table ">
                                    <TableContainer className=" ">
                                      <Table aria-label="simple table">
                                        <TableBody>
                                          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                            <TableCell align="right">{wrapWithTooltip(dictionary["Warn"]?.[currentLanguage])} </TableCell>
                                            <TableCell align="right">{basics.varna}</TableCell>
                                          </TableRow>
                                          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                            <TableCell align="right">{wrapWithTooltip(dictionary["Vasya"]?.[currentLanguage])} </TableCell>
                                            <TableCell align="right">{basics.vashya}</TableCell>
                                          </TableRow>
                                          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                            <TableCell align="right">{wrapWithTooltip(dictionary["Yoni"]?.[currentLanguage])} </TableCell>
                                            <TableCell align="right">{basics.yoni}</TableCell>
                                          </TableRow>
                                          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                            <TableCell align="right">{wrapWithTooltip(dictionary["Gone"]?.[currentLanguage])} </TableCell>
                                            <TableCell align="right">{basics.gan}</TableCell>
                                          </TableRow>
                                          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                            <TableCell align="right">{wrapWithTooltip(dictionary["Nadi"]?.[currentLanguage])} </TableCell>
                                            <TableCell align="right">{basics.nadi}</TableCell>
                                          </TableRow>
                                          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                            <TableCell align="right">{wrapWithTooltip(dictionary["Sign"]?.[currentLanguage])} </TableCell>
                                            <TableCell align="right">
                                              {basics.moon_sign === "TAURUS" || basics.moon_sign === "Taurus" ? dictionary["Taurus"]?.[currentLanguage] : basics.moon_sign}
                                            </TableCell>
                                          </TableRow>
                                          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                            <TableCell align="right">{wrapWithTooltip(dictionary["Sign Lord"]?.[currentLanguage])} </TableCell>
                                            <TableCell align="right">{basics.moon_sign_lord}</TableCell>
                                          </TableRow>
                                          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                            <TableCell align="right">{wrapWithTooltip(dictionary["Nakshatra-Charan"]?.[currentLanguage])} </TableCell>
                                            <TableCell align="right">{basics.naksahtra}</TableCell>
                                          </TableRow>
                                          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                            <TableCell align="right">{wrapWithTooltip(dictionary["Yoga"]?.[currentLanguage])} </TableCell>
                                            <TableCell align="right">{basics.yog}</TableCell>
                                          </TableRow>
                                          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                            <TableCell align="right">{wrapWithTooltip(dictionary["Karan"]?.[currentLanguage])} </TableCell>
                                            <TableCell align="right">{basics.karan}</TableCell>
                                          </TableRow>
                                          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                            <TableCell align="right">{wrapWithTooltip(dictionary["Tithi"]?.[currentLanguage])} </TableCell>
                                            <TableCell align="right">{basics.tithi}</TableCell>
                                          </TableRow>
                                          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                            <TableCell align="right">{wrapWithTooltip(dictionary["Yunja"]?.[currentLanguage])} </TableCell>
                                            <TableCell align="right">{basics.yunja}</TableCell>
                                          </TableRow>
                                          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                            <TableCell align="right">{wrapWithTooltip(dictionary["Tatva"]?.[currentLanguage])} </TableCell>
                                            <TableCell align="right">{basics.tatva}</TableCell>
                                          </TableRow>
                                          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                            <TableCell align="right">{wrapWithTooltip(dictionary["Name Alphabet"]?.[currentLanguage])} </TableCell>
                                            <TableCell align="right">{basics.name_alphabet}</TableCell>
                                          </TableRow>
                                          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                            <TableCell align="right">{wrapWithTooltip(dictionary["Paya"]?.[currentLanguage])} </TableCell>
                                            <TableCell align="right">{basics.paya}</TableCell>
                                          </TableRow>
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  </div>
                                </div>
                              </div>
                              <div className="basic_card">
                                <div className="basic_head">
                                  <h3>{dictionary["Panchang Details"]?.[currentLanguage]}</h3>
                                </div>
                                <div className="card-body">
                                  <div className="web-table ">
                                    <TableContainer className=" ">
                                      <Table aria-label="simple table">
                                        <TableBody>
                                          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                            <TableCell align="right">{wrapWithTooltip(dictionary["Tithi"]?.[currentLanguage])}</TableCell>
                                            <TableCell align="right">{reportData.basic_panchang.tithi}</TableCell>
                                          </TableRow>
                                          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                            <TableCell align="right">{wrapWithTooltip(dictionary["Karan"]?.[currentLanguage])}</TableCell>
                                            <TableCell align="right">{reportData.basic_panchang.karan}</TableCell>
                                          </TableRow>
                                          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                            <TableCell align="right">{wrapWithTooltip(dictionary["Yoga"]?.[currentLanguage])} </TableCell>
                                            <TableCell align="right">{reportData.basic_panchang.yog}</TableCell>
                                          </TableRow>
                                          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                            <TableCell align="right">{wrapWithTooltip(dictionary["Nakshatra"]?.[currentLanguage])} </TableCell>
                                            <TableCell align="right">{reportData.basic_panchang.nakshatra}</TableCell>
                                          </TableRow>
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : reportHeading !== "Birth Chart" ? null : (
                        <div className="skeleton_loader">
                          <div className="alert alert-info" role="alert">
                            We're in the process of generating your report.
                            <a href="#" class="alert-link">
                              {" "}
                              Hang tight!
                            </a>
                          </div>
                          <TableLoader />
                        </div>
                      )}
                      {reportHeading === "Complete Life Summary" && reportItem && (
                        <>
                          <div className="reports_tabs">
                            <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto" aria-label="scrollable auto tabs example">
                              <Tab label={dictionary["Complete Summary"]?.[currentLanguage]} />
                              <Tab label={dictionary["Planets"]?.[currentLanguage]} />
                              <Tab label={dictionary["Houses"]?.[currentLanguage]} />
                              <Tab label={dictionary["Ascendant"]?.[currentLanguage]} />
                              <Tab label={dictionary["Progressions"]?.[currentLanguage]} />
                              <Tab label={dictionary["Moon Phases Eclipses"]?.[currentLanguage]} />
                              <Tab label={dictionary["Midheaven"]?.[currentLanguage]} />
                            </Tabs>
                          </div>
                          {(value === 1 || value === 2) &&
                            selectedTabData?.length > 0 &&
                            selectedTabData.map((data, index) => (
                              <div className="report_section_card" key={index}>
                                <div className="report_card_title">
                                  <h3>{wrapWithTooltip(dictionary[data.heading] !== undefined ? dictionary[data.heading] : data.heading)}</h3>
                                </div>
                                <div className="report_card_content">
                                  <p>{wrapWithTooltip(data.explanation)}</p>
                                </div>
                              </div>
                            ))}
                          {value === 3 &&
                            selectedTabData &&
                            Object.entries(selectedTabData).map(([key, value]) => (
                              <div className="report_section_card" key={key}>
                                <div className="report_card_title">
                                  <h3>{wrapWithTooltip(dictionary[key]?.[currentLanguage] !== undefined ? dictionary[key][currentLanguage] : key)}</h3>
                                </div>
                                <div className="report_card_content">
                                  <p>{wrapWithTooltip(typeof value === "object" ? JSON.stringify(value) : value)}</p>
                                </div>
                              </div>
                            ))}
                          {(value === 4 || value === 5 || value === 6) &&
                            selectedTabData &&
                            Object.entries(selectedTabData).map(([key, value]) => (
                              <div className="report_section_card" key={key}>
                                <div className="report_card_title">
                                  <h3>{wrapWithTooltip(dictionary[key]?.[currentLanguage] !== undefined ? dictionary[key][currentLanguage] : key)}</h3>
                                </div>
                                <div className="report_card_content">
                                  <p>{wrapWithTooltip(typeof value === "object" ? JSON.stringify(value) : value)}</p>
                                </div>
                              </div>
                            ))}
                          {value === 0 &&
                            selectedTabData &&
                            Object.entries(selectedTabData).map(([key, value]) => (
                              <div className="report_section_card" key={key}>
                                <div className="report_card_title">
                                  <h3>{wrapWithTooltip(dictionary[key]?.[currentLanguage] !== undefined ? dictionary[key][currentLanguage] : key)}</h3>
                                </div>
                                <div className="report_card_content">
                                  <p>{wrapWithTooltip(typeof value === "object" ? JSON.stringify(value) : value)}</p>
                                </div>
                              </div>
                            ))}
                        </>
                      )}
                      {reportHeading === "Favourable Periods" &&
                        favourablePeriodsLoaded &&
                        reportItem &&
                        Object.entries(reportItem).map(
                          ([category, items]) =>
                            items.length > 0 && (
                              <div className="report_section_card" key={category}>
                                <div className="report_card_title">
                                  <h3>{wrapWithTooltip(dictionary[category]?.[currentLanguage])}</h3>
                                </div>
                                <div className="card_table">
                                  <div className="web-table pdflistingtable  mt-4">
                                    <TableContainer className=" ">
                                      <Table aria-label="simple table">
                                        <TableHead>
                                          <TableRow>
                                            <TableCell>{wrapWithTooltip(dictionary["Dasa"]?.[currentLanguage])}</TableCell>
                                            <TableCell>{wrapWithTooltip(dictionary["Apahara"]?.[currentLanguage])}</TableCell>
                                            <TableCell>{wrapWithTooltip(dictionary["Period Start"]?.[currentLanguage])}</TableCell>
                                            <TableCell>{wrapWithTooltip(dictionary["Period End"]?.[currentLanguage])}</TableCell>
                                            <TableCell className="text-center">{wrapWithTooltip(dictionary["Analysis"]?.[currentLanguage])}</TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {items.map((item, index) => (
                                            <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                              <TableCell>{wrapWithTooltip(item.dasa)}</TableCell>
                                              <TableCell>{wrapWithTooltip(item.apahara)}</TableCell>
                                              <TableCell>{wrapWithTooltip(item.period_start)}</TableCell>
                                              <TableCell>{wrapWithTooltip(item.period_end)}</TableCell>
                                              <TableCell>
                                                <span
                                                  className={`badge w-100 ${
                                                    item.analysis === "Not Favorable" || item.analysis === "अनुकूल नहीं"
                                                      ? "bg-inverse-danger"
                                                      : item.analysis === "Favorable" || item.analysis === "अनुकूल"
                                                      ? "bg-inverse-success"
                                                      : item.analysis === "Excellent" || item.analysis === "उत्कृष्ट"
                                                      ? "bg-inverse-purple"
                                                      : "bg-inverse-info"
                                                  }`}
                                                >
                                                  {wrapWithTooltip(item.analysis)}
                                                </span>
                                              </TableCell>
                                            </TableRow>
                                          ))}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  </div>
                                </div>
                              </div>
                            )
                        )}
                      {reportHeading === "Paryanthar Dasa" &&
                        planetaryInfluencesLoaded &&
                        reportItem &&
                        Object.entries(reportItem).map(
                          ([category, items]) =>
                            items?.length > 0 && (
                              <div className="dasha_wrapper" key={category}>
                                <div className="dasha_headline">
                                  <div className="report_card_title mb-0">
                                    <h3>{wrapWithTooltip(dictionary[category]?.[currentLanguage])}</h3>
                                  </div>
                                </div>
                                <div className="dasha_listing">
                                  {items.map((item, index) => (
                                    <div className="dasha_box" key={index}>
                                      <div className="dasha-flex">
                                        <div className="dasha_head">
                                          <div className="dosha_head_name">
                                            <span className="dosha_value_name">{dictionary["Name"]?.[currentLanguage]}</span>
                                            <span>{wrapWithTooltip(item.name)}</span>
                                          </div>

                                          <div className="dosha_head_duration">
                                            <span className="dosha_value_duration">{dictionary["Duration"]?.[currentLanguage]}</span>
                                            <span>{wrapWithTooltip(item.duration)}</span>
                                          </div>
                                        </div>
                                        <div className="dosha_body">
                                          <div className="dosha_Prediction">
                                            <span className="dosha_value_Prediction">{dictionary["Prediction"]?.[currentLanguage]}</span>
                                            <span>{wrapWithTooltip(item.prediction)}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )
                        )}
                      {reportHeading === "Yoga Analysis" &&
                        reportItem?.length > 0 &&
                        reportItem.map((data, index) => (
                          <div className="report_section_card" key={index}>
                            <div className="report_card_title">
                              <h3>{wrapWithTooltip(dictionary[data.yoga]?.[currentLanguage] !== undefined ? dictionary[data.yoga]?.[currentLanguage] : data.yoga)}</h3>
                            </div>
                            <div className="report_card_content">
                              <p>{wrapWithTooltip(data.description)}</p>
                            </div>
                          </div>
                        ))}
                      {reportHeading === "Auspicious planetary combinations" &&
                        reportItem?.length > 0 &&
                        reportItem.map((data, index) => (
                          <div className="report_section_card" key={index}>
                            <div className="report_card_title">
                              <h3>{wrapWithTooltip(dictionary[data.combination]?.[currentLanguage] !== undefined ? dictionary[data.combination]?.[currentLanguage] : data.combination)}</h3>
                            </div>
                            <div className="report_card_content">
                              <p>{wrapWithTooltip(data.description)}</p>
                            </div>
                          </div>
                        ))}
                      {reportHeading === "Planetary Conditions" &&
                        reportItem?.length > 0 &&
                        reportItem.map((data, index) => (
                          <div className="report_section_card" key={index}>
                            <div className="report_card_title">
                              <h3>{wrapWithTooltip(dictionary[data.condition]?.[currentLanguage] !== undefined ? dictionary[data.condition]?.[currentLanguage] : data.condition)}</h3>
                            </div>
                            <div className="report_card_content">
                              <p>{wrapWithTooltip(data.description)}</p>
                            </div>
                          </div>
                        ))}
                      {reportHeading === "Graha dosham and Remedies" &&
                        reportItem &&
                        Object.entries(reportItem).map(([key, value]) => (
                          <div className="report_section_card" key={key}>
                            <div className="report_card_title">
                              <h3>{wrapWithTooltip(dictionary[key]?.[currentLanguage] !== undefined ? dictionary[key][currentLanguage] : key)}</h3>
                            </div>
                            <div className="report_card_content">
                              <p>{wrapWithTooltip(value.description)}</p>
                              {value.remedy && value.remedy !== "" && (
                                <div className="ramadies_content  ">
                                  <div className="ramadies_title">
                                    <h3>{dictionary["Remedies"]?.[currentLanguage]}</h3>
                                  </div>
                                  <p>{wrapWithTooltip(value.remedy)}</p>
                                </div>
                              )}
                            </div>
                            <div className="report_card_content"></div>
                            <div className="report_card_content"></div>
                          </div>
                        ))}
                      {reportHeading === "Remedies" && reportItem && (
                        <>
                          <div className="report_section_card">
                            <div className="report_card_title">
                              <h3>{wrapWithTooltip(dictionary["star_remedies"]?.[currentLanguage] !== undefined ? dictionary["star_remedies"]?.[currentLanguage] : "Star Remedies")}</h3>
                            </div>
                            <div className="report_card_content">
                              <p>{wrapWithTooltip(reportItem.star_remedies)}</p>
                            </div>
                          </div>
                          <div className="report_section_card">
                            <div className="report_card_title">
                              <h3>{wrapWithTooltip(dictionary["dasa_remedies"]?.[currentLanguage] !== undefined ? dictionary["dasa_remedies"]?.[currentLanguage] : "Dasha Remedies")}</h3>
                            </div>
                            <div className="report_card_content">
                              <p>{wrapWithTooltip(reportItem.dasa_remedies.introduction)}</p>
                            </div>
                            <div className="report_card_content">
                              <p>{wrapWithTooltip(reportItem.dasa_remedies.dasha_influences)}</p>
                            </div>
                            <div className="report_card_content">
                              <p>{wrapWithTooltip(reportItem.dasa_remedies.specific_challenges)}</p>
                            </div>
                            <div className="remedies_wrapper">
                              <div className="dasha_headline">
                                <div className="report_card_title mb-0">
                                  <h3>
                                    {wrapWithTooltip(dictionary["remedial_measures"]?.[currentLanguage] !== undefined ? dictionary["remedial_measures"]?.[currentLanguage] : "Remedial Measures")}
                                  </h3>
                                </div>
                              </div>
                              <div className="ramadies_listing">
                                {Object.entries(reportItem.dasa_remedies.remedial_measures).map(([key, value]) => (
                                  <div className="ramadies_content  ">
                                    <div className="ramadies_flex_box">
                                      <div className="tech-icon  ">
                                        <img src={imageMap[key]} alt="" />
                                      </div>
                                      <div className="ramadies_description">
                                        <div className="ramadies_title">
                                          <h3>{wrapWithTooltip(dictionary[key]?.[currentLanguage] !== undefined ? dictionary[key][currentLanguage] : key)}</h3>
                                        </div>
                                        <p>{wrapWithTooltip(value)}</p>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                                <div className="ramadies_content ramadies_duration">
                                  <div className="ramadies_flex_box">
                                    <div className="ramadies_description">
                                      <div className="ramadies_title">
                                        <h3>{dictionary["Duration"]?.[currentLanguage]}</h3>
                                      </div>
                                      <p>{wrapWithTooltip(reportItem.dasa_remedies.duration)}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      {reportHeading === "Transit forecast" &&
                        planetaryInfluencesLoaded &&
                        reportItem &&
                        Object.entries(reportItem).map(
                          ([category, items]) =>
                            items.length > 0 && (
                              <div className="dasha_wrapper" key={category}>
                                <div className="dasha_headline">
                                  <div className="report_card_title mb-0">
                                    <h3>{wrapWithTooltip(dictionary[category]?.[currentLanguage])}</h3>
                                  </div>
                                </div>
                                <div className="dasha_listing">
                                  {items.map((item, index) => (
                                    <div className="dasha_box" key={index}>
                                      <div className="dasha-flex">
                                        <div className="dasha_head">
                                          <div className="dosha_head_name">
                                            <span className="dosha_value_name">{dictionary["House"]?.[currentLanguage]}</span>
                                            <span>{wrapWithTooltip(item.house)}</span>
                                          </div>

                                          <div className="dosha_head_duration">
                                            <span className="dosha_value_duration">{dictionary["Duration"]?.[currentLanguage]}</span>
                                            <span>{wrapWithTooltip(item.duration)}</span>
                                          </div>
                                        </div>
                                        <div className="dosha_body">
                                          <div className="dosha_Prediction">
                                            <span className="dosha_value_Prediction">{dictionary["Effects and Influences"]?.[currentLanguage]}</span>
                                            <span>{wrapWithTooltip(item.effects_influence)}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )
                        )}
                      {reportHeading === "Lucky Colors and Gemstones" && reportItem && (
                        <>
                          <div className="report_section_card">
                            <div className="report_card_title">
                              <h3>{wrapWithTooltip(dictionary["colors"]?.[currentLanguage] !== undefined ? dictionary["colors"][currentLanguage] : "Colors")}</h3>
                            </div>
                            <div className="remedies_wrapper ">
                              <div className="ramadies_listing">
                                {reportItem.colors.map((data, index) => (
                                  <div className="ramadies_content m-0 " key={index}>
                                    <div className="ramadies_flex_box">
                                      <div className="tech-icon  ">
                                        <img src={colour} alt="" />
                                      </div>
                                      <div className="ramadies_description">
                                        <div className="ramadies_title">
                                          <h3>{wrapWithTooltip(dictionary[data.color] !== undefined ? dictionary[data.color] : data.color)}</h3>
                                        </div>
                                        <p>{wrapWithTooltip(data.description)}</p>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                          <div className="report_section_card">
                            <div className="report_card_title">
                              <h3>{wrapWithTooltip(dictionary["gemstones"]?.[currentLanguage] !== undefined ? dictionary["gemstones"][currentLanguage] : "Gemstones")}</h3>
                            </div>
                            <div className="remedies_wrapper ">
                              <div className="ramadies_listing">
                                {reportItem.gemstones.map((data, index) => (
                                  <div className="ramadies_content m-0 " key={index}>
                                    <div className="ramadies_flex_box">
                                      <div className="tech-icon  ">
                                        <img src={Gemstones} alt="" />
                                      </div>
                                      <div className="ramadies_description">
                                        <div className="ramadies_title">
                                          <h3>{wrapWithTooltip(dictionary[data.gemstone] !== undefined ? dictionary[data.gemstone] : data.gemstone)}</h3>
                                        </div>
                                        <p>{wrapWithTooltip(data.description)}</p>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      {reportHeading === "Celebrity Comparisons" &&
                        reportItem?.length > 0 &&
                        reportItem.map((data, index) => (
                          <div className="report_section_card" key={index}>
                            <div className="report_card_title">
                              <h3>{wrapWithTooltip(data.celebrity)}</h3>
                            </div>
                            <div className="remedies_wrapper ">
                              <div className="ramadies_listing">
                                <div className="ramadies_content m-0 ">
                                  <div className="ramadies_flex_box">
                                    <div className="ramadies_description">
                                      <div className="ramadies_title">
                                        <h3>{dictionary["astrological_traits"]?.[currentLanguage]}</h3>
                                      </div>
                                      <p>{wrapWithTooltip(data.astrological_traits)}</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="ramadies_content m-0 ">
                                  <div className="ramadies_flex_box">
                                    <div className="ramadies_description">
                                      <div className="ramadies_title">
                                        <h3>{dictionary["comparison"]?.[currentLanguage]}</h3>
                                      </div>
                                      <p>{wrapWithTooltip(data.comparison)}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={openpopover}
        anchorEl={anchorEl}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div className="popover_content">
          <div className="popover_flex">
            <div className="popover_pera">
              <span>{popoverContent?.description ? popoverContent.description : ""}</span>
            </div>
            <ImageComponent
              // src={popoverimage}
              src={popoverContent?.image}
              alt="Recommendations"
            />
          </div>
        </div>
      </Popover>
    </div>
  );
};
