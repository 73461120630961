import React from 'react'
import ContentLoader from 'react-content-loader'

const CardContentLoader = ({ ...props }) => (
  <ContentLoader speed={1} foregroundColor="#ffd3a8" viewBox="0 0 800 90" height="100%" width="100%" {...props}   >
    <rect x="0" y="13" rx="4" ry="4" width="100%" height="5" />
    <rect x="0" y="29" rx="4" ry="4" width="100%" height="5" />
    <rect x="0" y="50" rx="4" ry="4" width="100%" height="5" />
    <rect x="0" y="65" rx="4" ry="4" width="100%" height="5" />
    <rect x="0" y="79" rx="4" ry="4" width="100%" height="5" />
    
  </ContentLoader>
)

CardContentLoader.metadata = {
  name: 'Didier Munezero',
  github: 'didiermunezero',
  description: 'Grid for content of head and body',
  filename: 'CardContentLoader',
}

export default CardContentLoader