import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import googleicon from "../assets/images/google-icon.svg";
import { TextField, InputAdornment, IconButton, OutlinedInput, FormControl, Tooltip, Zoom, CircularProgress } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LoginSidebar from "../components/login-slider";
import host from "../host";
import LoadingButton from "@mui/lab/LoadingButton";
import logo from "../assets/images/logo.png";
import { useGoogleLogin } from "@react-oauth/google";
import { changeAuth } from "../actions/loginAction";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
export const Login = () => {
  const auth = localStorage.getItem("login") === "true";
  let topic;

  const [redirectUrl, setRedirectUrl] = useState(null);

  useEffect(() => {
    document.documentElement.setAttribute("data-theme", "dark");
    const urlParams = new URLSearchParams(window.location.search);
    const topic = urlParams.get("topic");
    const redirect = urlParams.get("redirectUrl");
    localStorage.setItem("topic", topic);
    if (redirect) {
      setRedirectUrl(redirect);
    }
  }, []);

  useEffect(() => {
    if (auth) {
      if ((localStorage.getItem("topic") && localStorage.getItem("topic") !== "null") || topic) {
        // if topic is set in local storage then navigate to new-astro-chatbot
        return;
      }
      const urlParams = new URLSearchParams(window.location.search);
      const redirect = urlParams.get("redirectUrl");
      if (redirect) {
        return;
      }
      navigate("/dashboard");
    }
  }, [auth]);

  //login functionality begins
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [googleLoading, setGoogleLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
  });

  const login = () => {
    setLoading(true);
    fetch(host.astro_backend + "/user/login", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: formik.values.email,
        password: formik.values.password,
      }),
    })
      .then(async (res) => {
        if (!res.ok) {
          const message = await res.json();
          setMessage(message?.detail);
          setLoading(false);
        } else {
          const data = await res.json();
          dispatch(changeAuth(true, data.token));
          setLoading(false);
          if (
            localStorage.getItem("topic") === "none" ||
            localStorage.getItem("topic") === "Family" ||
            localStorage.getItem("topic") === "Love" ||
            localStorage.getItem("topic") === "Career" ||
            localStorage.getItem("topic") === "Business" ||
            localStorage.getItem("topic") === "Finance" ||
            localStorage.getItem("topic") === "Marriage"
          ) {
            navigate("/new-astro-chatbot");
          } else if (redirectUrl) {
            navigate(redirectUrl);
          } else {
            navigate("/dashboard");
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  //google login starts
  const googleLogin = (tokenResponse) => {
    setMessage("");
    setGoogleLoading(true);
    fetch(host.astro_backend + "/user/google", {
      method: "POST",
      body: JSON.stringify({ credential: tokenResponse }),
      headers: {
        "Content-type": "application/json",
      },
    })
      .then(async (res) => {
        if (res.status === 401) {
          return res.json().then((data) => {
            throw new Error(data.message);
          });
        } else if (res.status === 403) {
          return res.json().then((data) => {
            setGoogleLoading(false);
            setMessage(data.detail ? data.detail : "Something went wrong");
          });
        } else if (res.status === 400) {
          return res.json().then((data) => {
            setGoogleLoading(false);
            setMessage(data.detail ? data.detail : "Something went wrong");
          });
        } else if (res.status === 500) {
          return res.json().then((data) => {
            setGoogleLoading(false);
            setMessage(data.detail ? data.detail : "Something went wrong");
          });
        } else {
          return res.json();
        }
      })
      .then(async (data) => {
        setGoogleLoading(false);
        if (data.success) {
          if (data.user?.is_profile_complete === true) {
            dispatch(changeAuth(true, data.token));
            if (redirectUrl) {
              navigate(redirectUrl);
            } else {
              navigate("/dashboard");
            }
          } else if (data.user?.is_profile_complete === false) {
            dispatch(changeAuth(false, data.token));
            localStorage.setItem("fullname", data.user?.fullname);
            setGoogleLoading(false);
            navigate("/googlelogin");
          } else {
            setMessage("something went wrong");
          }
        } else {
          setGoogleLoading(false);
          setMessage(data.message);
        }
      })
      .catch((err) => {
        setGoogleLoading(false);
        setLoading(false);
        console.log(err);
      });
  };

  const loginGoogle = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      //console.log(tokenResponse)
      googleLogin(tokenResponse);
    },
    // scope: 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/user.gender.read https://www.googleapis.com/auth/user.birthday.read',
  });
  //google login ends

  return (
    <div className="bglogin">
      {/* 
   <LoginHeaderPage/>
   */}
      <div className="login_wrapper">
        <div className="login_flex">
          <div className="login_left">
            <div className="login_left_content">
              <LoginSidebar />
            </div>
          </div>
          <div className="login_right">
            <div className="login_right_content">
              <div className="auth-full-page-content justify-content-center ">
                <div className="form-width login-width ">
                  <div className="d-flex  h-100 ">
                    <div className="auth-content my-auto logincorporate  ">
                      <div className="login_logo text-center mb-2">
                        <Link to={"/"}>
                          <img className=" " src={logo} height={50} />
                        </Link>
                      </div>
                      <div className="mt-4 mb-2">
                          <Link
                            to="#"
                            className="  btn-google w-100"
                            onClick={(e) => {
                              e.preventDefault();
                              loginGoogle();
                            }}
                          >
                            <span className="">
                              {" "}
                              <img src={googleicon} alt="" />{" "}
                            </span>{" "}
                            Continue with Google{"   "}
                            {googleLoading? (<CircularProgress size={30}/>):""}
                          </Link>
                      </div>
                      <div className="d-flex align-items-center mt-3  ">
                        <hr className="flex-grow-1 bg-dark-4" />
                        <span className="mx-2 text-2 text-muted">or connect with </span>
                        <hr className="flex-grow-1 bg-dark-4" />
                      </div>
                      <form className=" mt-2" action="index.html">
                        <div className="welcometext  ">
                          {/* <h5 className="mb-0">AstroGuruji AI</h5> */}
                          <p className="text-muted mt-2 ">Log In to get your AI Astro Assistant</p>
                        </div>
                        <div className="row mt-5">
                          <div className="col-xxl-12 col-lg-12 col-md-12 col-12">
                            <div className="form-group">
                              <label className="form-label">
                                Email <span className="text-danger">*</span>
                              </label>
                              <TextField
                                type="text"
                                name="email"
                                placeholder="Enter business email"
                                disabled={loading}
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                              />
                            </div>
                          </div>
                          <div className="col-xxl-12 col-lg-12 col-md-12">
                            <div className="form-group">
                              <div className="d-flex align-items-start">
                                <div className="flex-grow-1">
                                  <label className="form-label">
                                    Password <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="flex-shrink-0">
                                  <div className="forgotpass">
                                    <Link to="/forgot-password" className=" ">
                                      Forgot password?
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <div className=" auth-pass-inputgroup">
                                <FormControl fullwidth variant="standard">
                                  <OutlinedInput
                                    name="password"
                                    placeholder="Enter password"
                                    type={showPassword ? "text" : "password"}
                                    disabled={loading}
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                                          {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                      </InputAdornment>
                                    }
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                    helperText={formik.touched.password && formik.errors.password}
                                  />
                                </FormControl>
                              </div>
                            </div>
                          </div>
                          <div className="col-xxl-12 col-lg-12 col-md-12 mt-3">
                            <div className="form-group">
                              <LoadingButton
                                style={{ textTransform: "capitalize" }}
                                disabled={!formik.isValid || formik.values.email === "" || formik.values.password === ""}
                                type="button"
                                variant="contained"
                                className="w-100  "
                                onClick={(e) => {
                                  e.preventDefault();
                                  login();
                                }}
                                controlled={true}
                                loading={loading}
                              >
                                Log In
                              </LoadingButton>
                            </div>
                          </div>
                        </div>
                      </form>
                      <div className="  text-center notmember">
                        <p className="text-muted mb-0">
                          Not a member?{" "}
                          <Link to={redirectUrl ? `/signup?redirectUrl=${redirectUrl}` : "/signup"} className="fw-semibold">
                            {" "}
                            Sign up here{" "}
                          </Link>
                        </p>
                        <div
                          style={{
                            color: "red",
                            fontWeight: "500",
                            marginTop: 5 + "%",
                          }}
                        >
                          {message}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
