import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { changeAuth } from "../actions/loginAction";
import { useDispatch } from "react-redux";
import HeaderPage from "../_layout/elements/header";
import FooterPage from "../_layout/elements/footer";
import { TextField, FormControl, FormGroup, FormControlLabel } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import PageLoader from "../components/page-loader";
import useRazorpay from "react-razorpay";
import host from "../host";
import MessageSnackbar from "../_layout/elements/snackbar";
import ContentLoader from "../components/loader/content-loader";
import { Switch } from "@mui/material";
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";

export const SubscriptionPayment = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = localStorage.getItem("login") === "true";

  useEffect(() => {
    if (!auth) {
      navigate("/logout");
    }
  }, [auth]);

  const [severity, setSeverity] = useState("success");
  const [snackbar, setSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const onClose = () => {
    setSnackbar(false);
  };

  const [loading, setLoading] = useState(false);

  const [plans, setPlans] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [Razorpay] = useRazorpay();

  const [profile, setProfile] = useState(null);
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState("");
  const [latLng, setLatLng] = useState({ lat: null, lng: null }); // Default lat/lng
  const [checkedSaveAddress, setCheckedSaveAddress] = useState(true);

  const getProfile = () => {
    setIsLoaded(false);
    fetch(host.astro_backend + `/user`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 403) {
          dispatch(changeAuth(false, null));
          navigate("/login");
        }
      })
      .then((data) => {
        if (data) {
          setProfile(data);
          setIsLoaded(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoaded(true);
      });
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Please enter your full name"),
    billing_address: Yup.string().required("Please enter your billing address"),
    pinCode: Yup.string()
      .required("Pin Code is required")
      .matches(/^[0-9]{6}$/, "Pin Code must be exactly 6 digits"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      billing_address: "",
      pinCode: "",
      country: "0",
      state: "0",
      city: "0",
    },
    validationSchema: validationSchema,
  });

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handlePlaceChanged = () => {
    const places = inputRef.current.getPlaces();
    if (places && places.length > 0) {
      const place = places[0];
      setLatLng({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
      setInputValue(place.formatted_address);
    }
  };

  const getPlans = () => {
    setIsLoaded(false);
    fetch(host.astro_backend + `/plans/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 403) {
          dispatch(changeAuth(false, null));
          navigate("/logout");
        } else {
          setIsLoaded(true);
          setSeverity("error");
          setMessage("Something went wrong");
          setSnackbar(true);
        }
      })
      .then((data) => {
        if (data) {
          setPlans(data);
          setIsLoaded(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoaded(true);
      });
  };

  useEffect(() => {
    if (id) {
      getProfile();
      getPlans();
    }
  }, []);

  const handlePaymentFail = (paymentResponse) => {
    fetch(
      host.astro_backend +
        `/payment/payment_failure?order_id=${paymentResponse.error.metadata.order_id}&payment_id=${paymentResponse.error.metadata.payment_id}&error_code=${paymentResponse.error.code}&error_description=${paymentResponse.error.description}&error_reason=${paymentResponse.error.reason}`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
      .then((res) => {
        setLoading(false);
        return res.json();
      })
      .then((data) => {
        if (data) {
          setSeverity("error");
          setMessage("Payment failed!");
          setSnackbar(true);
          // navigate(`/subscription-payment-notapprove/${data.transaction.id}`);
        } else {
          console.log("else");
        }
      })
      .catch((_err) => {
        setLoading(false);
      });
  };

  const makeCheck = async (orderId) => {
    fetch(`${host.astro_backend}/payment/check_payment?order_id=${orderId}`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data) {
          console.log(data);
          setSeverity("success");
          setMessage(plans?.plan_details?.plan_name === "Premium" ? "Payment successful, Report is being generated." : "Payment was successful.");
          setSnackbar(true);
          setTimeout(() => {
            navigate("/dashboard");
          }, 3000);
        } else {
          console.log("Data failed");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const makePayment = async (razorpayData) => {
    const options = {
      key: host.razorpayKey,
      order_id: razorpayData.id,
      name: "NaviVibe",
      amount: razorpayData.amount,
      currency: razorpayData.currency,
      prefill: {
        //We recommend using the prefill parameter to auto-fill customer's contact information especially their phone number
        name: formik.values.name, //your customer's name
        email: profile?.email ? profile.email : null, //your customer's email
        contact: profile?.phone_number ? profile.phone_number : null,
      },
      theme: {
        color: "#f16122",
      },
      handler: async function (response) {
        fetch(host.astro_backend + "/payment/verify_payment", {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: JSON.stringify(response),
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            if (data) {
              // navigate(`/subscription-payment-approve/${data.transaction.id}`);
              makeCheck(razorpayData.id);
              // activepaymentstatus();
            }
          })
          .catch((err) => {
            console.log(err);
            console.error("Error handling payment by server:", err);
          });
      },
    };

    // Initialize Razorpay instance and open payment popup
    const rzp = new Razorpay(options);
    rzp.open();

    rzp.on("payment.failed", (paymentResponse) => {
      handlePaymentFail(paymentResponse);
      // navigate("/subscription-payment-notapprove");
      rzp.close();
    });
  };

  const makeOrder = async () => {
    if (formik.values.name === "" || formik.values.billing_address === "" || formik.values.pinCode === "" || inputValue === "") {
      setSeverity("error");
      setMessage("Please fill all the fields");
      setSnackbar(true);
      return;
    } else {
      fetch(host.astro_backend + "/payment/order", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify({
          company_name: formik.values.name,
          billing_address: formik.values.billing_address,
          pincode: formik.values.pinCode,
          city: inputValue,
          plan_id: id,
          save_address: checkedSaveAddress,
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data) {
            makePayment(data);
          } else {
            setSeverity("error");
            setMessage(data.message);
            setSnackbar(true);
          }
        })
        .catch((err) => {
          console.log(err);
          setSeverity("error");
          setMessage("Something went wrong !");
          setSnackbar(true);
        });
    }
  };

  useEffect(() => {
    if (profile && plans) {
      if (profile?.fullname) {
        formik.setFieldValue("name", profile.fullname);
      }
      if (plans.saved_address?.billing_address !== null && plans.saved_address?.billing_address !== undefined && plans.saved_address?.billing_address !== "") {
        formik.setFieldValue("billing_address", plans.saved_address.billing_address);
      }
      if (plans.saved_address?.pincode !== null && plans.saved_address?.pincode !== undefined && plans.saved_address?.pincode !== "") {
        formik.setFieldValue("pinCode", plans.saved_address.pincode);
      }
      if (plans.saved_address?.city) {
        setInputValue(plans.saved_address.city);
      } else if (profile.city) {
        setInputValue(profile.city);
      }
      setLatLng({
        lat: profile.lat,
        lng: profile.long,
      });
    }
  }, [profile, plans]);

  return (
    <div>
      <MessageSnackbar open={snackbar} autoHideDuration={3000} onClose={onClose} severity={severity} message={message} />
      <div className="inner-header ">
        <HeaderPage />
      </div>
      <section className="  payment-details    ">
        <PageLoader />
        <div className="container">
          <div className="row">
            <div className="payment-head">
              <h2>Subscription Purchase</h2>
            </div>
            <div className="col-sm-8 ">
              <div className=" card h-100 ">
                <div className="card-header">
                  <div className="Orderid">
                    <h5>
                      <b>Billing Information</b>
                    </h5>
                  </div>
                </div>
                <div className=" card-body ">
                  <div className="row  ">
                    <div className="col-md-6">
                      <div className="form-group">
                        <FormControl fullWidth>
                          <TextField
                            id="outlined-basic"
                            label="User Name"
                            name="name"
                            placeholder="Enter your name"
                            variant="outlined"
                            disabled={loading}
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                          />
                        </FormControl>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <FormControl fullWidth>
                          <LoadScript googleMapsApiKey={host.api_key} libraries={["places"]}>
                            <StandaloneSearchBox onLoad={(ref) => (inputRef.current = ref)} onPlacesChanged={handlePlaceChanged}>
                              <div className="worldwidephone locationinput">
                                <TextField
                                  id="outlined-basic"
                                  type="text"
                                  label="Location"
                                  placeholder="Enter Location"
                                  name="pinCode"
                                  variant="outlined"
                                  value={inputValue}
                                  onChange={handleInputChange}
                                />

                                {/* <input size="small" type="text" placeholder="Enter location" className="PhoneInputInput searchBoxInput" aria-label="Location" value={inputValue} onChange={handleInputChange}/> */}
                              </div>
                            </StandaloneSearchBox>
                          </LoadScript>
                        </FormControl>
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div className="form-group">
                        <TextField
                          id="outlined-textarea"
                          label="Billing Address"
                          name="billing_address"
                          placeholder="Type your full address"
                          disabled={loading}
                          value={formik.values.billing_address}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={formik.touched.billing_address && Boolean(formik.errors.billing_address)}
                          helperText={formik.touched.billing_address && formik.errors.billing_address}
                          multiline
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <FormControl fullWidth>
                          <TextField
                            id="outlined-basic"
                            type="number"
                            label="PIN/ZIP Code"
                            placeholder="Enter PIN/ZIP Code"
                            name="pinCode"
                            variant="outlined"
                            value={formik.values.pinCode}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.pinCode && Boolean(formik.errors.pinCode)}
                          />
                          {formik.touched.pinCode && formik.errors.pinCode ? <div className="error text-danger mt-2">{formik.errors.pinCode}</div> : null}
                        </FormControl>
                      </div>
                    </div>

                    <div className="col-md-5">
                      <div className="form-group">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                defaultChecked
                                checked={checkedSaveAddress}
                                onChange={(event) => setCheckedSaveAddress(event.target.checked)}
                                color="primary"
                                name="checkedB"
                                inputProps={{ "aria-label": "primary checkbox" }}
                              />
                            }
                            label="Save Address Details"
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-4 ">
              <div className="ordersummery ">
                <div className="card">
                  <div className="card-header">
                    <div className="Orderid">
                      <h5>
                        <b>Order Summary</b>
                      </h5>
                    </div>
                  </div>
                  {isLoaded ? (
                    <div className="card-body">
                      <div className="summary">
                        <div className="summary-container">
                          <div className="summary-line">
                            <span className="summary-flex-1">
                              {" "}
                              <b>Plan name</b>{" "}
                            </span>
                            <span className="summary-flex-3">
                              <span className="badge Standard">{plans?.plan_details?.plan_name || "NA"}</span>
                            </span>
                          </div>

                          <div className="summary-line">
                            <span className="summary-flex-1">
                              {" "}
                              <b>Sub Total</b>{" "}
                            </span>

                            <span className="summary-flex-3">
                              {" "}
                              <i className="las la-rupee-sign" />
                              {plans?.plan_details?.amount || "NA"}
                            </span>
                          </div>

                          <div className="summary-line gst">
                            <span className="summary-flex-1">
                              {" "}
                              <b>GST</b>{" "}
                            </span>

                            <span className="summary-flex-3"> {plans?.plan_details?.gst_amount || "NA"}</span>
                          </div>
                          <div className="summary-foot">
                            <span className="total-cost">Grand Total</span>
                            <span className="total-summary-amount">
                              <i className="las la-rupee-sign" /> {plans?.plan_details?.total_amount || "NA"}
                            </span>
                          </div>
                          <div className="summary-btn ">
                            <Link to="#">
                              <button
                                type="button"
                                className="btn  btn-primary w-100"
                                onClick={() => {
                                  makeOrder();
                                }}
                              >
                                {" "}
                                Proceed to payment
                              </button>{" "}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <ContentLoader />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterPage />
    </div>
  );
};
