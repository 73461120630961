import React from "react";
import ContentLoader from "react-content-loader";

const ZodicSignLoader = (props) => {
  return (
    <ContentLoader speed={1} foregroundColor="#ffd3a8" viewBox="50 0 500 200" {...props}>
      <circle cx="230" cy="80" r="60" />
      <rect x="160" y="150" rx="0" ry="0" width="140" height="15" />
      <circle cx="390" cy="80" r="60" />
      <rect x="320" y="150" rx="0" ry="0" width="140" height="15" />
    </ContentLoader>
  );
};

ZodicSignLoader.metadata = {
  name: "baptiste fkt",
  github: "baptistefkt",
  description: "Three column grid layout",
  filename: "ZodicSignLoader",
};

export default ZodicSignLoader;
