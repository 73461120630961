import React, { useState  } from 'react'; 
import HeaderPage from '../_layout/elements/header';
import FooterPage from '../_layout/elements/footer';
import {  List ,ListItemButton,ListItemText,Collapse,Breadcrumbs,Typography,Tooltip,Zoom} from '@mui/material'; 
import ss1 from "../assets/images/Screenshot.png";
import createsk from "../assets/images/create_sk.png";
import createskdone from "../assets/images/create_skdone.png";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Assessmentapi from "../assets/images/AssessmentAPI.jpeg";
 
import MenuIcon from '@mui/icons-material/Menu';
export const BlogDetail = () => {
const [open, setOpen] = React.useState(true);
const handleClick = () => {setOpen(!open);};
const [open2, setOpen2] = React.useState(false);
const handleClick2 = () => {setOpen2(!open2);};
const [open3, setOpen3] = React.useState(false);
const handleClick3 = () => {setOpen3(!open3);};
const [open4, setOpen4] = React.useState(false);
const handleClick4 = () => {setOpen4(!open4);};
const [open5, setOpen5] = React.useState(false);
const handleClick5 = () => {setOpen5(!open5);};
const codeString = '(num) => num + 1';
const [isClassAdded, setIsClassAdded] = useState(false);
const handleButtonClick = () => {
setIsClassAdded((prevIsClassAdded) => !prevIsClassAdded);
};
const scrollToSection = (sectionId) => {
const section = document.getElementById(sectionId);
if (section) {
section.scrollIntoView({ behavior: 'smooth', block: 'start' });
}
};
return (
<div>
   <div className='inner-header'>
      <HeaderPage />
   </div>
   {/* <section className='padding_medium  minibanner  blog-banner'  >
      <div className='container'>
         <div className='row align-items-center justify-content-between'>
            <div className='col-md-12'>
               <div className='minibanner-content'>
                  <h2>Blog </h2>
                  <div className='blog-breadcrumbs'>
                     <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" href="/home">
                        MUI
                        </Link>
                        <Link
                           underline="hover"
                           color="inherit"
                           href="/material-ui/getting-started/installation/"
                           >
                        Core
                        </Link>
                        <Typography color="text.primary">Breadcrumbs</Typography>
                     </Breadcrumbs>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section> */}
   <section className='blog-section' id="blog">
      <div className='container'>
         <div className='row '>
            <div className='col-md-12 '>
               <div className='blog-menu'>
                  <Link to="" onClick={handleButtonClick}>
                  <MenuIcon />
                  </Link>
               </div>
            </div>
            <div className='col-md-12 '>
               <div className='blog-wrapper'>
                  <div className='blog-flex'>
                     <div   className={`blog-sidebar ${isClassAdded ? 'additional-class' : ''}`}> 

                   
                     <div className='blog-aside'>

                     <Link to="" className='close closeblog' onClick={handleButtonClick}>X</Link>
                        <List
                           component="nav"
                           aria-labelledby="nested-list-subheader"
                           >
                           <ListItemButton onClick={handleClick}>
                              <ListItemText primary="Getting Started" />
                              {open ? 
                              <ExpandLess />
                              : 
                              <ExpandMore />
                              }
                           </ListItemButton>
                           <Collapse in={open} timeout="auto" unmountOnExit>
                              <List component="div" disablePadding>
                                 <ListItemButton sx={{ pl: 4 }}   onClick={() => scrollToSection('Interduction')} >
                                 <ListItemText primary="Interduction" />
                                 </ListItemButton>
                              </List>
                           </Collapse>
                           <ListItemButton onClick={handleClick2}>
                              <ListItemText primary="Key Generation" />
                              {open2 ? 
                              <ExpandLess />
                              : 
                              <ExpandMore />
                              }
                           </ListItemButton>
                           <Collapse in={open2} timeout="auto" unmountOnExit>
                              <List component="div" disablePadding>
                                 <ListItemButton sx={{ pl: 4 }}   onClick={() => scrollToSection('FetchXaccesskey')} >
                                 <ListItemText primary="Fetch your X-access key" />
                                 </ListItemButton>
                              </List>
                           </Collapse>
                           <ListItemButton onClick={handleClick3}>
                              <ListItemText primary="Creating" />
                              {open3 ? 
                              <ExpandLess />
                              : 
                              <ExpandMore />
                              }
                           </ListItemButton>
                           <Collapse in={open3} timeout="auto" unmountOnExit>
                              <List component="div" disablePadding>
                                 <ListItemButton sx={{ pl: 4 }}   onClick={() => scrollToSection('Assesment')} >
                                 <ListItemText primary="Assesment" />
                                 </ListItemButton>
                                 <ListItemButton sx={{ pl: 4 }}   onClick={() => scrollToSection('Section')} >
                                 <ListItemText primary="Section" />
                                 </ListItemButton>
                                 <ListItemButton sx={{ pl: 4 }}   onClick={() => scrollToSection('Question')} >
                                 <ListItemText primary="Question" />
                                 </ListItemButton>
                              </List>
                           </Collapse>
                           <ListItemButton onClick={handleClick4}>
                              <ListItemText primary="Invites" />
                              {open4 ? 
                              <ExpandLess />
                              : 
                              <ExpandMore />
                              }
                           </ListItemButton>
                           <Collapse in={open4} timeout="auto" unmountOnExit>
                              <List component="div" disablePadding>
                                 <ListItemButton sx={{ pl: 4 }}   onClick={() => scrollToSection('SendInvites')} >
                                 <ListItemText primary="Send Invites" />
                                 </ListItemButton>
                              </List>
                           </Collapse>
                           <ListItemButton onClick={handleClick5}>
                              <ListItemText primary="Results" />
                              {open5 ? 
                              <ExpandLess />
                              : 
                              <ExpandMore />
                              }
                           </ListItemButton>
                           <Collapse in={open5} timeout="auto" unmountOnExit>
                              <List component="div" disablePadding>
                                 <ListItemButton sx={{ pl: 4 }}   onClick={() => scrollToSection('Resultcandidate')} >
                                 <ListItemText primary="Result of candidate" />
                                 </ListItemButton>
                                 <ListItemButton sx={{ pl: 4 }}   onClick={() => scrollToSection('Resultalltests')} >
                                 <ListItemText primary="Result of all tests" />
                                 </ListItemButton>
                              </List>
                           </Collapse>
                        </List>
                     </div>
                  </div>
                  <div className='blog-content'>


                  <section className='blog-section blog_section_pedding pb-0' id=" ">

<h1 className='mb-0'> Streamlining Evaluations: A Step-by-Step Tour of Assessment API</h1>

<img src={Assessmentapi} alt='' className='w-100' />
</section>


                     <section className='blog-section blog_section_pedding' id="Interduction">
                        <h2>Introduction</h2>
                        <p>Imagine a world where choosing the perfect candidate for a job is like finding the right piece for a puzzle. Think about a school identifying the best students for its programs, or a workplace helping its employees grow. This magic happens with assessments – a kind of tool that makes sure everything fits just right. Today, we're diving into how these assessments work their magic in hiring, education, and workplaces. They're not just tests; they're the friendly wizards making sure everyone is in the right place and growing in the best way possible. Join us as we uncover how these assessments are the secret ingredients behind successful stories in the real world.</p>
                     </section>
                     <section className='blog-section blog_section_pedding' id="FetchXaccesskey">
                        <h2>Fetching your Personal Access Key: </h2>
                        <h3>What is the X-access-key?</h3>
                        <p>The X-access-key is like your digital signature, a distinct code that sets you apart. It's not just a key; it's your identifier, your way of saying, "Hey, I belong here!"</p>
                        <p>To initiate the utilisation of our API, you must obtain an <b>X-access-key</b>. Each Enterprise/Organisation user possessing a Skillwise for Owner account is provided with a distinctive X-access-key, accessible within their account settings. This key serves as a unique identifier and enables access to the API functionalities. The accompanying screenshot illustrates the process of locating and retrieving the X-access-key from your account.</p>
                        <h3>
                           Step 1 : Click on Create New Secret Key  button from   <Link to=""> here</Link> 
                        </h3>
                        <img src={ss1} alt="" />
                        <h3>Step 2 : Give the desired key name and click on <b>Create Secret Key</b> button  </h3>
                        <img src={createsk} alt="" />
                        <h3>Step 3 : Copy the key and store it in safe secured place as this key will not be displayed once you click on <b> Done</b> button  </h3>
                        <img src={createskdone} alt="" />
                        <p>You have the flexibility to revoke any number of personal access keys and generate additional ones as needed. There is no restriction on the quantity of personal access keys that you can generate. Owner can also modify and delete the keys. Feel free to create as many as necessary to suit your requirements.</p>
                     </section>
                     <section className='blog-section blog_section_pedding' id="Assesment">
                        <h2>Creating Assesment</h2>
                        <h3>Step 1 : Create Assessment</h3>
                        <h4>Purpose</h4>
                        <p>To create an assessment in your platform through an API request.</p>
                        <h4>What are assessments?</h4>
                        <p>Assessments enable you to evaluate systematically and analyse candidates during the hiring process. These assessments serve as valuable tools to identify an applicant's skills, competencies, and qualifications, helping your teams make informed hiring decisions. Not only hiring , assessments can also be used to evaluate candidate’s academic performances.
                        </p>
                        <h4>How to create assessments?</h4>
                        <div className='blog-list'>
                           <ul>
                              <li>To set up assessments, make sure you have a suitable 'test name' and a clear 'description' for the test.</li>
                              <li>Assessments can be broken down into different sections, with each section containing multiple questions. When creating a test, it is required to have at least one section, and within that section, there must be a minimum of one question.</li>
                              <li>For adding and editing specific sections and questions in the test, you can use dedicated section and question APIs, and we will provide further explanation on how to use them.</li>
                              <li>
                                 In addition to the essential elements like test name, description, section, and question, the other settings are optional. You can find more details in the parameters table for reference.
                              </li>
                           </ul>
                        </div>
                        <h4>Request URL</h4>
                        <SyntaxHighlighter language="javascript" style={docco}>
                           {codeString}
                        </SyntaxHighlighter>
                        <h4>Request Method</h4>
                        <span className='d-block mt-3 mb-1'>Post</span>
                        <div className='syntaxhiglighter'>
                           <div className='syntax-head'>
                              <div className='language-tabs'>
                                 <button type='button' className='btn btn-default'>  NodeJS</button>
                                 <button type='button' className='btn btn-default'>  Python</button>
                                 <div className='copy-code'>
                                    <Tooltip title="Copy  Code" TransitionComponent={Zoom} placement="top" arrow>
                                       <Link to="" className='text-dark'>
                                       <ContentCopyIcon fontSize='small'/>
                                       </Link>
                                    </Tooltip>
                                 </div>
                              </div>
                           </div>
                           <SyntaxHighlighter language="javascript" style={docco}>
                              {codeString}
                           </SyntaxHighlighter>
                   
                        </div>

                        <h4>Parameters</h4>
                           <div className='table-responsive'>
                              <table className='table table-bordered'>
                                 <thead>
                                    <tr>
                                       <th>Field Name</th>
                                       <th>Type</th>
                                       <th>Description</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    <tr>
                                       <td>test_name <span><sub className='text-danger'>*</sub></span></td>
                                       <td>String</td>
                                       <td>The name or title of the assessment.</td>
                                    </tr>
                                    <tr>
                                       <td>description <span><sub className='text-danger'>*</sub></span></td>
                                       <td>String</td>
                                       <td>A brief description of the assessment.</td>
                                    </tr>
                                    <tr>
                                       <td>pass_percentage <span><sub className='text-danger'>*</sub></span></td>
                                       <td>Integer</td>
                                       <td>The pass percentage required to pass the assessment.</td>
                                    </tr>
                                    <tr>
                                       <td>invite_mail <span><sub className='text-danger'>*</sub></span></td>
                                       <td>Object</td>
                                       <td>
                                          Email template for sending test invitations.
                                          <table className='table table-bordered mt-3'>
                                             <thead>
                                                <tr>
                                                   <th>Field Name</th>
                                                   <th>Description</th>
                                                </tr>
                                             </thead>
                                             <tbody>
                                                <tr>
                                                   <td>subject
                                                      (string)
                                                   </td>
                                                   <td>
                                                      Email subject for sending the test invitation.
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td>body
                                                      (string)
                                                   </td>
                                                   <td>
                                                      Email body for sending the test invitation.
                                                   </td>
                                                </tr>
                                             </tbody>
                                          </table>
                                       </td>
                                    </tr>
                                    <tr>
                                       <td>advanced_settings <span><sub className='text-danger'>*</sub></span></td>
                                       <td>Object</td>
                                       <td>
                                          Configure the advanced settings for the test, by default sets all to false. 
                                          <table className='table table-bordered mt-3'>
                                             <thead>
                                                <tr>
                                                   <th>Field Name</th>
                                                   <th>Description</th>
                                                </tr>
                                             </thead>
                                             <tbody>
                                                <tr>
                                                   <td> is_invite_only 
                                                      <small className='text-muted d-block'> (boolean)</small>
                                                   </td>
                                                   <td>
                                                      Indicates whether the assessment is invitation-only.
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td>is_candidate_result
                                                      <small className='text-muted d-block'>  (boolean) </small>
                                                   </td>
                                                   <td>
                                                      Specifies whether individual candidate results will be available.
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td> is_mail_result
                                                      <small className='text-muted d-block'>  (boolean) </small>
                                                   </td>
                                                   <td>
                                                      Indicates whether results will be sent via email.
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td> is_mobile_view
                                                      <small className='text-muted d-block'>    (boolean) </small>
                                                   </td>
                                                   <td>
                                                      Determines whether the assessment is optimised for mobile viewing.
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td> is_shuffle_questions
                                                      (boolean)
                                                   </td>
                                                   <td>
                                                      Indicates whether questions should be shuffled.
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td> is_shuffle_options
                                                      <small className='text-muted d-block'>  (boolean) </small>
                                                   </td>
                                                   <td>
                                                      Specifies whether answer options should be shuffled.
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td> is_essay_analysis
                                                      <small className='text-muted d-block'>   (boolean) </small>
                                                   </td>
                                                   <td>
                                                      Indicates whether essay analysis is enabled.
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td> is_video_analysis
                                                      <small className='text-muted d-block'>    (boolean) </small>
                                                   </td>
                                                   <td>
                                                      Specifies whether video analysis is enabled.
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td> start_time
                                                      <small className='text-muted d-block'>    (datetime | required, if is_duration is true) </small>
                                                   </td>
                                                   <td>
                                                      The start time of the assessment 
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td> end_time
                                                      <small className='text-muted d-block'>  (datetime | required, if is_duration is true) </small>
                                                   </td>
                                                   <td>
                                                      The end time of the assessment
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td> timezone
                                                      <small className='text-muted d-block'>  (string | required, if is_duration is true) </small>
                                                   </td>
                                                   <td>
                                                      The timezone in which the assessment is scheduled (e.g., "Asia/Kolkata").
                                                   </td>
                                                </tr>
                                             </tbody>
                                          </table>
                                       </td>
                                    </tr>
                                    <tr>
                                       <td>Proctor_settings  </td>
                                       <td>Object</td>
                                       <td>
                                          Email template for sending test invitations.
                                          <table className='table table-bordered mt-3'>
                                             <thead>
                                                <tr>
                                                   <th>Field Name</th>
                                                   <th>Description</th>
                                                </tr>
                                             </thead>
                                             <tbody>
                                                <tr>
                                                   <td>is_video_proctor
                                                      <small className='text-muted d-block'>   (boolean) </small>
                                                   </td>
                                                   <td>
                                                      Indicates whether video proctoring is enabled.
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td> is_copy_proctor
                                                      <small className='text-muted d-block'>   (boolean)</small>
                                                   </td>
                                                   <td>
                                                      Specifies whether copy proctoring is enabled.
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td> is_tab_proctor
                                                      <small className='text-muted d-block'>   (boolean) </small>
                                                   </td>
                                                   <td>
                                                      Indicates whether tab proctoring is enabled.
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td> is_location_proctor
                                                      <small className='text-muted d-block'>   (boolean) </small>
                                                   </td>
                                                   <td>
                                                      Specifies whether location-based proctoring is enabled.
                                                   </td>
                                                </tr>
                                             </tbody>
                                          </table>
                                       </td>
                                    </tr>
                                    <tr>
                                       <td>Sections <span><sub className='text-danger'>*</sub></span></td>
                                       <td>Array</td>
                                       <td>
                                          Sections represent the sections in the test
                                          <table className='table table-bordered mt-3'>
                                             <thead>
                                                <tr>
                                                   <th>Field Name</th>
                                                   <th>Description</th>
                                                </tr>
                                             </thead>
                                             <tbody>
                                                <tr>
                                                   <td>section_name
                                                      <small className='text-muted d-block'>    (string) </small>
                                                   </td>
                                                   <td>
                                                      The name or title of the test section.
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td>  pass_percentage
                                                      <small className='text-muted d-block'>   (integer) </small>
                                                   </td>
                                                   <td>
                                                      The pass percentage required to pass the section.
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td> is_question_navigation
                                                      <small className='text-muted d-block'>    (boolean) </small>
                                                   </td>
                                                   <td>
                                                      Indicates whether question navigation is allowed within the section.
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td> is_skip_question
                                                      <small className='text-muted d-block'>    (boolean) </small>
                                                   </td>
                                                   <td>
                                                      Specifies whether skipping questions is allowed within the section.
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td> duration
                                                      <small className='text-muted d-block'>   (integer)  </small>
                                                   </td>
                                                   <td>
                                                      The duration of the section in  minutes.
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td> questions
                                                      <small className='text-muted d-block'>     (array) </small>
                                                   </td>
                                                   <td>
                                                      An array containing information about the questions within the section.
                                                      <br/>
                                                      <br/>
                                                      Question   <small className='text-muted d-block'> (object)</small>
                                                      <table className='table table-bordered mt-4'>
                                                         <thead>
                                                            <tr>
                                                               <th>Field Name</th>
                                                               <th>Description</th>
                                                            </tr>
                                                         </thead>
                                                         <tbody>
                                                            <tr>
                                                               <td>
                                                                  question
                                                                  <small className='text-muted d-block'>  (string) </small>
                                                               </td>
                                                               <td>The text of the question.
                                                               </td>
                                                            </tr>
                                                            <tr>
                                                               <td>
                                                                  marks
                                                                  <small className='text-muted d-block'>  (integer) </small>
                                                               </td>
                                                               <td>The marks assigned to the question.
                                                               </td>
                                                            </tr>
                                                            <tr>
                                                               <td>
                                                                  duration
                                                                  <small className='text-muted d-block'>  (integer) </small>
                                                               </td>
                                                               <td>The time limit for answering the question in minutes.
                                                               </td>
                                                            </tr>
                                                            <tr>
                                                               <td>
                                                                  difficulty
                                                                  <small className='text-muted d-block'>  (integer) </small>
                                                               </td>
                                                               <td> The difficulty level of the question (0: easy, 1: medium, 2: hard).</td>
                                                            </tr>
                                                            <tr>
                                                               <td>
                                                                  domain
                                                                  <small className='text-muted d-block'> (string) </small>
                                                               </td>
                                                               <td> The domain or category of the question.
                                                               </td>
                                                            </tr>
                                                            <tr>
                                                               <td>
                                                                  question_type
                                                                  <small className='text-muted d-block'> (integer)</small>
                                                               </td>
                                                               <td>  The type of the question (0: MCQ single, 1: MCQ multiple, 2: tita, 3: essay, 4: video, 5: coding).
                                                               </td>
                                                            </tr>
                                                            <tr>
                                                               <td>
                                                                  ideal_answer
                                                                  <small className='text-muted d-block'> (string/null)
                                                                  </small>
                                                               </td>
                                                               <td> The ideal answer for the question (used for essay, video, coding types).
                                                               </td>
                                                            </tr>
                                                            <tr>
                                                               <td>
                                                                  explanation
                                                                  <small className='text-muted d-block'> (string/null)
                                                                  </small>
                                                               </td>
                                                               <td> Explanation for the correct answer (used for MCQ types).</td>
                                                            </tr>
                                                            <tr>
                                                               <td>
                                                                  tags
                                                                  <small className='text-muted d-block'> (array)
                                                                  </small>
                                                               </td>
                                                               <td> Tags defining the question
                                                               </td>
                                                            </tr>
                                                            <tr>
                                                               <td>
                                                                  options
                                                                  <small className='text-muted d-block'> (array)
                                                                  </small>
                                                               </td>
                                                               <td> An array containing information about the answer options (used for MCQ types).
                                                               </td>
                                                            </tr>
                                                         </tbody>
                                                      </table>
                                                   </td>
                                                </tr>
                                             </tbody>
                                          </table>
                                       </td>
                                    </tr>
                                 </tbody>
                              </table>
                           </div>
                     </section>
                     <section className='blog-section blog_section_pedding' id="Section">
                        <h2>Creating Section</h2>
                        <h3>Step 2 : Create Section</h3>
                        <h4>Purpose</h4>
                        <p>The "create section" API serves the purpose of adding a distinct section to an assessment on your platform, enhancing the structure and organisation of your tests.</p>
                        <h4>Why Sections in Assessments?</h4>
                        <p>Sections help break down assessments into meaningful parts, making it easier to evaluate candidates systematically. Each section can encompass multiple questions, allowing for a more detailed analysis of candidates' skills and competencies. This systematic approach aids in making well-informed hiring decisions and extends beyond hiring to evaluate academic performances.
                        </p>
                        <h4>How to Use the "Create Section" API:</h4>
                        <p>After creating an assessment using the "create test" API and establishing the essential elements such as test name and description, the next step is to add sections. Each assessment should have at least one section, and within that section, there must be a minimum of one question. The "create section" API provides a dedicated method for incorporating and editing these specific sections.   </p>
                        <h4>Optional Settings and Parameters:</h4>
                        <p>Similar to the "create test" API, the "create section" API allows for additional settings that enhance the customization of your assessments. These options include:  </p>
                        <h5>Section Name: </h5>
                        <p>Assign a specific name to the section, providing clarity on its content.  </p>
                        <h5>Pass Percentage: </h5>
                        <p>Set a pass percentage for the section, indicating the minimum score required for candidates to succeed.  </p>
                        <h5>Question Navigation: </h5>
                        <p>Enable or disable question navigation, allowing candidates to move between questions.  </p>
                        <h5>Skip Question: </h5>
                        <p>Determine whether candidates can skip questions within the section.  </p>
                        <h5>Duration:</h5>
                        <p>Define the time limit for completing the section, ensuring a controlled assessment environment.
                           Additionally, it's important to note that at least one question must be added to each section. Details on how to add questions will be explained in the "create question" API, ensuring that your assessments include the necessary components for a comprehensive evaluation. These settings offer flexibility in tailoring assessments to your specific needs, allowing you to create a more personalized and effective evaluation process. 
                        </p>
                        <h4>Request URL</h4>
                        <SyntaxHighlighter language="javascript" style={docco}>
                           {codeString}
                        </SyntaxHighlighter>
                        <h4>Request Method</h4>
                        <span className='d-block mt-3 mb-1'>Post</span>
                        <div className='syntaxhiglighter'>
                           <div className='syntax-head'>
                              <div className='language-tabs'>
                                 <button type='button' className='btn btn-default'>  NodeJS</button>
                                 <button type='button' className='btn btn-default'>  Python</button>
                                 <div className='copy-code'>
                                    <Tooltip title="Copy  Code" TransitionComponent={Zoom} placement="top" arrow>
                                       <Link to="" className='text-dark'>
                                       <ContentCopyIcon fontSize='small'/>
                                       </Link>
                                    </Tooltip>
                                 </div>
                              </div>
                           </div>
                           <SyntaxHighlighter language="javascript" style={docco}>
                              {codeString}
                           </SyntaxHighlighter>
                        </div>
                     </section>
                     <section className='blog-section blog_section_pedding' id="Question">
                        <h2>Creating Question</h2>
                        <h3>Step 2 : Create Questions</h3>
                        <h4>Purpose</h4>
                        <p>The ‘create question’ API is designed to facilitate the addition of specific questions to the sections within your assessment. This functionality enhances the granularity of your tests, allowing for detailed evaluation of candidates skills and knowledge.</p>
                        <h4>Why Questions in Sections?</h4>
                        <p>Questions serve as the building blocks of assessments, providing a means to evaluate candidates on specific topics. By adding questions to sections, you can systematically assess different aspects of a candidate's abilities, contributing to well-informed decision-making in hiring processes or academic evaluations.   </p>
                        <h4>How to Use the ‘Create Question’ API?</h4>
                        <p>Following the creation of sections using the "create section" API, the next step is to add questions to these sections. Each section should have at least one question to ensure a comprehensive evaluation. The "create question" API offers a dedicated method for adding and editing these specific questions.For adding questions, use the 'id' of the test and the 'id' of the section from the 'create test' and 'create section' APIs. After getting the response, find the question 'id' labelled as '_id'. Remember this ID for future updates to questions.</p>
                        <h4>Types of questions available :</h4>
                        <p>Specify the type of question, choosing from various options.  </p>
                        <h5>MCQ (Single): </h5>
                        <p>Multiple Choice Question with a single correct answer. </p>
                        <h5>MCQ (Multiple): </h5>
                        <p>Multiple Choice Question with multiple correct answers.  </p>
                        <h5>TITA: </h5>
                        <p>"Type In The Answer" question where candidates need to type their response.  </p>
                        <h5>Essay:</h5>
                        <p>Open-ended question requiring a more detailed written response. </p>
                        <h5>Video:</h5>
                        <p>Question that involves a video component.</p>
                        <h4>Optional Settings and Parameters:</h4>
                        <p>Similar to the "create test" and "create section" APIs, the "create question" API provides optional settings to customize your assessments. While details on these settings are not specified in this text, they can include parameters like question type, difficulty level, or multimedia attachments. Refer to the parameters table for more information.
                        </p>
                        <h4>Request URL</h4>
                        <SyntaxHighlighter language="javascript" style={docco}>
                           {codeString}
                        </SyntaxHighlighter>
                        <h4>Request Method</h4>
                        <span className='d-block mt-3 mb-1'>Post</span>
                        <div className='syntaxhiglighter'>
                           <div className='syntax-head'>
                              <div className='language-tabs'>
                                 <button type='button' className='btn btn-default'>  NodeJS</button>
                                 <button type='button' className='btn btn-default'>  Python</button>
                                 <div className='copy-code'>
                                    <Tooltip title="Copy  Code" TransitionComponent={Zoom} placement="top" arrow>
                                       <Link to="" className='text-dark'>
                                       <ContentCopyIcon fontSize='small'/>
                                       </Link>
                                    </Tooltip>
                                 </div>
                              </div>
                           </div>
                           <SyntaxHighlighter language="javascript" style={docco}>
                              {codeString}
                           </SyntaxHighlighter>
                        </div>
                        <h4>Conclusion</h4>
                        <p>By combining the "create test," "create section," and "create question" APIs, you can build a structured and detailed assessment that meets your specific needs, whether for hiring decisions or academic evaluations. These APIs offer flexibility and customization to enhance the effectiveness of your evaluation process.</p>
                     </section>
                     <section className='blog-section blog_section_pedding' id="SendInvites">
                        <h2>Send Invites  </h2>
                        <h3>Step 4 : Invite Candidates</h3>
                        <h4>Purpose</h4>
                        <p>The "Send Invites" API allows you to send assessment invites to single candidates or multiple candidates simultaneously. It provides options to control whether candidates can re-appear for the assessment and whether invites can be resent to the candidates before they've appeared.</p>
                        <h3>Single Invite Sending vs. Bulk Invite Sending:</h3>
                        <h4>Single Invite Sending:</h4>
                        <p>You can use the API to send invites to individual candidates by providing their details in a single request.   </p>
                        <h4>Bulk Invite Sending:</h4>
                        <p>You can send invites to multiple candidates simultaneously by providing an array of candidate details in a single request.</p>
                        <h3>Sending Invites Methods:</h3>
                        <h4>Uploading CSV:</h4>
                        <p>The API supports bulk invite sending by allowing you to upload a CSV file containing candidate details. </p>
                        <h5>Customised Mail Body & Subject: </h5>
                        <p>You have the flexibility to customise the email body and subject when sending invites, making the communication more personalised. </p>
                        <p>This API provides a versatile and efficient way to manage the invitation process for assessments, offering both single and bulk sending options with additional customization features.</p>
                        <h3>Parameters:</h3>
                        <h5>Candidates </h5>
                        <p>An array of objects containing candidate details required for sending test invites. This array can be used to send bulk or single invites.</p>
                        <h3>Field:</h3>
                        <h5>email (required): </h5>
                        <p>Candidate’s email ID to which the invite is sent. </p>
                        <h5> name (required):</h5>
                        <p>Candidate’s name (either first name or full name).</p>
                        <h5> is_resend (required):</h5>
                        <p>Boolean indicating whether invites can be resent to the candidates before they’ve appeared.
                        </p>
                        <h5>is_repeat (required):</h5>
                        <p>Boolean indicating whether candidates can re-appear for the assessment.</p>
                        <h5>Optional Settings and Parameters:</h5>
                        <p>Similar to the "create test" and "create section" APIs, the "create question" API provides optional settings to customize your assessments. While details on these settings are not specified in this text, they can include parameters like question type, difficulty level, or multimedia attachments. Refer to the parameters table for more information.
                        </p>
                        <h4>Request URL</h4>
                        <SyntaxHighlighter language="javascript" style={docco}>
                           {codeString}
                        </SyntaxHighlighter>
                        <h4>Request Method</h4>
                        <span className='d-block mt-3 mb-1'>Post</span>
                        <div className='syntaxhiglighter'>
                           <div className='syntax-head'>
                              <div className='language-tabs'>
                                 <button type='button' className='btn btn-default'>  NodeJS</button>
                                 <button type='button' className='btn btn-default'>  Python</button>
                                 <div className='copy-code'>
                                    <Tooltip title="Copy  Code" TransitionComponent={Zoom} placement="top" arrow>
                                       <Link to="" className='text-dark'>
                                       <ContentCopyIcon fontSize='small'/>
                                       </Link>
                                    </Tooltip>
                                 </div>
                              </div>
                           </div>
                           <SyntaxHighlighter language="javascript" style={docco}>
                              {codeString}
                           </SyntaxHighlighter>
                        </div>
                     </section>
                     <section className='blog-section blog_section_pedding' id="Resultcandidate">
                        <h2> Result  </h2>
                        <h3>Step 5 : Get Results</h3>
                        <p>You can obtain the result of a particular test or the result of all tests
                        </p>
                        <p className='mb-1'>- Result of a particular test</p>
                        <p>- Results of all tests</p>
                        <h3>Result of a particular test :</h3>
                        <h4>Purpose</h4>
                        <p>The Result API serves the purpose of retrieving detailed information related to a specific candidate's performance in an assessment. This API requires the candidate ID as input, and it provides comprehensive details that offer insights into the candidate's assessment results.</p>
                        <h4>How to Use the Result API:</h4>
                        <p>To utilize the Result API, you need to input the candidate ID, which can be obtained from the response of the Invite API. The candidate ID acts as a unique identifier for each candidate who has participated in the assessment.</p>
                        <h4>Information Provided in the Result:</h4>
                        <p>The API response includes a wealth of information, offering a detailed overview of the candidate's performance. This may encompass:</p>
                        <p className='mb-2 pointtext'> <span className='text-dark '><b>Scores:-</b></span> Detailed breakdown of scores achieved in different sections or question categories.</p>
                        <p className='mb-2 pointtext'> <span className='text-dark'><b>Time Taken:-</b></span> Duration taken by the candidate to complete the assessment.</p>
                        <p className='mb-2 pointtext'> <span className='text-dark'><b>Correct and Incorrect Answers:-</b></span> A summary of correct and incorrect answers provided by the candidate.</p>
                        <p className='mb-2 pointtext'> <span className='text-dark'><b>Overall Assessment Outcome:-</b></span> An overall evaluation or outcome of the assessment, indicating the candidate's proficiency or competency level.</p>
                        <p className='mb-2 pointtext'> <span className='text-dark'><b>Proctoring results:-</b></span> It explains about various fraud that happened during the test.</p>
                        <h4>Significance of the Candidate ID:</h4>
                        <p>The candidate ID plays a crucial role as it uniquely associates the assessment results with a specific individual. This ID is obtained from the response of the Invite API when sending invitations to candidates.</p>
                        <h4 className='mb-3'>Benefits of Using the Result API:</h4>
                        <p className='mb-2  pointtext'> <span className='text-dark'><b>Individual Candidate Analysis:-</b></span> Enables in-depth analysis of a candidate's performance on a granular level.</p>
                        <p className='mb-2 pointtext'> <span className='text-dark'><b>Data-Driven Decision Making:-</b></span>Provides valuable data for making informed decisions in hiring or academic evaluation processes.</p>
                        <p className='mb-2 pointtext'> <span className='text-dark'><b>Tracking Progress:-</b></span>Facilitates tracking and monitoring of candidate progress over multiple assessments.</p>
                        <h4>Request URL</h4>
                        <SyntaxHighlighter language="javascript" style={docco}>
                           {codeString}
                        </SyntaxHighlighter>
                        <h4>Request Method</h4>
                        <span className='d-block mt-3 mb-1'>Post</span>
                        <div className='syntaxhiglighter'>
                           <div className='syntax-head'>
                              <div className='language-tabs'>
                                 <button type='button' className='btn btn-default'>  NodeJS</button>
                                 <button type='button' className='btn btn-default'>  Python</button>
                                 <div className='copy-code'>
                                    <Tooltip title="Copy  Code" TransitionComponent={Zoom} placement="top" arrow>
                                       <Link to="" className='text-dark'>
                                       <ContentCopyIcon fontSize='small'/>
                                       </Link>
                                    </Tooltip>
                                 </div>
                              </div>
                           </div>
                           <SyntaxHighlighter language="javascript" style={docco}>
                              {codeString}
                           </SyntaxHighlighter>
                        </div>
                     </section>
                     <section className='blog-section blog_section_pedding' id="Resultalltests">
                        <h2> Result of all tests :  </h2>
                        <h4>Purpose</h4>
                        <p>The Result of All Tests API serves the purpose of retrieving comprehensive details for all candidates who have participated in a specific test. To utilize this API, provide the 'test ID' obtained from the 'create test' API as a parameter. This API offers a consolidated view of the performance metrics and status of all candidates associated with the specified test.</p>
                        <h4>Benefits and Use Cases:</h4>
                        <p className='mb-2 pointtext'> <span className='text-dark '><b>Efficient Monitoring:-</b></span> Provides an efficient way to monitor the overall progress and outcomes of a specific test.</p>
                        <p className='mb-2 pointtext'> <span className='text-dark'><b>Aggregated Statistics:-</b></span>Consolidates statistics such as total invitations, completions, pass rates, etc., providing a holistic view.</p>
                        <p className='mb-2 pointtext'> <span className='text-dark'><b>Identification of Trends:-</b></span> Helps in identifying trends, patterns, and areas for improvement based on the collective performance of candidates.</p>
                        <h4>Example Use Case:</h4>
                        <p>Imagine a hiring manager wants to review the outcomes of a coding test (specified by the 'test ID'). The Result of All Tests API can be used to retrieve information about the total number of candidates invited, the number of candidates who completed the test, the overall pass rate, and more.</p>
                        <p>This API is a powerful tool for obtaining aggregated insights into the performance of all candidates associated with a particular test, enabling organizations to make data-driven decisions and refine their assessment strategies.</p>
                        <h4>Request URL</h4>
                        <SyntaxHighlighter language="javascript" style={docco}>
                           {codeString}
                        </SyntaxHighlighter>
                        <h4>Request Method</h4>
                        <span className='d-block mt-3 mb-1'>Post</span>
                        <div className='syntaxhiglighter'>
                           <div className='syntax-head'>
                              <div className='language-tabs'>
                                 <button type='button' className='btn btn-default'>  NodeJS</button>
                                 <button type='button' className='btn btn-default'>  Python</button>
                                 <div className='copy-code'>
                                    <Tooltip title="Copy  Code" TransitionComponent={Zoom} placement="top" arrow>
                                       <Link to="" className='text-dark'>
                                       <ContentCopyIcon fontSize='small'/>
                                       </Link>
                                    </Tooltip>
                                 </div>
                              </div>
                           </div>
                           <SyntaxHighlighter language="javascript" style={docco}>
                              {codeString}
                           </SyntaxHighlighter>
                        </div>
                     </section>
                  </div>
               </div>
            </div>
         </div>
      </div>
</div>
</section>
<FooterPage />
</div>
)
}