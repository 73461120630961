import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import HeaderPage from "../_layout/elements/header";
import ChatForm from "../components/chatform";
import {
  Backdrop,
  SpeedDial,
  SpeedDialAction,
  Avatar,
  List,
  TextField,
  Tooltip,
  Zoom,
  Checkbox,
  Chip,
  Button,
  FormControlLabel,
  Switch,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  ListItem,
  Divider,
  ListItemButton,
} from "@mui/material";
import astroai from "../assets/images/logoicon.png";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import HistoryLoader from "../components/loader/history-loader";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbDownAltOutlinedIcon from "@mui/icons-material/ThumbDownAltOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import CheckIcon from "@mui/icons-material/Check";
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
import PauseCircleOutlineOutlinedIcon from "@mui/icons-material/PauseCircleOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import consultguruji from "../assets/images/logoicon.png"; 
import family from "../assets/images/family.png";
import MessageSnackbar from "../_layout/elements/snackbar";
import { changeAuth } from "../actions/loginAction";
import marriage from "../assets/images/marriage.png";
import finance from "../assets/images/finance.png";
import CloseIcon from "@mui/icons-material/Close";
import ReactReadMoreReadLess from "react-read-more-read-less";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import lovebirds from "../assets/images/love-birds.png";
import { v4 as uuidv4 } from "uuid";
import career from "../assets/images/career.png";
import business from "../assets/images/business.png";
import { deepOrange, deepPurple } from "@mui/material/colors";
import ReactMarkdown from "react-markdown";
import astroavtar from "../assets/images/chatavtar.png";
import { motion } from "framer-motion";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import TryOutlinedIcon from "@mui/icons-material/TryOutlined";
import ChatContentLoader from "../components/loader/ChatContentLoader";
import { useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import host from "../host";
import TableLoader from "../components/loader/table-loader";
import ContentLoader from "../components/loader/content-loader";
import ReviewsOutlinedIcon from "@mui/icons-material/ReviewsOutlined";
import InsightsOutlinedIcon from "@mui/icons-material/InsightsOutlined";
import PlanDuration from "../components/plan_duration";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import SpeakerNotesOffOutlinedIcon from "@mui/icons-material/SpeakerNotesOffOutlined";
import DynamicFeedOutlinedIcon from "@mui/icons-material/DynamicFeedOutlined";
import AddIcon from "@mui/icons-material/Add";
import { use } from "marked";
import { get } from "jquery";
const label = { inputProps: { "aria-label": "Checkbox demo" } };
const actions2 = [
  { icon: <InsightsOutlinedIcon />, name: "Charts" },
  { icon: <HistoryOutlinedIcon />, name: "History" },
  { icon: <ReviewsOutlinedIcon />, name: "New Chat" },
];
// import { motion } from "framer-motion";
export const NewAstroChatbot = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = localStorage.getItem("login") === "true";
  const newChat = localStorage.getItem("chat_id") === "null";

  useEffect(() => {
    if (!auth) {
      navigate("/logout");
    }
  }, [auth]);

  useEffect(() => {
    if (localStorage.getItem("chat_id") !== "null" && localStorage.getItem("chat_id") !== undefined && localStorage.getItem("chat_id") !== null) {
      getSelectedChat(localStorage.getItem("chat_id"));
      getRelatedQuestions(localStorage.getItem("chat_id"));
    }
  }, []);

  const IOSSwitch = styled((props) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(({ theme }) => ({
    width: 38,
    height: 20,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 16,
      height: 16,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const [open, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openedChatId, setOpenedChatId] = useState(null);

  const handleClickaction = (event, chatId) => {
    setAnchorEl(event.currentTarget);
    setOpenedChatId(chatId);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenedChatId(null);
  };

  const [isClassAdded, setIsClassAdded] = useState(null);

  const [severity, setSeverity] = useState("success");
  const [snackbar, setSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const onClose = () => {
    setSnackbar(false);
  };
  //chat history functionality begins
  const [profile, setProfile] = useState(null);
  const [isFreeTrial, setIsFreeTrial] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [questionsAllowed, setQuestionsAllowed] = useState(null);
  const [hasExpired, setHasExpired] = useState(false);
  const [birthData, setBirthData] = useState(null);
  const [topic, setTopic] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [chatList, setChatList] = useState([]);

  function convertTo12HourFormat(hour, minute) {
    const suffix = hour >= 12 ? "PM" : "AM";
    hour = hour > 12 ? hour - 12 : hour;
    hour = hour === 0 ? 12 : hour;
    minute = minute < 10 ? "0" + minute : minute;
    return `${hour}:${minute} ${suffix}`;
  }

  const questions = {
    Family: [
      "Will my family relationships improve?",
      "What traits do my family members value?",
      "What can I do to create a harmonious family environment?",
      "How can we resolve disagreements effectively as a family?",
      "How can I help my son stay motivated and focused in his studies?",
      "How can we create a sense of belonging and acceptance in our family?",
    ],
    Career: [
      "When might I expect a promotion?",
      "What steps can I take to advance in my profession?",
      "How can I improve my work-life balance?",
      "Is a career change advisable at this time?",
      "What career decisions align best with my long-term goals?",
      "Are there any upcoming opportunities for career growth?",
    ],
    Business: [
      "When will I achieve success in my business?",
      "What potential obstacles or challenges may arise in my business endeavors?",
      "Are there any astrological indicators suggesting entrepreneurial success?",
      "How can I overcome loss in business?",
      "Is this an opportune moment for branching out my business?",
      "What strategies can I employ to enhance profitability?",
    ],
    Finance: [
      "Will my financial situation improve soon?",
      "How can I attract more abundance into my life?",
      "What career paths align with my financial goals?",
      "Can you provide insights into my financial future?",
      "Are there any favorable investment opportunities coming my way?",
      "What are potential risks or challenges I should be aware of regarding my finances?",
    ],
    Love: [
      "When will I find my soulmate?",
      "How can I attract more love into my life?",
      "How can I improve my current relationship?",
      "What past experiences might be influencing my current relationships?",
      "What can astrology reveal about my love language?",
      "What does my birth chart suggest about my love life?",
    ],
    Marriage: [
      "Should I consider marriage as the next step in my life?",
      "What steps can I take to strengthen the bond between me and my spouse?",
      "What challenges might I face in my marriage, and how can I overcome them?",
      "Am I emotionally prepared for marriage?",
      "When might I anticipate starting a family?",
      "How can I address issues with my wife effectively?",
    ],
  };

  const getChatList = () => {
    setIsLoaded(false);
    fetch(host.astro_backend + `/chat`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 403) {
          dispatch(changeAuth(false, null));
          navigate("/logout");
        } else {
          setIsLoaded(true);
          setSeverity("error");
          setMessage("Something went wrong");
          setSnackbar(true);
        }
      })
      .then((data) => {
        if (data) {
          setChatList(data);
          setIsLoaded(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoaded(true);
      });
  };

  // const handleGetChat = (chat_id) => {
  //   // setPageLoaded(false);
  //   fetch(host.astro_backend + `/chat/${chat_id}`, {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + localStorage.getItem("token"),
  //     },
  //   })
  //     .then((res) => {
  //       if (res.ok) {
  //         return res.json();
  //       } else if (res.status === 403) {
  //         dispatch(changeAuth(false, null));
  //         navigate("/logout");
  //       } else {
  //         // setPageLoaded(true);
  //         setSeverity("error");
  //         setMessage("Something went wrong");
  //         setSnackbar(true);
  //       }
  //     })
  //     .then((data) => {
  //       if (data?.chat_name) {
  //         // setTitle(data.chat_name);
  //       } else {
  //         // setTitle("Untitled");
  //       }
  //       if (data) {
  //         const temp = data.chat_history.flatMap((chat) => {
  //           return {
  //             brain: chat.brain_name ? chat.brain_name : "NA",
  //             request: chat.human ? chat.human : "NA",
  //             response: chat.ai ? chat.ai : "NA",
  //             message_id: chat.id,
  //           };
  //         });
  //         // setPageLoaded(true);
  //         handleDownloadChat(temp, data.chat_name ? data.chat_name : chat_id);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       // setPageLoaded(true);
  //     });
  // };

  const getProfile = () => {
    setIsLoaded(false);
    fetch(host.astro_backend + `/user`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 403) {
          dispatch(changeAuth(false, null));
          navigate("/logout");
        } else {
          setIsLoaded(true);
          setSeverity("error");
          setMessage("Something went wrong");
          setSnackbar(true);
        }
      })
      .then((data) => {
        if (data) {
          setProfile(data);
          setIsLoaded(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoaded(true);
      });
  };

  const getUserPlan = () => {
    setIsLoaded(false);
    fetch(host.astro_backend + `/plans/user/plan`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 403) {
          dispatch(changeAuth(false, null));
          navigate("/logout");
        } else {
          setIsLoaded(true);
          setSeverity("error");
          setMessage("Something went wrong");
          setSnackbar(true);
        }
      })
      .then((data) => {
        if (data) {
          if (data.is_free_trial) {
            setIsFreeTrial(true);
          } else {
            setIsFreeTrial(false);
          }
          setQuestionsAllowed(data.questions_remaining);
          // Calculate time remaining until expiration
          const currentDate = new Date();
          const expirationDate = new Date(data.expires_at);
          const temp = expirationDate - currentDate;
          setTimeRemaining(temp);
          if (currentDate > expirationDate) {
            setHasExpired(true);
          } else {
            setHasExpired(false);
          }
          setIsLoaded(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoaded(true);
      });
  };

  const getReport = () => {
    setIsLoaded(false);
    setKundliLoading(true);
    fetch(host.astro_backend + `/report`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 403) {
          dispatch(changeAuth(false, null));
          navigate("/logout");
        } else {
          setIsLoaded(true);
          setKundliLoading(false);
          setSeverity("error");
          setMessage("Something went wrong");
          setSnackbar(true);
        }
      })
      .then((data) => {
        if (data) {
          setBirthData(data.vedic_horoscope.astro_details);
          if (data.birth_chart !== null && data.birth_chart?.svg) {
            setKundliData(data.birth_chart?.svg);
            setKundliLoading(false);
          } else {
            getKundli();
          }
          setIsLoaded(true);
        }
      })
      .catch((err) => {
        setKundliLoading(false);
        console.log(err);
        setIsLoaded(true);
      });
  };

  useEffect(() => {
    getChatList();
    getProfile();
    getUserPlan();
    localStorage.setItem("streamTries", 0); // necessary for tab switch logic
    if (
      localStorage.getItem("topic") &&
      (localStorage.getItem("topic") === "Family" ||
        localStorage.getItem("topic") === "Career" ||
        localStorage.getItem("topic") === "Business" ||
        localStorage.getItem("topic") === "Finance" ||
        localStorage.getItem("topic") === "Love" ||
        localStorage.getItem("topic") === "Marriage")
    ) {
      setTopic(localStorage.getItem("topic"));
      localStorage.removeItem("topic");
    }
    if (localStorage.getItem("question") && localStorage.getItem("question") !== "") {
      handleMessage(localStorage.getItem("question"));
      localStorage.removeItem("question");
    }
  }, []);

  useEffect(() => {
    if (profile) {
      getReport();
    }
  }, [profile]);

  //chat history functionality endss

  const [title, setTitle] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const handleTitleClick = () => {
    // setIsEditing(true);
  };
  const [chatTime, setChatTime] = useState(0);

  function getTimeDifference(chatTime) {
    const now = new Date();
    const timeDifference = now - new Date(chatTime);
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return `${days} day${days > 1 ? "s" : ""} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else {
      return `${seconds} second${seconds > 1 ? "s" : ""} ago`;
    }
  }
  const handleEnterAndBlur = () => {
    setTitle(title); // Ensure the title state is updated before the API call
    fetch(host.astro_backend + `/chat/${chatId}?chat_name=${title}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 403) {
          dispatch(changeAuth(false, null));
          navigate("/logout");
        } else {
          setSeverity("error");
          setMessage("Something went wrong");
          setSnackbar(true);
        }
      })
      .then((data) => {
        if (data) {
          setIsEditing(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleEnterAndBlur();
    }
  };

  const handleInputBlur = () => {
    setIsEditing(false);
    handleEnterAndBlur();
  };
  const handleInputKeyPress = (e) => {
    if (e.key === "Enter") {
      setIsEditing(false);
    }
  };

  //chat flow begins
  let timeoutId;
  const [chatHistory, setChatHistory] = useState([]);
  const [chatId, setChatId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [request, setRequest] = useState("");
  const [data, setData] = useState("");
  const [currentLanguages, setCurrentLanguages] = useState([]);
  const [translatedResponses, setTranslatedResponses] = useState({});
  useEffect(() => {
    // states are appropriately reset
    if (newChat) {
      handleNewChat();
    }
  }, [newChat]);

  const handleNewChat = () => {
    setChatId(null); // this ChatId is for GetMessage API
    setChatHistory([]);
    setRequest("");
    id = null;
  };

  const getSelectedChat = (chat_id) => {
    setChatHistory([]);
    // setPageLoaded(false);
    fetch(host.astro_backend + `/chat/${chat_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 403) {
          dispatch(changeAuth(false, null));
          navigate("/logout");
        } else {
          // setPageLoaded(true);
          setSeverity("error");
          setMessage("Something went wrong");
          setSnackbar(true);
        }
      })
      .then((data) => {
        if (data?.created_at) {
          setChatTime(data.created_at);
        }
        if (data?.chat_name) {
          setTitle(data.chat_name);
        } else {
          setTitle("Untitled");
        }
        if (data) {
          const temp = data.chat_history.map((chat) => {
            return {
              request: chat.human ? chat.human : "NA",
              response: chat.ai ? chat.ai : "NA",
              message_id: chat.message_id,
              thumbsup: chat.upvote !== null && chat.upvote !== undefined && chat.upvote === true ? true : false,
              thumbsdown: chat.upvote !== null && chat.upvote !== undefined && chat.upvote === false ? true : false,
              rawResponse: chat.ai ? chat.ai : "",
            };
          });
          setChatHistory(temp);
          // setPageLoaded(true);
          setChatId(data._id);
        }
      })
      .catch((err) => {
        console.log(err);
        // setPageLoaded(true);
      });
  };

  const getMessage = async (message) => {
    setLoading(true);
    if (listening) {
      SpeechRecognition.abortListening();
    }

    if (!message) {
      return;
    }
    let mainquery = `/chat/`;
    setLoading(true);
    fetch(host.astro_backend + mainquery, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify({
        user_question: message,
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 403) {
          dispatch(changeAuth(false, null));
          navigate("/logout");
        } else {
          setLoading(false);
          setSeverity("error");
          setMessage("Something went wrong");
          setSnackbar(true);
        }
      })
      .then((data) => {
        setLoading(false);
        if (data) {
          if (chatId === null) {
            setChatId(data.chat_id);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleMessage = (message) => {
    if (message) {
      setChatHistory((prevChatHistory) => [...prevChatHistory, { request: message }]);
      if (chatId === null || chatId === "null" || chatId === undefined) {
        getMessage(message);
      } else {
        //continue with the chat flow
        const message_id = uuidv4();
        fetchData(message, message_id);
      }
      setRequest("");
    }
  };

  const handleTabSwitch = (message, message_id) => {
    if (parseInt(localStorage.getItem("streamTries")) > 10) {
      setSeverity("error");
      setMessage("Sorry, we are unable to process your request at the moment. Please try again later.");
      setSnackbar(true);
      localStorage.setItem("streamTries", 0);
      return;
    } else {
      //setting time delay to avoid the error
      localStorage.setItem("streamTries", parseInt(localStorage.getItem("streamTries")) + 1);
      setTimeout(() => {
        fetchData(message, message_id);
      }, 5000);
    }
  };

  const [relatedQuestionsLoading, setRelatedQuestionsLoading] = useState(false);
  const [relatedQuestions, setRelatedQuestions] = useState(null);

  const getRelatedQuestions = (chat_id) => {
    setRelatedQuestionsLoading(true);
    //chat_id === chatId
    fetch(host.astro_backend + `/chat/${chat_id}/followup`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 403) {
          dispatch(changeAuth(false, null));
          navigate("/logout");
        } else {
          setSeverity("error");
          setMessage("Something went wrong");
          setSnackbar(true);
        }
      })
      .then((data) => {
        setRelatedQuestions(data);
        setRelatedQuestionsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchData = async (message, message_id) => {
    if (listening) {
      SpeechRecognition.abortListening();
    }

    if (!message) {
      return;
    }
    setRelatedQuestionsLoading(true);
    setLoading(true);
    setData("");
    let url = `${host.astro_backend}/chat/${chatId}/default?&message_id=${message_id}`;

    await fetchEventSource(url, {
      method: "POST",
      headers: {
        Accept: "text/event-stream",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        user_question: message,
      }),
      onopen(res) {
        if (res.ok && res.status === 200) {
          localStorage.setItem("streamTries", 0);
          setChatHistory((prevChatHistory) => {
            const updatedHistory = [...prevChatHistory];
            const lastObjectIndex = updatedHistory.length - 1;
            if (lastObjectIndex >= 0) {
              // Update the last object
              updatedHistory[lastObjectIndex] = {
                ...updatedHistory[lastObjectIndex],
                response: "",
                rawResponse: "",
              };
            }

            return updatedHistory;
          });
          console.log("Connection made ");
          // setStreamClosed(false);
        } else if (res.status > 400 && res.status < 500 && res.status !== 429) {
          console.log("Client-side error ", res);
        } else if (res.status === 400) {
          handleTabSwitch(message, message_id);
        }
      },
      onmessage(event) {
        setLoading(false);
        // const parsedData = JSON.parse(event.data);
        //setData((eventData) => eventData + event.data);
        const correctedData = event.data.replace(/<br\/>/g, "\n").replace(/<b>(.*?)<\/b>/g, "**$1**");
        setData((eventData) => eventData + correctedData);
        // Important to set the data this way, otherwise old data may be overwritten if the stream is too fast
      },
      onclose() {
        if (localStorage.getItem("streamTries") === "0") {
          setLoading(false);

          if (isFreeTrial) {
            getUserPlan();
          }

          setChatHistory((prevChatHistory) => {
            // reset thelast response so it may not repeat itself
            const updatedHistory = [...prevChatHistory];
            const lastObjectIndex = updatedHistory.length - 1;
            if (lastObjectIndex >= 0) {
              // Update the last object
              updatedHistory[lastObjectIndex] = {
                ...updatedHistory[lastObjectIndex],

                message_id: message_id,

                thumbsup: false,
                thumbsdown: false,
              };
            }
            return updatedHistory;
          });
        }
        //  getChatDetails(message_id);
        getRelatedQuestions(chatId);
        console.log("Connection closed by the server");
      },
      onerror(err) {
        setLoading(false);
        console.log("There was an error from server", err);
      },
    });
  };

  useEffect(() => {
    if (data !== "") {
      setChatHistory((prevChatHistory) => {
        const updatedHistory = [...prevChatHistory];
        const lastObjectIndex = updatedHistory.length - 1;
        if (lastObjectIndex >= 0) {
          // Update the last object
          updatedHistory[lastObjectIndex] = {
            ...updatedHistory[lastObjectIndex],
            response: data,
            rawResponse: data ? data : "",
          };
        }

        return updatedHistory;
      });
    }
  }, [data]);
  const audioRef = useRef(null);
  const scrollingDivRef = useRef(null);

  useEffect(() => {
    if (chatHistory.length > 0) {
      console.log(chatHistory);
      scrollingDivRef.current.scrollIntoView({ behavior: "smooth" });

      setCurrentLanguages(Array(chatHistory.length).fill("english"));
    }
  }, [chatHistory]);

  useEffect(() => {
    // if (id) {
    //   return; // if inside a selected chat dont call with chatHistory[1]
    // }
    if (chatId && chatHistory[0]?.response === undefined) {
      const message_id = uuidv4();
      fetchData(chatHistory[0].request, message_id);
      localStorage.setItem("chat_id", chatId);
      //continue with the chat flow
    }
  }, [chatId]);

  //chat flow ends

  const [isListening, setIsListening] = useState(false);
  const handleMicButtonClick = () => {
    setIsListening((prevState) => !prevState);
  };

  const [micOn, setMicOn] = useState(false);

  const { transcript, listening, resetTranscript } = useSpeechRecognition();
  const handleStart = () => {
    SpeechRecognition.startListening({ continuous: true });
  };
  const handleStop = () => {
    handleMessage(transcript);
    SpeechRecognition.abortListening();
    resetTranscript();
    setRequest("");
  };

  const handleMic = () => {
    setMicOn(!micOn);
  };

  useEffect(() => {
    if (listening && transcript.length > 0) {
      timeoutId = setTimeout(() => {
        console.log("Timeout: No speech detected. Stopping listening.");
        handleMic();
      }, 5000); // Adjust the timeout duration as needed
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [transcript, listening]);

  useEffect(() => {
    if (micOn) {
      // console.log("started");
      handleStart();
    } else {
      // console.log("stopped");
      handleStop();
    }
  }, [micOn]);

  // Like - feedback functionality begins

  const sendFeedBack = (chat_id, message_id, reaction) => {
    let upvote;
    console.log(message_id);
    if (reaction === "thumbsup") {
      upvote = true;
    } else {
      upvote = false;
    }

    fetch(host.astro_backend + `/chat/${chat_id}/feedback/${message_id}?upvote=${upvote}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 403) {
          dispatch(changeAuth(false, null));
          navigate("/logout");
        } else {
          setSeverity("error");
          setMessage("Something went wrong");
          setSnackbar(true);
        }
      })
      .then((data) => {
        if (data) {
          console.log(id, upvote);
          setChatHistory((prevChatHistory) => prevChatHistory.map((chat) => (chat.message_id === message_id ? { ...chat, thumbsup: upvote, thumbsdown: !upvote } : chat)));
          setSeverity("success");
          setMessage("Thanks For Your Feedback !");
          setSnackbar(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Like - feedback functionality ends

  //this below function for skill suggition start//
  const [copyStatus, setCopyStatus] = useState({});
  const handleClick = (index, message) => {
    // Copying message to clipboard
    navigator.clipboard
      .writeText(message)
      .then(() => {
        setCopyStatus((prevState) => ({
          ...prevState,
          [index]: true,
        }));
      })
      .catch((error) => {
        console.error("Failed to copy:", error);
      });
  };
  //this below function for skill suggition end//

  /*play pause function start */
  const [isAudioPlaying, setIsAudioPlaying] = useState({});
  const [isAudioEnded, setIsAudioEnded] = useState({});

  const base64toBlob = (base64, mimeType) => {
    const byteCharacters = atob(base64);
    const byteArray = new Uint8Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteArray[i] = byteCharacters.charCodeAt(i);
    }

    return new Blob([byteArray], { type: mimeType });
  };

  const fetchAudioData = async (index, message) => {
    const mainquery = "/synthesize/";
    try {
      fetch(host.astro_backend + mainquery, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
          "X-API-Key": host.textToSpeechApikey,
        },
        body: JSON.stringify({
          text: message,
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          const audioContent = data.audio_content;
          const audioBlob = base64toBlob(audioContent, "audio/mp3");
          const url = window.URL.createObjectURL(audioBlob);
          audioRef.current = new Audio(url);
          handlePlayAudio(index);
          audioRef.current.addEventListener("ended", () => handleAudioEnded(index));
        })
        .catch((err) => {
          console.error("Error fetching data:", err);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleAudioEnded = (index) => {
    if (audioRef.current) {
      setIsAudioPlaying((prevState) => ({
        ...prevState,
        [index]: false,
      }));
      setIsAudioEnded((prevState) => ({
        ...prevState,
        [index]: true,
      }));
    }
  };

  const handlePlayAudio = (index) => {
    if (audioRef.current) {
      setIsAudioPlaying((prevState) => ({
        ...prevState,
        [index]: true,
      }));
      setIsAudioEnded((prevState) => ({
        ...prevState,
        [index]: false,
      }));
      audioRef.current.play();
    }
  };

  const handlePauseAudio = (index) => {
    if (audioRef.current) {
      setIsAudioPlaying((prevState) => ({
        ...prevState,
        [index]: false,
      }));
      setIsAudioEnded((prevState) => ({
        ...prevState,
        [index]: false,
      }));
      audioRef.current.pause();
    }
  };

  const handleResumeAudio = (index) => {
    if (audioRef.current) {
      setIsAudioPlaying((prevState) => ({
        ...prevState,
        [index]: true,
      }));
      audioRef.current.play();
    }
  };

  const cleanText = (text) => {
    // Remove styled elements
    const strippedText = text.replace(/<[^>]*>/g, ""); // This regex removes HTML tags

    // Remove non-alphanumeric characters except spaces
    const cleanText = strippedText.replace(/[^\w\s]/gi, "");

    // Remove extra whitespaces and newline characters
    const finalText = cleanText.replace(/\s+/g, " ").trim();

    return finalText;
  };

  /*play pause function end */

  const handleLanguageToggle = (index, chatResponse) => {
    setCurrentLanguages((prevLanguages) => {
      const newLanguages = [...prevLanguages];
      newLanguages[index] = newLanguages[index] === "english" ? "hindi" : "english";
      return newLanguages;
    });

    if (!translatedResponses[index]) {
      translateLanguage(index, chatResponse);
    }
  };

  const translateLanguage = (index, text) => {
    setLoading(true);
    let mainquery = `/synthesize/translate`;
    fetch(host.astro_backend + mainquery, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify({
        text: text,
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 403) {
          dispatch(changeAuth(false, null));
          navigate("/logout");
        } else {
          setLoading(false);
          setSeverity("error");
          setMessage("Something went wrong");
          setSnackbar(true);
        }
      })
      .then((data) => {
        setLoading(false);
        if (data) {
          setTranslatedResponses((prevState) => ({
            ...prevState,
            [index]: data.translated_text,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  //kundli svg functionality begins

  const [kundliData, setKundliData] = useState(null);
  const [kundliLoading, setKundliLoading] = useState(false);

  const getKundli = () => {
    setKundliLoading(true);
    if (!profile) {
      return;
    }

    // Encode the credentials in base64
    const auth = btoa(`${host.username}:${host.password}`);

    fetch(host.astro_url + "/horo_chart_image/:D1", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${auth}`,
      },
      body: JSON.stringify({
        day: profile.birth_day,
        month: profile.birth_month,
        year: profile.birth_year,
        hour: profile.birth_hour,
        lat: profile.lat,
        lon: profile.long,
        min: profile.birth_minute,
        tzone: profile.timezone,
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 403) {
          dispatch(changeAuth(false, null));
          navigate("/logout");
        } else {
          setKundliLoading(false);
          setSeverity("error");
          setMessage("Something went wrong");
          setSnackbar(true);
        }
      })
      .then((data) => {
        if (data) {
          setKundliData(data.svg);
          setKundliLoading(false);
        }
      })
      .catch((err) => {
        setKundliLoading(false);
        console.log(err);
      });
  };

  //kundli svg functionality ends
  const [openaction, setOpenaction] = React.useState(false);
  const handleOpenaction = () => setOpenaction(true);
  const handleCloseaction = () => setOpenaction(false);
  return (
    <div>
      <MessageSnackbar open={snackbar} autoHideDuration={3000} onClose={onClose} severity={severity} message={message} />

      <div className="inner-header">
        <HeaderPage />
      </div>
      <div className="astro_chatbot_wrapper">
        <div className="astro_flex">
          <div className={`asrtro_aside ${isClassAdded === "History" ? "showhistorymobile" : ""}`}>
            <div className="asrtro_aside_flex">
              <div className="astro_history">
                <div className="report_menu_close">
                  <Link to="" className="close closemenubar" onClick={() => setIsClassAdded(null)}>
                    X
                  </Link>
                </div>

                {chatHistory?.length > 0 && (
                  <div className="history_head">
                    <Link
                      to=""
                      className=" btn btn-primary history_title"
                      onClick={() => {
                        localStorage.removeItem("chat_id");
                        setIsClassAdded(null);
                        handleNewChat();
                      }}
                    >
                      New Chat
                    </Link>
                  </div>
                )}
                <div className="chat_avtar_history">
                  <div className="aiastro_title">Rishi Kashyap AI</div>
                  <div className="avtar_image">
                    <img src={astroavtar} alt="Avatar" />
                  </div>
                  <div className="astroname">Guru Kashyap AI</div>

                  <div className="astro_avtar_pera">
                    <span>Why wait for the stars to align when I can align your destiny in a chat?</span>
                  </div>
                </div>
                <div className="sidebar-history   ">
                  <div className="sidebar-history-body">
                    <div className="skeleton_loader" style={{ display: "none" }}>
                      <HistoryLoader />
                    </div>
                    <div className="chat_history_list  ">
                      <div className="list_box">
                        {!isLoaded ? (
                          <HistoryLoader />
                        ) : (
                          <ul>
                            {chatList && chatList.length > 0 ? (
                              Object.entries(
                                chatList.reduce((accumulator, chat, index) => {
                                  const currentDate = new Date(chat.created_at);
                                  let formattedDate;

                                  // Check if the date is today
                                  const today = new Date();
                                  if (currentDate.getDate() === today.getDate() && currentDate.getMonth() === today.getMonth() && currentDate.getFullYear() === today.getFullYear()) {
                                    formattedDate = "Today";
                                  }
                                  // Check if the date is yesterday
                                  else if (currentDate.getDate() === today.getDate() - 1 && currentDate.getMonth() === today.getMonth() && currentDate.getFullYear() === today.getFullYear()) {
                                    formattedDate = "Yesterday";
                                  }
                                  // Otherwise, format the date as dd-mm-yyyy
                                  else {
                                    const day = ("0" + currentDate.getDate()).slice(-2);
                                    const month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
                                    const year = currentDate.getFullYear();
                                    formattedDate = `${day}-${month}-${year}`;
                                  }

                                  if (!accumulator[formattedDate]) {
                                    accumulator[formattedDate] = [];
                                  }

                                  accumulator[formattedDate].push(
                                    <li key={index}>
                                      <Link
                                        // to={path}
                                        to=""
                                        className={localStorage.getItem("chat_id") === chat.id ? "active" : ""}
                                        onClick={() => {
                                          setIsClassAdded(null);
                                          localStorage.setItem("chat_id", chat.id);
                                          getSelectedChat(chat.id);
                                          getRelatedQuestions(chat.id);
                                        }}
                                      >
                                        <div className="history_line">{chat.chat_name ? chat.chat_name : "NA"}</div>
                                      </Link>
                                      {/* <div className="history_action">
                                        <Tooltip title="Actions">
                                          <Link
                                            onClick={(event) => handleClickaction(event, chat.id)}
                                            size="small"
                                            aria-controls={open ? "account-menu" : ""}
                                            aria-haspopup="true"
                                            aria-expanded={open ? "true" : "false"}
                                          >
                                            <MoreHorizIcon />
                                          </Link>
                                        </Tooltip>
                                        <Menu
                                          anchorEl={anchorEl}
                                          id="account-menu"
                                          open={openedChatId === chat.id}
                                          onClose={handleClose}
                                          onClick={handleClose}
                                          PaperProps={{
                                            elevation: 0,
                                            sx: {
                                              overflow: "visible",
                                              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                              mt: 1.5,
                                              "& .MuiAvatar-root": {
                                                width: 32,
                                                height: 32,
                                                ml: -0.5,
                                                mr: 1,
                                              },
                                              "&::before": {
                                                content: '""',
                                                display: "block",
                                                position: "absolute",
                                                top: 0,
                                                right: 14,
                                                width: 10,
                                                height: 10,
                                                bgcolor: "background.paper",
                                                transform: "translateY(-50%) rotate(45deg)",
                                                zIndex: 0,
                                              },
                                            },
                                          }}
                                          transformOrigin={{
                                            horizontal: "right",
                                            vertical: "top",
                                          }}
                                          anchorOrigin={{
                                            horizontal: "right",
                                            vertical: "bottom",
                                          }}
                                        >
                                          <MenuItem>
                                            <ListItemIcon>
                                              <ShareOutlinedIcon fontSize="small" />
                                            </ListItemIcon>
                                            Share Chat
                                          </MenuItem>
                                          //Comment below part
                                          <MenuItem
                                            onClick={() => {
                                              handleGetChat(chat.id);
                                            }}
                                          >
                                            <ListItemIcon>
                                              <FileDownloadOutlinedIcon fontSize="small" />
                                            </ListItemIcon>
                                            Download Chat
                                          </MenuItem>
                                          <MenuItem>
                                            <ListItemIcon>
                                              <DeleteOutlineOutlinedIcon fontSize="small" />
                                            </ListItemIcon>
                                            Delete
                                          </MenuItem>
                                        </Menu>
                                      </div> */}
                                    </li>
                                  );
                                  return accumulator;
                                }, {})
                              ).map(([date, chats]) => (
                                <div key={date}>
                                  <div className="list_title">{date}</div>
                                  <ul>{chats}</ul>
                                </div>
                              ))
                            ) : (
                              <div className="nochats">
                                <SpeakerNotesOffOutlinedIcon />
                                No Chats Available{" "}
                              </div>
                            )}
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="asrtro_chat_right">
            <div className="deskhome_chat_wrapper ">
              <div className="desk_chat_wrapper">
                {/* Display if it is free trial or if lesser than 3 days remaining */}
                {isFreeTrial !== null && timeRemaining !== null && (isFreeTrial || timeRemaining < ((2.592 * 10) ^ 8)) && (
                  <div className="chatpageplanduration">
                    <div className="fix_plan_wrapper">
                      <PlanDuration timeRemaining={timeRemaining} hasExpired={hasExpired} isFreeTrial={isFreeTrial} questionsAllowed={questionsAllowed} isChat={true} showLifeBanner={false} />
                    </div>
                  </div>
                )}
                {chatHistory.length === 0 ? (
                  <div className="new_chat_box">
                    <div className="astro_chat_center_content">
                      <motion.div initial={{ opacity: 0, y: 30 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.2, delay: 0.2 }}>
                        <Avatar sx={{ width: 50, height: 50 }} src={consultguruji}></Avatar>
                      </motion.div>
                      <motion.div initial={{ opacity: 0, y: 30 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.2, delay: 0.2 }} className="ai_message">
                        <h3>Hello {profile?.fullname} ji, How can I help you today?</h3>
                      </motion.div>
                      {topic === "" && (
                        <div className="astro_suggestions_flex mt-3">
                          <motion.div
                            initial={{ opacity: 0, y: 30 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.2, delay: 0.2 }}
                            className="astro_suggestions_box"
                            onClick={() => handleMessage("What major life events can I expect in the near future?")}
                          >
                            <div className="suggestion_flex">
                              <div className="suggestion_content">
                                <span>What major life events can I expect in the near future?</span>
                              </div>
                            </div>
                          </motion.div>
                          <motion.div
                            initial={{ opacity: 0, y: 30 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.2, delay: 0.4 }}
                            className="astro_suggestions_box"
                            onClick={() => handleMessage("How can I overcome obstacles in my studies and excel academically?")}
                          >
                            <div className="suggestion_flex">
                              <div className="suggestion_content">
                                <span>How can I overcome obstacles in my studies and excel academically?</span>
                              </div>
                            </div>
                          </motion.div>
                        </div>
                      )}
                    </div>
                    <div className={topic === "" ? "astro_sugcat_box " : "astro_sugcat_box defaultclass "}>
                      <div className="astro_sugcat_flex">
                        <div className="astro_chat_category_wrapper" style={{ display: "block" }}>
                          <div className="astro_chat_category_inner">
                            <div className="astro_chat_category_head">
                              <h5>Tap a category to see personalized questions or ask your own questions below</h5>
                            </div>
                            <div className="astro_chat_category_flex  ">
                              <motion.div initial={{ opacity: 0, y: 30 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.2, delay: 0.2 }} className="astro_category_cta_chatbot  ">
                                <Link
                                  to=""
                                  onClick={() => {
                                    setTopic("Family");
                                  }}
                                  className={topic === "Family" ? " active" : ""}
                                >
                                  <div className="category_chat_icon">
                                    <img src={family} alt="" />
                                  </div>
                                  <div className="category_chat_content">
                                    <h3>Family</h3>
                                  </div>
                                </Link>
                              </motion.div>
                              <motion.div initial={{ opacity: 0, y: 30 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.2, delay: 0.4 }} className="astro_category_cta_chatbot  ">
                                <Link
                                  to=""
                                  onClick={() => {
                                    setTopic("Career");
                                  }}
                                  className={topic === "Career" ? " active" : ""}
                                >
                                  <div className="category_chat_icon">
                                    <img src={career} alt="" />
                                  </div>
                                  <div className="category_chat_content">
                                    <h3>Career</h3>
                                  </div>
                                </Link>
                              </motion.div>
                              <motion.div initial={{ opacity: 0, y: 30 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.2, delay: 0.6 }} className="astro_category_cta_chatbot  ">
                                <Link
                                  to=""
                                  onClick={() => {
                                    setTopic("Business");
                                  }}
                                  className={topic === "Business" ? " active" : ""}
                                >
                                  <div className="category_chat_icon">
                                    <img src={business} alt="" />
                                  </div>
                                  <div className="category_chat_content">
                                    <h3>Business</h3>
                                  </div>
                                </Link>
                              </motion.div>
                              <motion.div initial={{ opacity: 0, y: 30 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.6, delay: 0.6 }} className="astro_category_cta_chatbot  ">
                                <Link
                                  to=""
                                  onClick={() => {
                                    setTopic("Finance");
                                  }}
                                  className={topic === "Finance" ? " active" : ""}
                                >
                                  <div className="category_chat_icon">
                                    <img src={finance} alt="" />
                                  </div>
                                  <div className="category_chat_content">
                                    <h3>Finance</h3>
                                  </div>
                                </Link>
                              </motion.div>
                              <motion.div initial={{ opacity: 0, y: 30 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.6, delay: 0.6 }} className="astro_category_cta_chatbot  ">
                                <Link
                                  to=""
                                  onClick={() => {
                                    setTopic("Love");
                                  }}
                                  className={topic === "Love" ? " active" : ""}
                                >
                                  <div className="category_chat_icon">
                                    <img src={lovebirds} alt="" />
                                  </div>
                                  <div className="category_chat_content">
                                    <h3>Love</h3>
                                  </div>
                                </Link>
                              </motion.div>
                              <motion.div initial={{ opacity: 0, y: 30 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.6, delay: 0.6 }} className="astro_category_cta_chatbot  ">
                                <Link
                                  to=""
                                  onClick={() => {
                                    setTopic("Marriage");
                                  }}
                                  className={topic === "Marriage" ? " active" : ""}
                                >
                                  <div className="category_chat_icon">
                                    <img src={marriage} alt="" />
                                  </div>
                                  <div className="category_chat_content">
                                    <h3>Marriage</h3>
                                  </div>
                                </Link>
                              </motion.div>
                            </div>
                          </div>
                        </div>
                        {(topic === "Family" || topic === "Career" || topic === "Business" || topic === "Finance" || topic === "Love" || topic === "Marriage") && (
                          <div className="astro_suggestions_wrapper">
                            <div className="astro_suggestion_inner">
                              <div className="chat_suggestion_head">
                                <label>{topic}</label>
                                <Link
                                  to="#"
                                  className=" "
                                  onClick={() => {
                                    setTopic("");
                                  }}
                                >
                                  <CloseIcon />
                                </Link>
                              </div>
                              <div className="chat_suggestion_body">
                                <div className="astro_suggestions_flex">
                                  {questions[topic]?.length > 0 &&
                                    questions[topic].map((question, index) => (
                                      <motion.div
                                        initial={{ opacity: 0, y: 30 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ duration: 0.2, delay: 0.2 * (index + 1) }}
                                        className="astro_suggestions_box"
                                        key={index}
                                        onClick={() => handleMessage(question)}
                                      >
                                        <div className="suggestion_flex">
                                          <div className="suggestion_icon">
                                            <TryOutlinedIcon fontSize="small" />
                                          </div>
                                          <div className="suggestion_content">
                                            <span>{question}</span>
                                          </div>
                                        </div>
                                      </motion.div>
                                    ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="desk_chat_head">
                      <div className="top-chatbar">
                        <div className="chat_title">
                          {isEditing ? (
                            <TextField
                              id="outlined-basic"
                              placeholder=" "
                              size="small"
                              type="text"
                              value={title}
                              onChange={(e) => setTitle(e.target.value)}
                              onBlur={handleInputBlur}
                              onKeyDown={handleKeyDown}
                              autoFocus
                            />
                          ) : (
                            <div className="d-flex align-items-center gap-3">
                              <h2 onClick={handleTitleClick}>{title ? title : "New chat"}</h2>
                              <span className="chattime">
                                <i className="las la-clock"></i> {getTimeDifference(chatTime === 0 ? new Date() : chatTime)}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="desk_chat_body">
                      <div className="chat_container">
                        <div className="desk_chat_panal">
                          <div className="desk_chat_inner_box ">
                            <div className="desk_chat_contentwrapper">
                              <div className="desk_chat_contentbox">
                                <div className="desk_chat_bodydown">
                                  <div className="desk_chat_body_Inner">
                                    <div className="desk_message_list_container">
                                      <div className="desk_message_list ">
                                        <div className="chat_message">
                                          {chatHistory &&
                                            chatHistory.length > 0 &&
                                            chatHistory.map((chat, index) => (
                                              <div key={index}>
                                                <div className="chat-box">
                                                  <div className="chat-flex">
                                                 
                                                    <div className="chat_content">
                                                      <div className="chat_sender">    <div className="chat_avtar">
                                                      <Avatar sx={{ bgcolor: deepOrange[500] }}>
                                                        {profile?.fullname?.split(/\s/).reduce((response, word) => (response += word.slice(0, 1)), "") ?? "NA"}
                                                      </Avatar>
                                                    </div> you</div>
                                                      <div className="chat_responcebox">
                                                        <div className="answer_content">
                                                          {chat.request !== "" ? (
                                                            <ReactReadMoreReadLess charLimit={350} readMoreText={"Read more ▼"} readLessText={"Read less ▲"}>
                                                              {chat.request}
                                                            </ReactReadMoreReadLess>
                                                          ) : (
                                                            "NA"
                                                          )}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div id="scrolling" ref={scrollingDivRef}></div>
                                                </div>
                                                <div className="chat-box">
                                                  <div className="chat-flex">
                                              
                                                    <div className="chat_content">
                                                      <div className="chat_sender">      <div className="chat_avtar">
                                                      <Avatar   src={astroai}>
                                                        AI
                                                      </Avatar>
                                                    </div> Rishi Kashyap AI</div>
                                                      <div className="chat_responcebox">
                                                        <div className="answer_wrapper">
                                                          <div className="answer_body">
                                                            {loading && index === chatHistory.length - 1 && (
                                                              <motion.div
                                                                initial={{ opacity: 0, y: 3 }}
                                                                animate={{ opacity: 1, y: 0 }}
                                                                transition={{ duration: 0.4, delay: 0.2 }}
                                                                className="skeleton_loader"
                                                              >
                                                                <ChatContentLoader />
                                                              </motion.div>
                                                            )}
                                                            {(chat.response || !loading) && (
                                                              <>
                                                                <motion.div
                                                                  initial={{
                                                                    opacity: 0,
                                                                    y: 5,
                                                                  }}
                                                                  animate={{
                                                                    opacity: 1,
                                                                    y: 0,
                                                                  }}
                                                                  transition={{
                                                                    duration: 0.4,
                                                                    delay: 0.2,
                                                                  }}
                                                                  className="answer_content   "
                                                                >
                                                                  {currentLanguages[index] === "hindi" ? (
                                                                    <>{translatedResponses[index] && <ReactMarkdown children={translatedResponses[index]} />}</>
                                                                  ) : (
                                                                    <>{chat.rawResponse && <ReactMarkdown children={chat.rawResponse} />}</>
                                                                  )}
                                                                </motion.div>
                                                                <div className="answer_actions">
                                                                  <div className="left_action">
                                                                    <div className="likedislike">
                                                                      <Tooltip className="blue-tooltip" title="Like" TransitionComponent={Zoom} placement="top" arrow>
                                                                        <Checkbox
                                                                          {...label}
                                                                          icon={<ThumbUpAltOutlinedIcon />}
                                                                          checkedIcon={<ThumbUpIcon />}
                                                                          // checked={true}
                                                                          onClick={() => {
                                                                            chat.thumbsup = true;
                                                                            chat.thumbsdown = false;
                                                                            sendFeedBack(chatId, chat.message_id, "thumbsup");
                                                                          }}
                                                                          checked={chat?.thumbsup === true}
                                                                        />
                                                                      </Tooltip>
                                                                      <Tooltip className="blue-tooltip" title="Dislike" TransitionComponent={Zoom} placement="top" arrow>
                                                                        <Checkbox
                                                                          {...label}
                                                                          icon={<ThumbDownAltOutlinedIcon />}
                                                                          checkedIcon={<ThumbDownIcon />}
                                                                          onClick={() => {
                                                                            chat.thumbsdown = true;
                                                                            chat.thumbsup = false;
                                                                            sendFeedBack(chatId, chat.message_id, "thumbsdown");
                                                                          }}
                                                                          checked={chat?.thumbsdown === true}
                                                                        />
                                                                      </Tooltip>
                                                                    </div>
                                                                    <div className="copychat">
                                                                      <Tooltip className="blue-tooltip" title="Copy Message" TransitionComponent={Zoom} placement="top" arrow>
                                                                        <Chip
                                                                          label={copyStatus[index] ? "Copied" : "Copy"}
                                                                          variant="outlined"
                                                                          onClick={() => handleClick(index, currentLanguages[index] === "english" ? chat.rawResponse : translatedResponses[index])}
                                                                          color={copyStatus[index] ? "success" : "primary"} // Setting color based on copy status
                                                                          icon={copyStatus[index] ? <CheckIcon fontSize="small" /> : null}
                                                                        />
                                                                      </Tooltip>
                                                                    </div>
                                                                    <div className="playpause">
                                                                      {isAudioPlaying[index] ? (
                                                                        <>
                                                                          <Tooltip className="blue-tooltip" title="Pause Audio" TransitionComponent={Zoom} placement="top" arrow>
                                                                            <Button
                                                                              variant="text"
                                                                              style={{
                                                                                textTransform: "capitalize",
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                gap: 4,
                                                                              }}
                                                                              onClick={() => handlePauseAudio(index)}
                                                                              className="text-danger"
                                                                            >
                                                                              <PauseCircleOutlineOutlinedIcon />
                                                                              <span>Pause</span>
                                                                            </Button>
                                                                          </Tooltip>
                                                                        </>
                                                                      ) : (
                                                                        <>
                                                                          {isAudioEnded[index] !== false && (
                                                                            <Tooltip className="blue-tooltip" title="Listen Audio" TransitionComponent={Zoom} placement="top" arrow>
                                                                              <Button
                                                                                variant="text"
                                                                                style={{
                                                                                  textTransform: "capitalize",
                                                                                  display: "flex",
                                                                                  alignItems: "center",
                                                                                  gap: 4,
                                                                                }}
                                                                                onClick={() => {
                                                                                  const textToSend = currentLanguages[index] === "english" ? cleanText(chat.rawResponse) : translatedResponses[index];
                                                                                  fetchAudioData(index, textToSend);
                                                                                }}
                                                                                className=" "
                                                                              >
                                                                                <PlayCircleFilledWhiteOutlinedIcon />
                                                                                <span>Listen</span>
                                                                              </Button>
                                                                            </Tooltip>
                                                                          )}
                                                                        </>
                                                                      )}
                                                                      {isAudioPlaying[index] === false && isAudioEnded[index] === false && (
                                                                        <Tooltip className="blue-tooltip" title="Resume Audio" TransitionComponent={Zoom} placement="top" arrow>
                                                                          <Button
                                                                            variant="text"
                                                                            style={{
                                                                              textTransform: "capitalize",
                                                                              display: "flex",
                                                                              alignItems: "center",
                                                                              gap: 4,
                                                                            }}
                                                                            onClick={() => handleResumeAudio(index)}
                                                                            className="text-success"
                                                                          >
                                                                            <PlayCircleFilledWhiteOutlinedIcon />
                                                                            <span>Resume</span>
                                                                          </Button>
                                                                        </Tooltip>
                                                                      )}
                                                                    </div>
                                                                    <div className="switchlanguage">
                                                                      <Tooltip className="blue-tooltip" title="Change Language" TransitionComponent={Zoom} placement="top" arrow>
                                                                        <FormControlLabel
                                                                          size="small"
                                                                          control={
                                                                            <IOSSwitch
                                                                              size="small"
                                                                              sx={{
                                                                                m: 1,
                                                                              }}
                                                                              fontSize="small"
                                                                              checked={currentLanguages[index] === "hindi"}
                                                                              onChange={() => handleLanguageToggle(index, chat.rawResponse)}
                                                                            />
                                                                          }
                                                                          label={currentLanguages[index] === "hindi" ? "हिंदी" : "English"}
                                                                        />
                                                                      </Tooltip>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </>
                                                            )}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            ))}
                                          {relatedQuestions !== null && !relatedQuestionsLoading && !(isFreeTrial && questionsAllowed === 0) && (
                                            <div className="related_wrapper chat_sections">
                                              <div className="related-head">
                                                <div className="sources_title">
                                                  <div className="sourse-icon">
                                                    <DynamicFeedOutlinedIcon fontSize="small" />
                                                  </div>
                                                  <h5>Related</h5>
                                                </div>
                                              </div>
                                              <div className="related_body">
                                                <div className="related_list">
                                                  <nav aria-label="main ">
                                                    <List>
                                                      {Object.entries(relatedQuestions).map(([key, question], index) => (
                                                        <React.Fragment key={key}>
                                                          <ListItem disablePadding>
                                                            <ListItemButton onClick={() => handleMessage(question)}>
                                                              <ListItemIcon>
                                                                <AddIcon fontSize="small" />
                                                              </ListItemIcon>
                                                              <ListItemText primary={question} />
                                                            </ListItemButton>
                                                          </ListItem>
                                                          {index < Object.keys(relatedQuestions).length - 1 && <Divider />}
                                                        </React.Fragment>
                                                      ))}
                                                    </List>
                                                  </nav>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <div className="bottom_chat_wrapper">
                  <ChatForm
                    chatLength={chatHistory?.length}
                    // chatType={"brain"}
                    // selectedText={selectedText}
                    // setSelectedText={setSelectedText}
                    request={request}
                    transcript={transcript}
                    listening={listening}
                    handleMic={handleMic}
                    setMicOn={setMicOn}
                    setRequest={setRequest}
                    handleMessage={handleMessage}
                    isFreeTrial={isFreeTrial}
                    hasExpired={hasExpired}
                    questionsAllowed={questionsAllowed}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={`asrtro_chat_chart_box ${isClassAdded === "Charts" ? "showchartmobile" : ""}`}>
            <div className="asrtro_chat_chart_body">
              <div className="report_menu_close">
                <Link to="" className="close closemenubar" onClick={() => setIsClassAdded(null)}>
                  X
                </Link>
              </div>
              <div className="chat_chart_cta">
                <div className="chat_chartcta_head">
                  <div className="chartcta_title">
                    <h3>Basic Details</h3>
                  </div>
                </div>
                {profile && birthData && isLoaded && (
                  <div className="astro-aside_cta_body p-0">
                    <div className="basic_details_chatbot">
                      <div className="basic_detail_head">
                        <div className="table_flex">
                          <div className="table_box">
                            <div className="table_row">
                              <div className="table_label">Name</div>
                              <div className="table_value">{profile.fullname}</div>
                            </div>

                            <div className="table_row">
                              <div className="table_label">Birth Date</div>
                              <div className="table_value">
                                {profile.birth_day}/{profile.birth_month}/{profile.birth_year}
                              </div>
                            </div>
                            <div className="table_row">
                              <div className="table_label">Birth Time</div>
                              <div className="table_value">{convertTo12HourFormat(profile.birth_hour, profile.birth_minute)}</div>
                            </div>
                            <div className="table_row">
                              <div className="table_label">Birth Place</div>
                              <div className="table_value">{profile.birth_place}</div>
                            </div>
                          </div>
                          <div className="table_box">
                            <div className="table_row">
                              <div className="table_label">Gender</div>
                              <div className="table_value">{profile.gender}</div>
                            </div>
                            <div className="table_row">
                              <div className="table_label">Nakshatra</div>
                              <div className="table_value">{birthData.naksahtra}</div>
                            </div>

                            <div className="table_row">
                              <div className="table_label">Ascendant</div>
                              <div className="table_value">{birthData.ascendant}</div>
                            </div>
                            <div className="table_row">
                              <div className="table_label">Moon Sign</div>
                              <div className="table_value">{birthData.moon_sign}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {!isLoaded && <TableLoader />}
              </div>
              <div className="chat_chart_cta">
                <div className="chat_chartcta_head">
                  <div className="chartcta_title">
                    <h3>Lagna / Ascendant / Basic Birth Chart</h3>
                  </div>
                </div>
                {kundliLoading ? (
                  <ContentLoader />
                ) : (
                  <div className="astro-aside_cta_body">
                    <div className="kundli_data_wrapper">{kundliData && <div dangerouslySetInnerHTML={{ __html: kundliData }} />}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="chat_action_mobile">
          <Backdrop open={openaction} />
          <SpeedDial ariaLabel="SpeedDial tooltip example" icon={<MoreHorizIcon />} onClose={handleCloseaction} onOpen={handleOpenaction} open={openaction}>
            {actions2
              .filter((action2) => !(action2.name === "New Chat" && chatHistory?.length === 0))
              .map((action2) => (
                <SpeedDialAction
                  key={action2.name}
                  icon={action2.icon}
                  tooltipTitle={action2.name}
                  tooltipOpen
                  onClick={() => {
                    handleCloseaction();
                    setIsClassAdded(action2.name);
                    if (action2.name === "New Chat") {
                      localStorage.removeItem("chat_id");
                      handleNewChat();
                    }
                  }}
                />
              ))}
          </SpeedDial>
        </div>
      </div>
    </div>
  );
};
