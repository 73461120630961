import React from "react";
import {} from "@mui/material";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import { Link } from "react-router-dom";
export default function PlanDuration(props) {
  const convertTime = (milliseconds) => {
    const seconds = milliseconds / 1000;
    const days = seconds / (60 * 60 * 24);
    if (days >= 1) {
      return `${Math.floor(days)} day(s)`;
    } else {
      const hours = seconds / (60 * 60);
      return `${Math.floor(hours)} hour(s)`;
    }
  };
  
  // Usage
  const RemainingTime = convertTime(props.timeRemaining);
  return (
    <>
      <div className="plan_duration_wrapper">
        <h5>
          {props.hasExpired
            ? "The plan has been expired"
            : props.isChat === true
            ? props.isFreeTrial === true && props.questionsAllowed > 0
              ? `You can ask ${props.questionsAllowed} question(s).`
              : `Free question limit exceeded. Free Trial ends in ${RemainingTime}.`
            :  `${RemainingTime}  remaining.`}{" "}
            {
              props.showLifeBanner?" To unlock your full life report - Go Premium! ":""
            }
          <Link to="/plans">
            {" "}
            <AutoFixHighIcon fontSize="small" /> Upgrade Now{" "}
          </Link>
        </h5>
      </div>
    </>
  );
}
