import React, { useEffect } from "react";

import logowblue from "../assets/images/logo.png";
import { CircularProgress } from "@mui/material";

export const LoaderPage = () => {
  useEffect(() => {
    document.documentElement.setAttribute("data-theme", "dark");
  }, []);

  return (
    <div>
      <div className="auth-page">
        <div className="container-fluid p-0">
          <div className="row g-0">
            <div className="col-xxl-12 col-lg-4 col-md-5">
              <div style={{ height: "100vh", display: "flex", alignItems: "center" }}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
                  <div style={{ marginBottom: "1rem", textAlign: "center" }}>
                    <img src={logowblue} alt="" style={{ height: "50px" }} />
                  </div>
                  <div style={{ textAlign: "center", marginTop: "1rem", display: "flex", alignItems: "center", gap: "0.5rem", justifyContent: "center", fontSize: "30px" }}>
                    <CircularProgress size={20} />
                    Loading your personalized dashboard. Please wait a moment...
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
