import React, { useState, useEffect } from "react";
import { TextField, InputAdornment, IconButton, Autocomplete, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import { Link } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import paymenttype from "../assets/images/pay_methods_branding.png";
import paymentlogo from "../assets/images/logo.png";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import FlagIcon from "@mui/icons-material/Flag";
import PaymentStatus from "../components/payment-status-modal";
import useRazorpay from "react-razorpay";
import MessageSnackbar from "../_layout/elements/snackbar";
import { useFormik } from "formik";
import host from "../host";
import * as Yup from "yup";
import { Country, State, City } from "country-state-city";
import tzlookup from "tz-lookup";
import moment from "moment-timezone";
const csc = Country.getAllCountries;

export const LifeReportPayment = () => {
  const [severity, setSeverity] = useState("success");
  const [snackbar, setSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const onClose = () => {
    setSnackbar(false);
  };

  //this below function for add folder   popup start//
  const [openpaymentstatus, setOpenpaymentstatus] = React.useState(false);
  const activepaymentstatus = () => setOpenpaymentstatus(true);
  const handleClosepaymentstatus = () => setOpenpaymentstatus(false);
  //this below function for add brain   popup end//

  //form functionality starts

  const [Razorpay] = useRazorpay();

  const [loading, setLoading] = useState(false);
  const [phoneError, setPhoneError] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [countryCode, setCountryCode] = useState("IN");
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  
  const [longitude, setLongitude] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [offsetTimezone, setOffsetTimezone] = useState(null);

  const validationSchema = Yup.object({
    name: Yup.string().required("First name is required"),
    email: Yup.string().email("Invalid email address").required("Email is required"),
    phoneNo: Yup.string()
      .required("Phone number is required")
      .matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, "Phone number is not valid"),
    gender: Yup.string().required("Gender is required").notOneOf(["Select"], "Please select a gender"),
    birthDay: Yup.date().required("Birth date is required").nullable(),
    country: Yup.string().required("Country is required").notOneOf([0, "0"], "Please select a country"),
    state: Yup.string().required("State is required").notOneOf([0, "0"], "Please select a state"),
    city: Yup.string().required("City is required").notOneOf([0, "0"], "Please select a city"),
    language: Yup.string().required("Language is required").notOneOf(["Select"], "Please select a language"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phoneNo: "",
      gender: "Select",
      birthDay: null,
      country: "0",
      state: "0",
      city: "0",
      language: "Select",
    },
    validationSchema: validationSchema,
  });

  const isFormInvalid = () => {
    return (
      formik.values.name === "" ||
      formik.values.email === "" ||
      formik.values.phoneNo === "" ||
      formik.values.gender === "Select" ||
      formik.values.birthDay === null ||
      formik.values.country === "0" ||
      formik.values.state === "0" ||
      formik.values.city === "0" ||
      formik.values.language === "Select"
    );
  };

  

  useEffect(() => {
    const List = csc();
    if (List && List.length > 0) {
      setCountries((prev) => {
        return [
          ...prev,
          ...List.map((country) => {
            return { code: country.isoCode, name: country.name };
          }),
        ];
      });
    }
  }, []);

  useEffect(() => {
    const statelist = State.getStatesOfCountry(formik.values.country);
    setStates([]);

    if (statelist && statelist.length > 0) {
      setStates((prev) => {
        return [
          ...prev,
          ...statelist.map((state_item) => {
            return { code: state_item.isoCode, name: state_item.name };
          }),
        ];
      });
    }
  }, [formik.values.country]);

  useEffect(() => {
    const cityList = City.getCitiesOfState(formik.values.country, formik.values.state);
    setCities([]);

    if (cityList && cityList.length > 0) {
      setCities((prev) => {
        return [
          ...prev,
          ...cityList.map((city_item) => {
            return {latitude: city_item.latitude, longitude: city_item.longitude, name: city_item.name };
          }),
        ];
      });
    }
  }, [formik.values.state]);

  useEffect(() => {
    let city = cities.find((city) => city.name === formik.values.city);

    if (city) {
      setLongitude(city.longitude);
      setLatitude(city.latitude);

      let timezone = tzlookup(city.latitude, city.longitude);
      let offset = moment.tz(timezone).utcOffset() / 60;

      setOffsetTimezone(offset);
    }
  }, [formik.values.city]);

  const handlePaymentFail = (paymentResponse) => {
    fetch(
      (host.astro_backend + `/report_payment/payment_failure?order_id=${paymentResponse.error.metadata.order_id}&payment_id=${paymentResponse.error.metadata.payment_id}&error_code=${paymentResponse.error.code}&error_description=${paymentResponse.error.description}&error_reason=${paymentResponse.error.reason}`),
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        // body: JSON.stringify({
        //   order_id: paymentResponse.error.metadata.order_id,
        //   payment_id: paymentResponse.error.metadata.payment_id,
        //   error_code: paymentResponse.error.code,
        //   error_description: paymentResponse.error.description,
        //   error_reason: paymentResponse.error.reason,
        // }),
      }
    )
      .then((res) => {
        setLoading(false);
        return res.json();
      })
      .then((data) => {
        if (data.success) {
          // navigate(`/subscription-payment-notapprove/${data.transaction.id}`);
        } else {
          console.log("else");
        }
      })
      .catch((_err) => {
        setLoading(false);
      });
  };

  const makePayment = async (razorpayData) => {

    const options = {
      key: host.razorpayKey,
      order_id: razorpayData.id,
      amount: razorpayData.amount,
      currency: razorpayData.currency,
      handler: async function (response) {
        fetch(host.astro_backend +"/report_payment/verify_payment",
          {
            method: "POST",
            headers: {
              "Content-type": "application/json",
            },
            body: JSON.stringify(response),
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            if (data) {
              // navigate(`/subscription-payment-approve/${data.transaction.id}`);
              makeCheck(razorpayData.id);
              // activepaymentstatus();
            } 
          })
          .catch((err) => {
            console.log(err);
            console.error("Error handling payment by server:", err);
          });
      },
    };

    // Initialize Razorpay instance and open payment popup
    const rzp = new Razorpay(options);
    rzp.open();

    rzp.on("payment.failed", (paymentResponse) => {
      handlePaymentFail(paymentResponse);
      // navigate("/subscription-payment-notapprove");
      rzp.close();
    });
  };

  const makeOrder = async () => {
    if (latitude === null || longitude === null || offsetTimezone === null) {
      setSeverity("error");
      setMessage("Please select a valid location");
      setSnackbar(true);
      return;
    }
    const birth_Day = new Date(formik.values.birthDay);

    fetch(host.astro_backend + "/report_payment/order", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        // Authorization: "Bearer " + auth.org,
      },
      body: JSON.stringify({
        fullname: formik.values.name,
        email: formik.values.email,
        phone_number: formik.values.phoneNo,
        gender: formik.values.gender,
        birth_year: birth_Day.getFullYear(),
        birth_month: birth_Day.getMonth() + 1,
        birth_day: birth_Day.getDate(),
        birth_hour: birth_Day.getHours(),
        birth_minute: birth_Day.getMinutes(),
        birth_second: 0,
        birth_place: formik.values.city,
        lat: latitude,
        long: longitude,
        timezone: 1,
        report_language: formik.values.language,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data) {
          makePayment(data);
        } else {
          setSeverity("error");
          setMessage(data.message);
          setSnackbar(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setSeverity("error");
        setMessage("Something went wrong !");
        setSnackbar(true);
      });
  };
  
  const makeCheck = async (orderId) => {
    
    fetch(
      `${host.astro_backend}/report_payment/check_payment?order_id=${orderId}`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          // Authorization: "Bearer " + auth.org,
        },
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data) {
          console.log(data)
          // const {
          //   success_data: { order_id, total_amount, subscription_name, credits_added, total_credits},
          // } = data;
          // setOrderId(order_id || "");
          // setAmount(total_amount || "");
          // setPlanName(
          //   subscription_name === "Bundle 1"
          //     ? "Standard"
          //     : subscription_name === "Bundle 2"
          //     ? "Pro"
          //     : subscription_name === "Bundle 3"
          //     ? "Max"
          //     : "-"
          // );
          // setCreditsAdded(credits_added);
          // setTotalCredits(total_credits);
        } else {
          console.log("Data failed");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //form functionality ends

  return (
    <div>
      <MessageSnackbar open={snackbar} autoHideDuration={3000} onClose={onClose} severity={severity} message={message} />

      <div className="paymentpage-container">
        <div className="content-container">
          <div className="payment_content">
            <div className="payment_flex">
              <div className="payment_left_content">
                <div className="payment_logo">
                  <Link to="/life-report-campaining">
                    <img src={paymentlogo} alt="logo" height={50} />
                  </Link>
                </div>
                <div className="payment_form_title">
                  <h3>Premium Personalised Kundali By Astroguruji</h3>
                </div>
                <div className="ql-editor" data-gramm="false" contentEditable="false">
                  <p>Please Provide:</p>
                  <p>
                    Kindly Fill in your <strong>Full Name, Date of Birth</strong>, <strong>Place of Birth</strong>, <strong>Time of Birth, AM/PM</strong> and <strong>Gender </strong>correctly. Rest
                    assured, we do not require any other personal or sensitive details.
                  </p>
                  <p>It's crucial to fill out this Data accurately to make your premium personalised kundali.</p>
                  <p>
                    <strong>Your Premium Personalised Kundali will be sent on your registered email id in 5-6 Working Days.</strong>
                  </p>
                  <p>T&amp;C Applied.</p>
                  <p>
                    <strong>NOTE: PLEASE ENTER YOUR PHONE NO. ACTIVE ON WHATSAPP</strong>
                  </p>
                </div>
                <div className="support-details">
                  <h6>Contact Us: </h6>
                  <div className="sub-detail">
                    <Link to="mailto:support@navivibe.com">
                      <div className="contact_icon">
                        <EmailOutlinedIcon fontSize="small" />
                      </div>
                      <span>support@navivibe.com</span>
                    </Link>
                  </div>
                  <div className="sub-detail">
                    <Link to="tel:9835601376">
                      <div className="contact_icon">
                        <CallOutlinedIcon fontSize="small" />
                      </div>
                      <span>9835601376</span>
                    </Link>
                  </div>
                </div>

                <div className="details-footer ">
                  <div className="details-footer-top mb-3">
                    <img alt="rzp-logo" src="https://cdn.razorpay.com/logo.svg" height={25} />
                  </div>
                  <div className="details-footer-bottom">
                    <p>
                      Want to create page like this for your Business? Visit
                      <Link to="" target="_blank">
                        Razorpay Payment Pages
                      </Link>
                      to get started!
                    </p>
                    <div className="details-footer-bottom-actions">
                      <p>
                        <Link to=" " target="_blank" className="report-message black-link-text">
                          <span className="Icon undefined">
                            <FlagIcon fontSize="small" />
                          </span>
                          Report Page{" "}
                        </Link>
                      </p>

                      <div className="terms_link">
                        <p>
                          {" "}
                          <Link to="/privacy" target="blank">
                            Terms & Conditions, Privacy Policy, Refund Policy
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="payment_form_box">
                <div className="payment_form">
                  <div className="payment_form_title">
                    <h3>Payment Details</h3>
                  </div>
                  <div className="row mt-3">
                    <div className="col-xxl-12 col-lg-12 col-md-12 col-12">
                      <div className="form-group">
                        <div className="form_flex">
                          <label className="form-label">
                            {" "}
                            Name <span className="text-danger">*</span>
                          </label>
                          <div className="form_input">
                            <FormControl fullWidth>
                              <TextField
                                size="small"
                                type="text"
                                placeholder="Enter user name  "
                                name="name"
                                disabled={loading}
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                              />
                            </FormControl>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-12 col-lg-12 col-md-12 col-12">
                      <div className="form-group">
                        <div className="form_flex">
                          <label className="form-label">
                            Email <span className="text-danger">*</span>
                          </label>
                          <div className="form_input">
                            <FormControl fullWidth>
                              <TextField
                                size="small"
                                type="text"
                                placeholder="Enter email"
                                name="email"
                                disabled={loading}
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                              />
                            </FormControl>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-12 col-lg-12 col-md-12 col-12">
                      <div className="form-group">
                        <label className="form-label">
                          {" "}
                          Phone Number <span className="text-danger">*</span>
                        </label>
                        <PhoneInput
                          size="small"
                          className={`worldwidephone ${formik.touched.phoneNo && formik.errors.phoneNo ? "error" : ""}`}
                          international
                          countryCallingCodeEditable={false}
                          value={formik.values.phoneNo}
                          disabled={loading}
                          onChange={(e) => {
                            formik.setFieldValue("phoneNo", e ? e : "");
                          }}
                          onBlur={() => formik.setFieldTouched("phoneNo")}
                          defaultCountry="IN"
                          onCountryChange={(e) => {
                            setCountryCode(e);
                          }}
                        />
                        {formik.touched.phoneNo && formik.errors.phoneNo ? <div className="error text-danger mt-2">{formik.errors.phoneNo}</div> : null}
                      </div>
                    </div>
                    <div className="col-xxl-12 col-lg-12 col-md-12 col-12">
                      <div className="form-group  ">
                        <div className="form_flex">
                          <label className="form-label">Select Gender</label>
                          <div className="form_input">
                            <FormControl fullWidth size="small">
                              <Select
                                labelId="demo-select-small-label"
                                defaultValue={Select}
                                name="gender"
                                placeholder="Select request"
                                value={formik.values.gender}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.gender && Boolean(formik.errors.gender)}
                              >
                                <MenuItem value="Select">Select-- </MenuItem>
                                <MenuItem value="Male">Male </MenuItem>
                                <MenuItem value="Female">Female</MenuItem>
                                <MenuItem value="Other">Other</MenuItem>
                              </Select>
                              {formik.touched.gender && formik.errors.gender ? <div className="error text-danger mt-2">{formik.errors.gender}</div> : null}
                            </FormControl>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-12 col-lg-12 col-md-12 col-12">
                      <div className="form-group datetimepicker">
                        <div className="form_flex">
                          <label className="form-label">Birth Details</label>
                          <div className="form_input">
                            <FormControl fullWidth>
                              <LocalizationProvider dateAdapter={AdapterDayjs} size="small">
                                <DemoContainer components={["DateTimePicker", "MobileDateTimePicker", "DesktopDateTimePicker", "StaticDateTimePicker"]}>
                                  <DateTimePicker
                                    name="birthDay"
                                    value={formik.values.birthDay}
                                    onChange={(value) => formik.setFieldValue("birthDay", value)}
                                    renderInput={(params) => <TextField {...params} />}
                                  />
                                </DemoContainer>
                              </LocalizationProvider>
                            </FormControl>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-12 col-lg-12 col-md-12 col-12 mt-">
                      <div className="form-group">
                        <div className="form_flex mb-2">
                          <label style={{ fontWeight: "600", fontSize: "16px" }}>Birth Place</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-12 col-lg-12 col-md-12 col-12">
                      <div className="form-group  ">
                        <div className="form_flex">
                          <label className="form-label">Country</label>
                          <div className="form_input">
                            <FormControl fullWidth>
                              <Select
                                size="small"
                                name="country"
                                value={formik.values.country}
                                onChange={(e) => {
                                  formik.setFieldValue("country", e.target.value);
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched("country");
                                }}
                                error={formik.touched.country && Boolean(formik.errors.country)}
                              >
                                <MenuItem value={0}> --Select Country--</MenuItem>
                                {countries &&
                                  countries.length > 0 &&
                                  countries.map((loc) => {
                                    return <MenuItem value={loc.code}>{loc.name}</MenuItem>;
                                  })}
                              </Select>
                              {formik.touched.country && formik.errors.country ? <div className="error text-danger mt-2">{formik.errors.country}</div> : null}
                            </FormControl>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-12 col-lg-12 col-md-12 col-12">
                      <div className="form-group  ">
                        <div className="form_flex">
                          <label className="form-label">State</label>
                          <div className="form_input">
                            <FormControl fullWidth>
                              <Select
                                name="state"
                                size="small"
                                value={formik.values.state}
                                onChange={(e) => {
                                  formik.setFieldValue("state", e.target.value);
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched("state");
                                }}
                                error={formik.touched.state && Boolean(formik.errors.state)}
                              >
                                <MenuItem value={0}> --Select State--</MenuItem>
                                {states &&
                                  states.length > 0 &&
                                  states.map((loc) => {
                                    return <MenuItem value={loc.code}>{loc.name}</MenuItem>;
                                  })}
                              </Select>
                              {formik.touched.state && formik.errors.state ? <div className="error text-danger mt-2">{formik.errors.state}</div> : null}
                            </FormControl>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-12 col-lg-12 col-md-12 col-12">
                      <div className="form-group  ">
                        <div className="form_flex">
                          <label className="form-label">City</label>
                          <div className="form_input">
                            <FormControl fullWidth>
                              <Select
                                name="city"
                                size="small"
                                value={formik.values.city}
                                onChange={(e) => {
                                  formik.setFieldValue("city", e.target.value);
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched("city");
                                }}
                                error={formik.touched.city && Boolean(formik.errors.city)}
                              >
                                <MenuItem value={0}> --Select City--</MenuItem>
                                {cities &&
                                  cities.length > 0 &&
                                  cities.map((loc) => {
                                    return <MenuItem value={loc.name}>{loc.name}</MenuItem>;
                                  })}
                              </Select>
                              {formik.touched.city && formik.errors.city ? <div className="error text-danger mt-2">{formik.errors.city}</div> : null}
                            </FormControl>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-12 col-lg-12 col-md-12 col-12">
                      <div className="form-group  ">
                        <div className="form_flex">
                          <label className="form-label">Report Language</label>
                          <div className="form_input">
                            <FormControl fullWidth size="small">
                              <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                name="language"
                                defaultValue={0}
                                placeholder="Select request"
                                value={formik.values.language}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.language && Boolean(formik.errors.language)}
                              >
                                <MenuItem value={"Select"}>--Select--</MenuItem>
                                <MenuItem value={"Hindi"}>Hindi</MenuItem>
                                <MenuItem value={"English"}>English</MenuItem>
                                <MenuItem value={"Gujarati"}>Gujarati</MenuItem>
                                <MenuItem value={"Kannada"}>Kannada</MenuItem>
                              </Select>
                              {formik.touched.language && formik.errors.language ? <div className="error text-danger mt-2">{formik.errors.language}</div> : null}
                            </FormControl>
                            <span className="d-block">
                              {" "}
                              <small
                                className="mt-2"
                                style={{
                                  fontSize: "11px",
                                  lineHeight: "18px",
                                  display: "block",
                                }}
                              >
                                Please select in which language you need your kundali report
                              </small>{" "}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-12 col-lg-12 col-md-12 col-12">
                      <div className="form-group">
                        <div className="form_flex">
                          <label className="form-label"> Payment </label>
                          <div className="form_input">
                            <div className="payment_prize">₹1999</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-12 col-lg-12 col-md-12 col-12">
                      <div className="form-group">
                        <div className="form_flex mb-2 justify-content-center">
                          <div className="payment_type text-right">
                            <img src={paymenttype} height={11} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-12 col-lg-12 col-md-12 mt-3">
                      <div className="form-group">
                        <Link to="#">
                          <button
                            className="btn btn-primary w-100  "
                            type="button"
                            disabled={isFormInvalid()}
                            onClick={() => {
                              makeOrder();
                            }}
                          >
                            Pay ₹1999.00
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <PaymentStatus openpaymentstatus={openpaymentstatus} handleClosepaymentstatus={handleClosepaymentstatus} />
    </div>
  );
};
