import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import HeaderPage from '../_layout/elements/header';
import FooterPage from '../_layout/elements/footer';
export const Privacy  = () => {
const defaultOptions = {
loop: true,
autoplay: true,
};
return (
<div>
   <div className='inner-header'>
      <HeaderPage />
   </div>
   <section className='padding_medium  minibanner  blog-banner'  >
      <div className='container'>
         <div className='row align-items-center justify-content-between'>
            <div className='col-md-12'>
               <div className='minibanner-content'>
                  <h2>Terms & Conditions </h2>
                  <span className='d-block mt-3'>Last updated: December 12, 2023</span>
               </div>
            </div>
         </div>
      </div>
   </section>
   <section className=" termsandcondition padding_medium  ">
      <div className="container">
         <div className="row  ">
            <div className="col-12">
               <div className="row">


                  
                  <div className="col-md-12">

                  <div className="occasion-tab">
                <ul className="nav nav-tabs  ">
                <li className="nav-item">
                    <a className="nav-link active" data-bs-toggle="tab" href="#termscondition"> Terms &amp; Conditions </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link " data-bs-toggle="tab" href="#privacypolicy"> Privacy Policy </a>
                  </li>
                 
                  <li className="nav-item">
                    <a className="nav-link" data-bs-toggle="tab" href="#refundpolicy"> Refund &amp; Cancellation Policy </a>
                  </li>
                </ul>
              </div>

              <div className="tab-content">
            
                <div className="tab-pane fade show active" id="termscondition">
                 
                      <div className="terms-content blog-section blog-content">
                       
                        <h4>Welcome to NaviVibe</h4>
                        <p>By accessing or using our app, you agree to be bound by the following terms and conditions (the "Terms"). If you do not agree with these Terms, do not use the app.</p>
                        
                        <h4>1. Services Provided </h4>
                        <p>NaviVibe provides astrology service to its users. Users must provide their full name, phone number, email address, date of birth, time of birth, and place of birth to receive a variety of personalized astrological services like horoscope, chatbot, life report and others.</p>
                        <h4>2. User Accounts </h4>
                        <p>To access certain features of our app, you must register and create an account. You are responsible for maintaining the confidentiality of your account and password and for all activities that occur under your account. You agree to provide accurate and complete information when creating your account and to keep this information updated.</p>
                        
                        <h4>3. Privacy Policy </h4>
                        <p>Our Privacy Policy, which describes how we handle and protect your personal data, is available on our website. By using our app, you agree to the collection, processing, and use of your data as described in the Privacy Policy.</p>
                        <h4>4. Payment Terms </h4> 
                        <p>Some features require payment before you can access them. Payments are processed through a secure third-party payment gateway. By making a payment, you agree to provide the payment gateway with valid and updated payment information and authorize us to charge such for all purchased services and applicable fees.</p>
                        <h4>5. Intellectual Property </h4>
                        <p>All content provided through the app, including text, graphics, logos, images, as well as the trademark, is owned by or licensed to NaviVibe and is protected by copyright and intellectual property laws. You may not reproduce, distribute, modify, or use any content without express permission from NaviVibe.</p>
                        <p>We retain all rights, title, and interest in and to the Platform and its content. You acknowledge that you do not acquire any ownership rights by using the Platform.</p>
                        <h4>6. Disclaimers </h4>
                        <p>The astrological advice and reports provided by the app are based on astrological principles. While we strive for accuracy, we do not guarantee that the content of the reports will be accurate or reliable. You agree that the use of the app's content is at your own risk.</p>
                        <h4>7. Limitation of Liability </h4>
                        <p>NaviVibe will not be liable for any direct, indirect, incidental, consequential, or punitive damages arising from your use of the app or services.</p>
                        <h4>8. Changes to Terms and Conditions  </h4>
                        <p>We reserve the right to modify these Terms at any time. We will notify you of any changes by posting the new Terms on the app. Your continued use of the app after such changes will constitute your consent to the new Terms.</p>
                        <h4>9. Termination   </h4>
                        <p>We may terminate or suspend your account and bar access to the app immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms.</p>
                        <h4>10. Indemnification    </h4>
                        <p>You agree to defend, indemnify, and hold harmless NaviVibe and its licensee and licensors, and their employees, contractors, agents, officers, and directors, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees), resulting from or arising out of a) your use and access of the app, by you or any person using your account and password, or b) a breach of these Terms.</p>
                        <h4>11. Contact Us</h4>
                        <p>If you have any questions about these Terms, please contact us at   <Link to="mailto:support@navivibe.com">support@navivibe.com </Link></p>
                      </div>
                
                </div>
                <div className="tab-pane fade  " id="privacypolicy">
                  <div className="row">
                    <div className="col-md-12">
                   
                          <div className="terms-content blog-section blog-content">

                          <h3>Welcome to NaviVibe!</h3>

                        
                        <p>We are committed to protecting your privacy. This Privacy Policy outlines our policies and procedures on the collection, use, and disclosure of your information when you use our app and informs you about your privacy rights and how the law protects you.</p>
                     
                        <h3>Interpretation and Definitions</h3>
                        <p>For the purposes of this Privacy Policy:</p>
                     
                        <div className='blog-list'>
                        <ul  >
                           <li> <b>Account </b>  <br/> means a unique account created for You to access our Service or parts of our Service.</li>
                           <li > <b>Personal Data </b>   <br/> is any information that relates to an identified or identifiable individual.</li>
                           <li > <b>Service </b>   <br/> refers to the NaviVibe app.</li>
                         
                           <li > <b>Usage Data </b>   <br/> refers to data collected automatically, either generated by the Service or from the Service infrastructure itself (for example, the time and date of your app usage).</li>
                           <li > <b> You </b>   <br/> means the individual accessing or using our Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
                           <li > <b> 	Personal Data</b>  <br/>  is any information that relates to an identified or identifiable individual.</li>
                         
                        </ul>

                        </div>
                        <h2>Collecting and Using Your Personal Data</h2>
                        <h3>Types of Data Collected</h3>
                        <h4>Personal Data</h4>
                        <p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to:</p>
                      
                        <div className='blog-list'>
                        <ul  >
                           <li>Basic personal details </li>
                         <li>Interactions with the application</li>
                        
                        </ul>

                        </div>
                    
                     <h4>Usage Data</h4>
                     <p>Usage Data is collected automatically when using the Service.</p>
                     <p>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
                   
                     <h4>Use of Your Personal Data</h4>
                     <p>The Company may use Personal Data for the following purposes:</p>
                     <div className='blog-list'>
                     <ul >
                        <li> <b>To provide and maintain our Service :</b> <br/> including to monitor the usage of our Service.</li>
                        <li> <b>To manage Your Account: </b>  <br/>  to manage your registration as a user of the Service.</li>
                        <li> <b>For the performance of a contract: </b> <br/>  to develop, comply with, and undertake the purchase contract for the products, services, or reports you have purchased.</li>
                        <li> <b>To contact You:</b> <br/> To contact you by email, telephone calls, or other equivalent forms of electronic communication, such as mobile application's push notifications regarding updates or informative communications related to the functionalities, products, or contracted services.</li>
                        <li> <b>To provide customer support.</b> <br/> To contact you by email, telephone calls, or other equivalent forms of electronic communication, such as mobile application's push notifications regarding updates or informative communications related to the functionalities, products, or contracted services.</li>
                   
                        <li> <b>For business transfers :</b> <br/> We may use your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our assets, in which Personal Data held by us about our Service users is among the assets transferred.</li>
                     </ul>
                     </div>
                     <h2 className='mt-3 mb-3'>We may share Your personal information in the following situations: </h2>
                     <div className='blog-list'>
                     <ul >
                        <li> <b>With Service Providers :</b> <br/>  We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You.</li>
                        <li> <b>For business transfers :</b> <br/>  We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.</li>
                        <li> <b>With Affiliates :</b> <br/>We may share your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners, or other companies that We control or that are under common control with us.</li>
                        <li> <b>With business partners :</b> <br/>  We may share your information with Our business partners to offer you certain products, services, or promotions.</li>
                        <li> <b>With other users :</b> <br/>   When you share personal information or otherwise interact in public areas with other users, such information may be viewed by all users and may be publicly distributed outside.</li>
                        <li> <b>WWith Your consent :</b> <br/> We may disclose your personal information for any other purpose with your consent.</li>
                     </ul>
                     </div>
                     <h3>Retention of Your Personal Data</h3>
                     <p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
                     <p>The company will also retain usage data for internal analysis purposes. Usage data is generally retained for a shorter period, except when used to strengthen the security or improve the functionality of our service, or we are legally obligated to retain this data for longer periods.</p>
                     <h3>Transfer of Your Personal Data</h3>
                     <p>Your information, including personal data, is processed at the company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.</p>
                     <p>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.</p>
                     <p>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</p>
                     <h3>Delete Your Personal Data</h3>
                     <p>You have the right to delete or request that We assist in deleting the Personal Data that We have collected about You.</p>
                     <p>Our Service may give You the ability to delete certain information about You from within the Service.</p>
                     <p>You may update, amend, or delete Your information at any time by signing in to Your Account, if you have one, and visiting the account settings section that allows you to manage Your personal information. You may also contact Us to request access to, correct, or delete any personal information that You have provided to Us.</p>
                     <p>Please note, however, that We may need to retain certain information when we have a legal obligation or lawful basis to do so.</p>
                     <h3>Disclosure of Your Personal Data</h3>
                     <h5>Business Transactions</h5>
                     <p>If the company is involved in a merger, acquisition, or asset sale, your personal data may be transferred. We will provide notice before your personal data is transferred and becomes subject to a different privacy policy.</p>
                     <h5>Law enforcement</h5>
                     <p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
                     <h5>Other legal requirements</h5>
                     <p>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>
                     <div className='blog-list'>
                     <ul >
                        <li> Comply with a legal obligation</li>
                        <li> Protect and defend the rights or property of the Company</li>
                        <li> Prevent or investigate possible wrongdoing in connection with the Service</li>
                        <li> Protect the personal safety of Users of the Service or the public</li>
                        <li> Protect against legal liability</li>
                     </ul>
                     </div>
                     <h5>Security of Your Personal Data</h5>
                     <p>The security of your personal data is important to us but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>
                     <h5>Links to Other Websites</h5>
                     <p>Our service may contain links to other websites that are not operated by us. If you click on a third-party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.</p>
                     <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third-party sites or services.</p>
                     <h5>Changes to this Privacy Policy</h5>
                     <p>We may update Our privacy policy sometimes. We will notify you of any changes by posting the new privacy policy on this page.</p>
                     <p>We will tell you via email and/or a prominent notice on Our service, before the change becomes effective and update the "Last updated" date at the top of this Privacy Policy.</p>
                     <p>You are advised to review this privacy policy periodically for any changes. Changes to this privacy policy are effective when they are posted on this page.</p>
                     <h5>Contact Us</h5>
                     <p>If you have any questions about this privacy policy, you can write to us at   <Link to="mailto:support@navivibe.com">support@navivibe.com </Link></p>
              

                   
                        
                          </div>
                 
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="refundpolicy">
               
                      <div className="terms-content blog-section blog-content">
<h3>Introduction</h3>

                        <p>At NaviVibe, we strive to provide our users with a high-quality personalized astrology service. We understand that plans can change, which may lead to needing to cancel a purchase. This policy outlines the terms under which refunds and cancellations are processed.</p>
                        <h4>Refunds and Cancellations</h4>
                        <div className='blog-list'>
                     <ul >
                        <li> <b>General Policy: </b> <br/> All paid services provided by NaviVibe are paid for in advance and are non-refundable. </li>
                        <li> <b>Exceptional Circumstances:  </b>  <br/>   If you believe there was an error in billing or a significant mistake, please contact us. We will evaluate such cases individually and may offer a refund or issue a new report at our discretion.</li>
                        <li> <b>Cancellation of Service:  </b> <br/> Once a service is ordered and work has begun, the order cannot be canceled as the service is deemed to have commenced and is immediately processed by our servers and astrology experts.</li>
                        <li> <b>No Refunds for Completed Reports and Consultations: </b> <br/> Since our services are personalized and digitally delivered, we cannot offer refunds once a service has been completed.</li>
                        
                     </ul>
                     </div>
                        
            
                        <h4> Changes to This Policy</h4>
                        <p>We reserve the right to revise this refund and cancellation policy at any time. Any changes will be effective immediately upon posting the updated policy on our app or website. We will make reasonable efforts to inform users of any changes through our app or via email.</p>
                        <h4> Contact Us</h4>
                        <p>If you have any questions or concerns about this policy or wish to request a review of your case for a potential refund, please reach out to us at support@navivibe.com We are here to help and ensure your satisfaction with our services.</p>
                        <h4>Acknowledgment</h4>
                        <p>By ordering a report from NaviVibe, you acknowledge that you have read, understood, and agree to be bound by this refund and cancellation policy. If you do not agree with this policy, do not proceed with your purchase.</p>
                      </div>
               
                </div>
              </div>



                   
               </div>
               </div>
            </div>
         </div>
      </div>
   </section>
   <FooterPage />
</div>
)
}