import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { changeAuth } from "../actions/loginAction";
import { useDispatch } from "react-redux";
import "react-phone-number-input/style.css";
import { styled } from "@mui/material/styles";
import { Switch } from "@mui/material";
import HeaderPage from "../_layout/elements/header";
import FooterPage from "../_layout/elements/footer";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import host from "../host";
import MessageSnackbar from "../_layout/elements/snackbar";
import PageLoader from "../components/loader/page-loader";
export const Plan = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = localStorage.getItem("login") === "true";

  // useEffect(() => {
  //   if (!auth) {
  //     navigate("/logout");
  //   }
  // }, [auth]);
  const [severity, setSeverity] = useState("success");
  const [snackbar, setSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const onClose = () => {
    setSnackbar(false);
  };

  const IOSSwitch = styled((props) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(27px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: theme.palette.mode === "dark" ? "#f16122" : "#f16122",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#f16122" : "#f16122",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));
  const [plans, setPlans] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const convertTime = (milliseconds) => {
    const seconds = milliseconds / 1000;
    const days = seconds / (60 * 60 * 24);
    if (days >= 1) {
      return `${Math.floor(days)} day(s)`;
    } else {
      const hours = seconds / (60 * 60);
      return `${Math.floor(hours)} hour(s)`;
    }
  };

  const getPlans = () => {
    setIsLoaded(false);
    fetch(host.astro_backend + `/plans`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 403) {
          dispatch(changeAuth(false, null));
          navigate("/logout");
        } else {
          setIsLoaded(true);
          setSeverity("error");
          setMessage("Something went wrong");
          setSnackbar(true);
        }
      })
      .then((data) => {
        if (data) {
          setPlans(data);
          setIsLoaded(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoaded(true);
      });
  };

  const [isFreeTrial, setIsFreeTrial] = useState(null);
  const [isFullReport, setIsFullReport] = useState(null);
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [questionsAllowed, setQuestionsAllowed] = useState(null);
  const [hasExpired, setHasExpired] = useState(false);

  const getUserPlan = () => {
    fetch(host.astro_backend + `/plans/user/plan`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 403) {
          dispatch(changeAuth(false, null));
          navigate("/login");
        } else {
          setSeverity("error");
          setMessage("Something went wrong");
          setSnackbar(true);
        }
      })
      .then((data) => {
        if (data) {
          if (data.is_free_trial) {
            setIsFreeTrial(true);
          } else {
            setIsFreeTrial(false);
          }
          if (data.is_full_life_report) {
            setIsFullReport(true);
          } else {
            setIsFullReport(false);
          }
          setQuestionsAllowed(data.questions_remaining);

          // Calculate time remaining until expiration
          const currentDate = new Date();
          const expirationDate = new Date(data.expires_at);
          const temp = expirationDate - currentDate;
          setTimeRemaining(temp);
          if (currentDate > expirationDate) {
            setHasExpired(true);
          } else {
            setHasExpired(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getPlans();
    getUserPlan();
  }, []);
  return (
    <div>
      <MessageSnackbar open={snackbar} autoHideDuration={3000} onClose={onClose} severity={severity} message={message} />
      <div className="inner-header">
        <HeaderPage />
      </div>
      <section className="padding_medium  minibanner  plan  ">
        <div className="container" style={{ position: "relative" }}>
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="minibanner-content">
                <h2 className=" ">
                  {" "}
                  <span className="text-blue"> Plan</span> & Pricing{" "}
                </h2>
                Current plan: <span className="badge Standard">{isFreeTrial ? "Free Tier" : isFullReport ? "Premium Tier" : "Basic Tier"}</span>
                <br/>
              {" "}Time remaining: <span>{hasExpired ? "Your plan has expired" : `${convertTime(timeRemaining)}`}</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      {isLoaded ? (
        <section className="padding_medium">
          <div className="container" style={{ position: "relative" }}>
            <div className="row justify-content-center">
              <div className="col-md-10">
                <div className="plan-tables-container">
                  <div className="plan-table-item">
                    <div className="plan-title">
                      <h3>Trial</h3>
                      <div className="pricing-description">All bare essentials for low-volume testing with limited results analysis.</div>
                    </div>
                    <div className="plan-cost-box">
                      <div className="plan-cost">
                        <h5>FREE</h5>
                      </div>
                    </div>
                    <div className="plan-btn">
                      <button type="button" className="btn btn-secondry w-100" disabled={true}>
                        {" "}
                        Test 7 Days Free Trial
                      </button>
                    </div>

                    <div className="item-features  ">
                      <ul>
                        <li>
                          <span className="checkcol">
                            <CheckCircleIcon />
                          </span>
                          10 free questions with Kashyapji
                        </li>
                        <li>
                          <span className="checkcol">
                            <CheckCircleIcon />
                          </span>
                          Summary of personalized horoscope
                        </li>
                        <li>
                          <span className="checkcol">
                            <CheckCircleIcon />
                          </span>
                          Basic Life Report
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="plan-table-item popularitem">
                    <div className="ldplan">
                      <div className="lddeg" style={{ transform: "rotate(180deg)" }}>
                        <div className="ldcol"></div>
                        <div className="ldimg"></div>
                      </div>
                    </div>
                    <div className="ldplan">
                      <div className="lddeg" style={{ transform: "rotate(-0deg)" }}>
                        <div className="ldcol"></div>
                        <div className="ldimg"></div>
                      </div>
                    </div>
                    <div className="plan-title">
                      <h3>Basic</h3>
                      <div className="pricing-description">Perfect for most users. Test more with powerful analytics but limited results per month.</div>
                    </div>
                    <div className="plan-cost-box">
                      <div className="plan-cost">
                        <h5>
                          <i className="las la-rupee-sign"></i>
                          {plans.find((plan) => plan.plan_name === "Basic")?.amount}{" "}
                        </h5>{" "}
                        <small>/pm</small>
                      </div>
                    </div>
                    <div className="plan-btn">
                      <Link to={`/subscription-payment/${plans.find((plan) => plan.plan_name === "Basic")?.id}`}>
                        {" "}
                        <button type="button" className="btn btn-default w-100">
                          Buy Now
                        </button>{" "}
                      </Link>
                    </div>

                    <div className="item-features  ">
                      <ul>
                        <li>
                          <span className="checkcol">
                            <CheckCircleIcon />
                          </span>
                          Unlimited questions
                        </li>
                        <li>
                          <span className="checkcol">
                            <CheckCircleIcon />
                          </span>
                          Complete daily/ weekly/ monthly personalized horoscope with love/ career/ finance advice
                        </li>
                        <li>
                          <span className="checkcol">
                            <CheckCircleIcon />
                          </span>
                          Basic Life Report
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="plan-table-item">
                    <div className="plan-title">
                      <h3>Premium</h3>
                      <div className="pricing-description">Full Access to complete life report highlighting various aspects of your life.</div>
                    </div>
                    <div className="plan-cost-box">
                      <div className="plan-cost">
                        <h5>
                          <i className="las la-rupee-sign"></i>
                          {plans.find((plan) => plan.plan_name === "Premium")?.amount}
                        </h5>
                      </div>
                    </div>
                    <div className="plan-btn">
                      <Link to={`/subscription-payment/${plans.find((plan) => plan.plan_name === "Premium")?.id}`}>
                        {" "}
                        <button type="button" className="btn btn-secondry w-100">
                          {" "}
                          Buy Now
                        </button>{" "}
                      </Link>
                    </div>
                    <div className="item-features  ">
                      <ul>
                        <li>
                          <span className="checkcol">
                            <CheckCircleIcon />
                          </span>
                          Unlock Complete Life Report permanently
                        </li>
                        <li>
                          <span className="checkcol">
                            <CheckCircleIcon />
                          </span>
                          <h4 className=" text-center mb-0 pb-0  text-left" style={{ lineHeight: "25px" }}>
                            {" "}
                            Basic Features Included <br />
                            <small style={{ fontSize: "12px", display: "block", textAlign: "left" }}>(Only For One Month)</small>
                          </h4>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <small>
                  All prices are in Excl of GST. For outside India, no additional taxes applicable*.
                  <br />
                  Pricing is subject to change without any prior notice. Please refer to the detailed
                  <Link to="/terms"> "Terms and Conditions"</Link> page here.
                </small>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <PageLoader />
      )}
      <FooterPage />
    </div>
  );
};
