import React, { useState ,useEffect} from 'react'; 
import FooterPage from '../_layout/elements/footer';
import bannerimage from'../assets/images/bannerimage2.png'
import businessissue from "../assets/images/business-issues'.png"; 
import motivation from "../assets/images/motivation.png"; 
import healthissue from "../assets/images/health-issue.png"; 
import careerissue from "../assets/images/career-path.png"; 
import financeissue from "../assets/images/loss.png"; 
import heart from "../assets/images/broken-heart.png"; 
import { Avatar,Accordion,AccordionSummary,Typography,AccordionDetails } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Signuppopup from '../components/signup-modal';
import { Link } from 'react-router-dom';
export const LifeReportCampaigning = () => {  
//this below function for add folder   popup start// 
const [opensignup, setOpensignup] = React.useState(false);
const activesignup = () => setOpensignup(true);
const handleClosesignup = () => setOpensignup(false);
//this below function for add brain   popup end// 
return (
<div>
   <section className="reportbanner">
      <div className='container'>
         <div className='row'>
            <div className='banner-content-box'>
               <div className='banner-content'>
                  <h1> Are You on the Right Path? Discover Your True Course with Our <span className='text-blue'>Life Report </span>     </h1>
                  <p>Gain unparalleled insights into your strengths and future challenges with our life report,  from the perspective of both Vedic and Western astrology</p>
                  <p>Dream Big. Plan Smart. Unlock Your Future. </p>
                  <div className="banner-button  ">
                     <div className='report_pricing'>
                        <div className='offer_percentage'> 50% OFF</div>
                        <div className='offer_prize'>
                           <span className='cutoffrate'>₹3999</span>
                           <span className='actual_rate'>₹1999</span>
                        </div>
                     </div>
                     <div className='banner_big_button'>
                    <Link to="/report-payment">    <button type="button" className="btn btn-primary  w-100"> Get My Life Report Now!</button></Link>
                     </div>
                  </div>
               </div>
               <div className='banner-image'>
                  <img src={bannerimage} alt=''/>
               </div>
            </div>
         </div>
      </div>
   </section>
   <section className="padding_medium">
      <div className='container'>
         <div className='row justify-content-center'>
            {/* 
            <div className='col-md-9'>
               <div className='step-heading'>
                  <h2 className='text-center mt-3'> Top Categories     </h2>
                  <p className='text-center   margbottom-50  paddinglr '> It is a long established fact that a reader will be distracted by the readable content of a page when looking at its
                     layout. The point of using Lorem Ipsum 
                  </p>
               </div>
            </div>
            */}
         </div>
         <div className='row justify-content-center'>
            <div className='col-md-4 colmargin'>
               <div className='testimonial_card'>
                  <div className='testimonail_avtar'>
                     <Avatar imgProps={{style:{  padding:'15px'}}} alt="Remy Sharp" src={heart}  className='testimonial_img'/>
                  </div>
                  <div className='testo_name'> Relationship Troubles</div>
                  <div className='testo_message'>
                     <span> Struggling with family or romantic\rconnections? Feeling disconnected or\rmisunderstood? Gain clarity on relationship\rdynamics and paths to harmony with\ran insightful Life Report. </span> 
                  </div>
               </div>
            </div>
            <div className='col-md-4 colmargin'>
               <div className='testimonial_card'>
                  <div className='testimonail_avtar'>
                     <Avatar imgProps={{style:{  padding:'15px'}}} alt="Remy Sharp" src={financeissue}  className='testimonial_img'/>
                  </div>
                  <div className='testo_name'> Financial Troubles</div>
                  <div className='testo_message'>
                     <span> Money worries keeping you up at night?\rWondering how to grow your savings?\rExplore financial strategies and prosperity\rpotential in your Life Report. </span> 
                  </div>
               </div>
            </div>
            <div className='col-md-4 colmargin'>
               <div className='testimonial_card'>
                  <div className='testimonail_avtar'>
                     <Avatar imgProps={{style:{  padding:'15px'}}} alt="Remy Sharp" src={careerissue}  className='testimonial_img'/>
                  </div>
                  <div className='testo_name'>Career Troubles</div>
                  <div className='testo_message'>
                     <span> Facing disappointments in your job search\ror difficulties clearing professional exams?\rAlign your career with your astrological\rstrengths through your Life Report, \renhancing job prospects both \rlocally and abroad. </span> 
                  </div>
               </div>
            </div>
            {/* 
            <div className='col-md-3 colmargin'>
               <div className='testimonial_card'>
                  <div className='testimonail_avtar'>
                     <Avatar imgProps={{style:{borderRadius:'50%', padding:'10px'}}} alt="Remy Sharp" src={financeissue}  className='testimonial_img'/>
                  </div>
                  <div className='testo_name'>Love Troubles</div>
                  <div className='testo_message'>
                     <span> Is your relationship losing its spark? Feel\runloved or distant? Revitalize love and\rintimacy with tailored insights from your\rLife Report and ensure\ra joyful, lasting union. </span> 
                  </div>
               </div>
            </div>
            */}
            <div className='col-md-4 colmargin'>
               <div className='testimonial_card'>
                  <div className='testimonail_avtar'>
                     <Avatar imgProps={{style:{  padding:'10px'}}} alt="Remy Sharp" src={healthissue}  className='testimonial_img'/>
                  </div>
                  <div className='testo_name'>Health Troubles</div>
                  <div className='testo_message'>
                     <span> Are health issues disrupting your daily life\ror are you struggling to maintain wellness?\rUncover personalized strategies and\rdetailed insights in your Life Report. </span> 
                  </div>
               </div>
            </div>
            <div className='col-md-4 colmargin'>
               <div className='testimonial_card'>
                  <div className='testimonail_avtar'>
                     <Avatar imgProps={{style:{  padding:'10px'}}} alt="Remy Sharp" src={motivation}  className='testimonial_img'/>
                  </div>
                  <div className='testo_name'>Motivation & Desires</div>
                  <div className='testo_message'>
                     <span> Curious about what drives you? Want\rto harness these deep motivations to\rachieve your goals? Discover the core forces\rthat propel your life's direction and learn\rhow to utilize them for success with\rinsights from your Life Report. </span> 
                  </div>
               </div>
            </div>
            <div className='col-md-4 colmargin'>
               <div className='testimonial_card'>
                  <div className='testimonail_avtar'>
                     <Avatar imgProps={{style:{ padding:'10px' }}} alt="Remy Sharp" src={businessissue}  className='testimonial_img'/>
                  </div>
                  <div className='testo_name'>Business Troubles</div>
                  <div className='testo_message'>
                     <span> Struggling with business setbacks or\rstalled growth? Uncover astrological\rinsights that pinpoint your business\rchallenges and provide effective strategies\rfor overcoming them as detailed\rin your Life Report. </span> 
                  </div>
               </div>
            </div>
            <div className='col-md-4 colmargin mt-5'>
               <button type="button" className="btn btn-primary  w-100"> Get My Life Report Now!</button>
            </div>
         </div>
      </div>
   </section>
   <section className="padding_medium">
      <div className='container'>
         <div className='row justify-content-center'>
            <div className='col-md-9'>
               <div className='step-heading '>
                  <h2 className='text-center mt-3 mb-5'>  Frequently Asked Questions    </h2>
               </div>
            </div>
         </div>
         <div className="row justify-content-center">
            <div className="col-md-8">
               <div className='faq-wrapper'>
                  <Accordion>
                     <AccordionSummary
                     expandIcon={
                     <ArrowDownwardIcon />
                     }
                     aria-controls="panel1-content"
                     id="panel1-header"
                     >
                     <h5>What is NaviVibe?</h5>
                     </AccordionSummary>
                     <AccordionDetails>
                        <Typography>
                           NaviVibe is an AI astrology app that offers personalized insights and guidance based on Vedic and Western astrology,\rnumerology, and AI expertise.
                        </Typography>
                     </AccordionDetails>
                  </Accordion>
                  <Accordion>
                     <AccordionSummary
                     expandIcon={
                     <ArrowDownwardIcon />
                     }
                     aria-controls="panel2-content"
                     id="panel2-header"
                     >
                     <h5>What can I expect from the Life Report feature?</h5>
                     </AccordionSummary>
                     <AccordionDetails>
                        <Typography>
                           The Life Report provides comprehensive insights into your personal strengths, future challenges, and opportunities,\rcombining Vedic and Western astrology. It also includes numerology reports for your birthday and key life numbers,\ralong with the option to ask four free questions to our AI expert.
                        </Typography>
                     </AccordionDetails>
                  </Accordion>
                  <Accordion>
                     <AccordionSummary
                     expandIcon={
                     <ArrowDownwardIcon />
                     }
                     aria-controls="panel3-content"
                     id="panel3-header"
                     >
                     <h5>How do I get my Life Report?</h5>
                     </AccordionSummary>
                     <AccordionDetails>
                        <Typography>
                           Simply provide your name, date and time of birth, place of birth, and email address. After submitting this information, \ryou will be directed to our secure payment gateway to complete the transaction. Once payment is confirmed, our\radvanced algorithms will generate your personalized Life Report and deliver it directly to your inbox.
                        </Typography>
                     </AccordionDetails>
                  </Accordion>
                  <Accordion>
                     <AccordionSummary
                     expandIcon={
                     <ArrowDownwardIcon />
                     }
                     aria-controls="panel4-content"
                     id="panel4-header"
                     >
                     <h5>Is my personal information secure?</h5>
                     </AccordionSummary>
                     <AccordionDetails>
                        <Typography>
                           Yes, we take your privacy and security seriously. We use your data only to generate your personalized reports and\rdeliver insights to you.
                        </Typography>
                     </AccordionDetails>
                  </Accordion>
                  <Accordion>
                     <AccordionSummary
                     expandIcon={
                     <ArrowDownwardIcon />
                     }
                     aria-controls="panel5-content"
                     id="panel5-header"
                     >
                     <h5>How can I contact support if I have further questions?</h5>
                     </AccordionSummary>
                     <AccordionDetails>
                        <Typography>
                           If you have any questions or need assistance, please don't hesitate to contact our support team. You can reach us via \rWhatsApp at 95XXXXXXXX. We're here to help!
                        </Typography>
                     </AccordionDetails>
                  </Accordion>
               </div>
            </div>
         </div>
      </div>
   </section>
   <Signuppopup opensignup={opensignup} handleClosesignup={handleClosesignup} />
</div>
)
}