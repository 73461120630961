import React, { useState  } from 'react'; 
import HeaderPage from '../_layout/elements/header';
import FooterPage from '../_layout/elements/footer';
import {  Breadcrumbs,Typography,Avatar } from '@mui/material'; 
 
import Assessmentapi from "../assets/images/AssessmentAPI.jpeg";
 
import { Link } from 'react-router-dom';
 
export const Relationships = () => {
 
return (
<div>
   <div className='inner-header'>
      <HeaderPage />
   </div>
   {/* <section className='padding_medium  minibanner  career-banner'  >
      <div className='container'>
         <div className='row align-items-center justify-content-between'>
            <div className='col-md-12'>
               <div className='minibanner-content'>
                  <h2>Career </h2>
                  <div className='blog-breadcrumbs'>
                     <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" href="/home">
                        MUI
                        </Link>
                        <Link
                           underline="hover"
                           color="inherit"
                           href="/material-ui/getting-started/installation/"
                           >
                        Core
                        </Link>
                        <Typography color="text.primary">Breadcrumbs</Typography>
                     </Breadcrumbs>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section> */}
   <section className='padding_medium '  >
      <div className='container'>
         <div className='row '>
         <div className='ai-proctoring  '>
                  <div className='step-heading'>
                     <span class="border-verticle borderbg"></span>
                  
                     <h2 className='text-center mt-3'>Relationships</h2>
                     <p className='text-center   margbottom-50  paddinglr '>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In viverra quis odio sit amet accumsan. Maecenas at augue ut leo ultricies scelerisque id sit amet ligula. Quisque congue augue eu ante mollis venenatis.</p>
                  </div>
                  <div className='aiproctoring-box'>
                     <div className='aiproctoring-flex'>
                        <div className='proctor-box'>
                           <div className='aiproctoring-cta'>
                              <div className='proctor-icon bg_videoproctor'>
                                 
                              </div>
                              <div className='proctor-content'>
                                 <h4>Relationship Calendar</h4>
                                 <h5>Check good times and bad times with astrological calendar</h5>
                                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In viverra quis odio sit amet accumsan. Maecenas at augue ut leo ultricies scelerisque id sit amet ligula. Quisque congue augue eu ante mollis venenatis.</p>
                              </div>
                           </div>
                        </div>
                        <div className='centerbordered'>
                           <span class="border-verticle borderbg"></span>
                           <span className='circle-dot'></span>
                           <span class="border-dashed  "></span>
                        </div>
                        <div className='proctor-box'>
                           <div className='aiproctoring-cta'>
                              <div className='proctor-icon'>
                                
                              </div>
                              <div className='proctor-content'>
                                 <h4>Face Matchmaking</h4>
                                 <h5>Upload picture of you and your partner to check compatibility</h5>
                                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In viverra quis odio sit amet accumsan. Maecenas at augue ut leo ultricies scelerisque id sit amet ligula. Quisque congue augue eu ante mollis venenatis.</p>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className='aiproctoring-flex'>
                        <div className='proctor-box'>
                           <div className='aiproctoring-cta'>
                              <div className='proctor-icon'>
                               
                              </div>
                              <div className='proctor-content'>
                                 <h4>Match Making</h4>
                                 <h5>Check compatibility of you and your partner with western/ eastern/ chinese techniques</h5>
                                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In viverra quis odio sit amet accumsan. Maecenas at augue ut leo ultricies scelerisque id sit amet ligula. Quisque congue augue eu ante mollis venenatis.</p>
                              </div>
                           </div>
                        </div>
                        <div className='centerbordered'>
                           <span class="border-verticle borderbg"></span>
                           <span className='circle-dot'></span>
                           <span class="border-dashed  "></span>
                        </div>
                        <div className='proctor-box'>
                           <div className='aiproctoring-cta'>
                              <div className='proctor-icon'>
                                 
                              </div>
                              <div className='proctor-content'>
                                 <h4>Celebrity Match</h4>
                                 <h5>Check your astrological compatibility with celebrities</h5>
                                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In viverra quis odio sit amet accumsan. Maecenas at augue ut leo ultricies scelerisque id sit amet ligula. Quisque congue augue eu ante mollis venenatis.</p>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className='aiproctoring-flex'>
                        <div className='proctor-box'>
                           <div className='aiproctoring-cta'>
                              <div className='proctor-icon'>
                               
                              </div>
                              <div className='proctor-content'>
                                 <h4>Ask a Question</h4>
                                 <h5>Get personalized relationship advice from our AI Guru</h5>
                                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In viverra quis odio sit amet accumsan. Maecenas at augue ut leo ultricies scelerisque id sit amet ligula. Quisque congue augue eu ante mollis venenatis.</p>
                              </div>
                           </div>
                        </div>
                        <div className='centerbordered'>
                           <span class="border-verticle borderbg"></span>
                           <span className='circle-dot'></span>
                           <span class="border-dashed  "></span>
                        </div>
                        <div className='proctor-box'>
                           <div className='aiproctoring-cta'>
                              <div className='proctor-icon'>
                               
                              </div>
                              <div className='proctor-content'>
                                 <h4>Break Up Calculator</h4>
                                 <h5>Check compatibility scores using out various calculators</h5>
                                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In viverra quis odio sit amet accumsan. Maecenas at augue ut leo ultricies scelerisque id sit amet ligula. Quisque congue augue eu ante mollis venenatis.</p>
                              </div>
                           </div>
                        </div>
                     </div>
             
                  </div>
               </div>
    
      </div>
</div>
</section>
<FooterPage />
</div>
)
}